import React, {useState} from "react";
import style from './team.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";
import InsertedImageRound from "../../../../parts/insertedImageRound/insertedImageRound";
import user from "../../../../../assets/images/common/user.png";
import InsertedImage from "../../../../parts/insertedImage/insertedImage";
import basket from "../../../../../assets/images/common/basket.png";
import {useNavigate} from "react-router-dom";
import {
    deleteTeamLeaderDepartmentManagementTC, nudgeTeamDepartmentManagementTC,
    reinviteTeamLeaderDepartmentManagementTC,
    setChosenTeamDepartmentManagementInfoAC
} from "../../../../../redux/reducers/departmentManagementReducer";
import MessageBox from "../../../../parts/messageBox/messageBox";
import {useHookErrors} from "../../../../../redux/hookNavigate";
import secondaryLeaderImage from './../../../../../assets/images/companyManagement/addLeader_black.png';
import {setChosenItemForModalWindowCompanyManagementAC} from "../../../../../redux/reducers/companyManagementReducer";
import pendingImage from './../../../../../assets/images/common/pending.png';

export type StatusType = 'Active' | 'Incomplete Onboarding' | 'Invitation Sent';

type TeamPropsType = {
    id: number,
    teamName: string,
    membersCount: number,
    leader: string,
    secondaryLeaderName: string | null,
    email: string,
    status: StatusType,
    removeTeam?: () => void,
    choseTeam?: () => void,
    chooseAnItemForModalWindow?: (action: 'add' | 'change' | 'choose' | 'delete' | null,
                                 item: 'admin' | 'department' | 'team' | 'member' | null) => void,
}

const Team = (props: TeamPropsType) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentStatus = useSelector((state: RootStateType) => state.common.currentStatus);
    const departmentName = useSelector((state: RootStateType) => state.departmentManagement.departmentInfo.name);
    const teams = useSelector((state: RootStateType) => state.departmentManagement.departmentInfo.teams);
    const statusStyle = props.status === 'Active' ? `${style.green}`
        : props.status === 'Incomplete Onboarding' ? `${style.yellow}`
            : `${style.red}`;
    const departmentId = useSelector((state: RootStateType) => state.departmentManagement.departmentInfo.id);



    const {notification, isError, isSuccess, hideNotification, setError, setSuccess} = useHookErrors();

    const removeTeam = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        dispatch(deleteTeamLeaderDepartmentManagementTC(departmentId, props.email, props.id, setError))
    }

    const nudgeTeam = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(nudgeTeamDepartmentManagementTC(props.id, setError, setSuccess))
    }

    const reinviteTeamLeader = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(reinviteTeamLeaderDepartmentManagementTC(departmentId, props.email, setError, setSuccess))
    }

    let addSecondaryLeader = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if(props.chooseAnItemForModalWindow) {
            props.chooseAnItemForModalWindow('choose', 'team');
            let leader = {
                id: props.id,
                name: props.teamName,
                leader: props.leader,
                secondaryLeaderName: props.secondaryLeaderName,
                email: props.email,
            }
            dispatch(setChosenItemForModalWindowCompanyManagementAC(leader));
        }
    }

    //выбираем конкретную команду на странице department management information
    const choseCertainTeam = () => {
        if (props.teamName !== 'N/A') {
            let chosenTeam = teams.find(team => team.id === props.id);
            chosenTeam && dispatch(setChosenTeamDepartmentManagementInfoAC(chosenTeam));
            //здесь другой адрес навигации в зависимости от роли
           currentStatus === 'isModerator' ? navigate(`/companyManagement/${departmentName}/${chosenTeam?.name}`)
               :   navigate(`/departmentManagement/${chosenTeam?.name}`);
        }
    }

    return (
        <>
            <p style={{height: '1px', backgroundColor: '#D3D1D1', margin: '10px 0'}}></p>
            <div className={`team_header ${style.font}`} onClick={choseCertainTeam}
                 style={{cursor: 'pointer', position: 'relative'}}>
                {(isError || isSuccess) &&
                    <div style={{width: '100%', position: 'absolute', top: '0', left: '0', zIndex: '10'}}>
                        <MessageBox notification={notification} isError={isError} isSuccess={isSuccess}
                                    autoClosing={true} hideError={hideNotification}/>
                    </div>}
                <InsertedImageRound width={2.8125} height={2.8125} image={user}/>
                <div>{props.teamName}</div>
                <div>{props.membersCount}</div>
                <div>{props.leader}</div>
                <div style={{alignSelf: 'center'}}>
                    {+props.id < 0 ? '' : props?.secondaryLeaderName && props.secondaryLeaderName === 'Pending' ?
                        <InsertedImage width={1.5} height={1.5} image={pendingImage} cursor={'auto'}
                                       handler={() => {}}/>
                            : props?.secondaryLeaderName ? props.secondaryLeaderName :
                        <InsertedImage width={1.5} height={1.5} image={secondaryLeaderImage} cursor={'pointer'}
                                       handler={addSecondaryLeader}/>}
                </div>
                <div>{props.email}</div>
                <div className={statusStyle}>
                    <input type="radio" checked readOnly className={style.radio}/>
                    <label htmlFor="">{props.status}</label>
                </div>
                <div>
                    {props.status === 'Incomplete Onboarding' &&
                        <button className={style.action_button} onClick={nudgeTeam}>Nudge</button>}
                    {props.status === 'Invitation Sent' &&
                        <button className={style.action_button} onClick={reinviteTeamLeader}>Reinvite</button>}
                </div>
                <InsertedImage width={1.5625} height={1.5625} image={basket} handler={removeTeam}/>
            </div>
        </>
    )
}

export default Team;
