import React from "react";
import style from './section.module.scss';
import InsertedImage from "../../insertedImage/insertedImage";
import {useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";

export type SectionPropsType = {
    image: string,
    name: string,
    onClickHandler?: () => void
}

const Section = (props: SectionPropsType) => {
    const {currentStatus, tourCompleted} = useSelector((state: RootStateType) => state.common);

    return (
        <div className={`${style.section} ${(currentStatus === 'isModerator' && !tourCompleted) ? 'edit_profile_item_menu' : ''}
        ${(currentStatus === 'isDepartmentAdmin' && !tourCompleted) ? 'edit_profile_item_menu_DA' : ''}
        ${(currentStatus === 'isAdmin' && !tourCompleted) ? 'edit_profile_item_menu_leader' : ''}
         ${(currentStatus === 'isMember' && !tourCompleted) ? 'edit_profile_item_menu_member' : ''}`}
            onClick={props.onClickHandler}>
            <InsertedImage width={1.25} height={1.25} image={props.image}/>
            <div className={style.section_name}>{props.name}</div>
        </div>
    )
}

export default Section;