import React, {useState} from "react";
import style from './miniSidebar.module.scss';
import InsertedImage from "../insertedImage/insertedImage";
import dashboard from './../../../assets/images/sidebar/dashboard.png';
import coachingCorner from './../../../assets/images/sidebar/coachingCorner.png';
import teamManagement from './../../../assets/images/sidebar/teamManagement.png';
import checkins from './../../../assets/images/sidebar/checkins.png';
import createTeam from './../../../assets/images/sidebar/createNewTeam.png';
import companyManagement from './../../../assets/images/sidebar/companyManagement.png';
import faq from './../../../assets/images/sidebar/faq.png';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import departmentManagement from "../../../assets/images/sidebar/departmentManagement.png";
import ModalWindowCreateTeam from "../../pages/createTeam/modalWindowCreateTeam/modalWindowCreateTeam";
import ModalWindowCreateDepartment
    from "../../pages/createDepartment/modalWindowCreateDepartment/modalWindowCreateDepartment";

const MiniSidebar = () => {
    const {currentStatus, tourCompleted} = useSelector((state: RootStateType) => state.common);
    //для отображения модалки перед create new team или create new department
    const [modalMode, setModalMode] = useState<boolean>(false);
    const changeModalMode = (mode: boolean) => {
        setModalMode(mode);
    }

    //для определениякакую модалку открывать (на создание команды или департамента)
    const [modalType, setModalType] = useState<'department' | 'team' | ''>('');

    const onClickCreateNewTeam = () => {
        changeModalMode(true);
        setModalType('team');
    }

    const onClickCreateNewDepartment = () => {
        changeModalMode(true);
        setModalType('department');
    }

    const additionalSidebarClass = (currentStatus === 'isModerator' && !tourCompleted) ? 'dashboard_company_minisidebar' :
        (currentStatus === 'isDepartmentAdmin' && !tourCompleted) ? 'dashboard_company_minisidebar_DA' :
            (currentStatus === 'isAdmin' && !tourCompleted) ? 'dashboard_leader_minisidebar' :
                (currentStatus === 'isMember' && !tourCompleted) ? 'dashboard_member_minisidebar' : ''

    return (
        <div className={`${style.mini_sidebar} ${additionalSidebarClass}`}
             style={{position: modalMode ? 'relative' : 'sticky'}}>
            <NavLink
                to={`${(currentStatus === 'isModerator' || currentStatus === 'isDepartmentAdmin') ? '/dashboard' : '/dashboard/team'}`}
                data-title={'Dashboard'}>
                <InsertedImage width={1.4375} height={1.4375} image={dashboard}/>
            </NavLink>

            {['isMember', 'isAdmin'].includes(currentStatus) &&
                <NavLink to='/coachingCorner' data-title={'Coaching corner'}>
                    <div
                        className={`${(currentStatus === 'isAdmin' && !tourCompleted) ? 'coaching_corner_minisidebar_leader' : ''} 
                ${(currentStatus === 'isMember' && !tourCompleted) ? 'coaching_corner_minisidebar_member' : ''}`}>
                        <InsertedImage width={1.4375} height={1.4375} image={coachingCorner}/>
                    </div>
                </NavLink>}

            {currentStatus === 'isAdmin' && <NavLink to='/teamManagement' data-title={'Team management'}>
                <InsertedImage width={1.4375} height={1.4375} image={teamManagement}/>
            </NavLink>}

            {currentStatus === 'isDepartmentAdmin' &&
                <NavLink to='/departmentManagement' data-title={'Department management'}>
                    <div
                        className={`${(currentStatus === 'isDepartmentAdmin' && !tourCompleted) ? 'minisidebar_department_management_DA' : ''}`}>
                        <InsertedImage width={1.4375} height={1.4375} image={departmentManagement}/>
                    </div>
                </NavLink>}

            {currentStatus === 'isModerator' && <NavLink to='/companyManagement' data-title={'Company Management'}>
                <div
                    className={`${(currentStatus === 'isModerator' && !tourCompleted) ? 'minisidebar_company_management_moderator' : ''}`}>
                    <InsertedImage width={1.4375} height={1.4375} image={companyManagement}/></div>
            </NavLink>}

            {/*пока закомментила репорты*/}
            {/*{['isModerator', 'isAdmin', 'isDepartmentAdmin'].includes(currentStatus) &&*/}
            {/*<NavLink to={'/'} data-title={'Check in Report'}>*/}
            {/*    <div>*/}
            {/*        <InsertedImage width={1.4375} height={1.4375} image={reports}/>*/}
            {/*    </div>*/}
            {/*</NavLink>*/}
            {/*}*/}

            {['isModerator', 'isAdmin', 'isDepartmentAdmin'].includes(currentStatus) &&
                <NavLink to='/digitalPulse' data-title={'Digital Pulse'}>
                    <div
                        className={`${(currentStatus === 'isModerator' && !tourCompleted) ? 'minisidebar_digital_pulse_moderator' : ''}
                    ${(currentStatus === 'isAdmin' && !tourCompleted) ? 'minisidebar_digital_pulse_leader' : ''} 
                    ${(currentStatus === 'isDepartmentAdmin' && !tourCompleted) ? 'minisidebar_digital_pulse_DA' : ''}`}>
                        <InsertedImage width={1.4375} height={1.4375} image={checkins}/>
                    </div>
                </NavLink>}

            {(currentStatus === 'isDepartmentAdmin' || currentStatus === 'isModerator') &&
                <div className={style.create_department_item}
                     data-title={'Create New Department'} onClick={onClickCreateNewDepartment}>
                    <InsertedImage width={1.4375} height={1.4375} image={createTeam}/>
                </div>}

            <div className={style.create_department_item}
                 data-title={'Create New Team'} onClick={onClickCreateNewTeam}>
                <InsertedImage width={1.4375} height={1.4375} image={createTeam}/>
            </div>

            <NavLink to='/faq' data-title={'FAQ'}>
                <InsertedImage width={1.4375} height={1.4375} image={faq}/>
            </NavLink>
            {modalMode && modalType === 'team' &&
                <ModalWindowCreateTeam closeModalWindow={(mode: boolean) => changeModalMode(mode)}/>}
            {modalMode && modalType === 'department' &&
                <ModalWindowCreateDepartment closeModalWindow={(mode: boolean) => changeModalMode(mode)}/>}
        </div>
    )
}

export default MiniSidebar;