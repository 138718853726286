import React, {ChangeEvent, useEffect} from "react";
import style from './createMessageItem.module.scss';
import ButtonWithoutImage from "../../../../parts/buttonWithoutImage/buttonWithoutImage";
import nofoto from "../../../../../assets/images/coachingCorner/nofoto.png";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";
import {coachingCornerAddNewPostTC} from "../../../../../redux/reducers/coachingCornerReducer";
import anonymIcon from './../../../../../assets/images/coachingCorner/anonym.png';

export type CreateMessageItemPropsType = {
    switchCurseWordsMode: () => void,
    setWarningText: (text: string) => void,
}

const CreateMessageItem = (props: CreateMessageItemPropsType) => {
    const dispatch = useDispatch();
    let userName = useSelector((state: RootStateType) => state.common.userInfo.name);
    let state = useSelector((state: RootStateType) => state.coachingCorner);

    //для изменения текста в текстареа
    const [text, changeText] = React.useState<string>('');

    //для анонимности
    const [anonym, changeAnonym] = React.useState<boolean>(false);

    const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        changeText(e.currentTarget.value);
    }

    //срабатывает когда по инпуту чекаем
    const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        changeAnonym(e.target.checked);
    }

    const sendComment = () => {

        let newPost = {
            anonymously: anonym,
            createdAt: '',
            daysAgo: '',
            id: Math.floor(Math.random() * 88888),
            logo_url: anonym ? anonymIcon : state.userPhoto,
            name: anonym ? 'Anonymous' : userName,
            seen: true,
            source: state.postFromMotivation ? 'Sent from Motivation trend' : '',
            text: text.trim(),
        }

        let answer = {
            anonymously: anonym,
            text: text.trim().replace(/\n/ig, ' ').replace(/ +/g, ' '),
            isMotivation: state.postFromMotivation
        }
        state.chosenTeamId && dispatch(coachingCornerAddNewPostTC(state.chosenTeamId, newPost, answer,
            props.setWarningText, changeText, props.switchCurseWordsMode));
    }

    const disabled = !text.trim().length;

    useEffect(() => {
        //для текстареа с переносом слов на новую строку  авто-высотой
        let tx = document.getElementsByTagName("textarea")[0];
        tx.setAttribute("style", "height:" + (tx.scrollHeight) + "px;overflow-y:hidden;");
        tx.addEventListener("input", OnInput, false);

        function OnInput() {
            tx.style.height = "auto";
            tx.style.resize = "none";
            tx.style.height = (tx.scrollHeight) + "px";
        }
    }, [])

    return (
        <div className={`${style.create_message_item}`}>
            <div className={style.block_with_textarea}>
                <div className={style.ava}>
                    {/*<img src={props.post['logo_url'] ? props.post['logo_url'] : nofoto} alt=""/>*/}
                    <img src={state.userPhoto ? state.userPhoto : nofoto} alt=""/>
                </div>

                <textarea placeholder={'What\'s new?'} id={'motivation_chatbox_textarea'}
                          onChange={onChangeText}
                    //autoFocus={true}
                          value={text}/>

                <ButtonWithoutImage width={8.375}
                                    onClickHandler={sendComment}
                                    borderRadius={85}
                                    boxShadow={'0px -1px 28px rgba(0, 0, 0, 0.04)'}
                                    name={'Post'}
                                    disabled={disabled}/>
            </div>

            <div className={style.block_with_checkbox}>
                <div>
                    <input className={style.block_with_checkbox_input} onChange={onCheck}
                           id={'anonymity'}
                           type="checkbox"
                           name="anonymity"/>
                    <label className={style.block_with_checkbox_label} htmlFor={'anonymity'}>Post Anonymously</label>
                </div>
            </div>
        </div>
    )
}

export default CreateMessageItem;