import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {companyManagementAPI, departmentManagementAPI, teamManagementAPI} from "../../api/api";
import {v1} from "uuid";
import {
    addMemberTeamManagementAC,
    CheckinReportMemberType, fixSecondaryTeamLeaderTeamManagementAC, removeMemberTeamManagementAC,
} from "./teamManagementReducer";
import {CompanyInfoDepartmentType, updateTeamLeaderSecondaryNameCompanyManagementAC} from "./companyManagementReducer";
import {setLoadingCommonReducerAC} from "./commonReducer";

//initialState.leader: это для того, чтоб в модалке отобразить разный контент и действия в зависимости от того, где модалка вызвана
//если team: мы либо меняем тим лидера, либо приглашаем нового тим лидера
//если department: мы меняем менеджера департамента
//если member: мы добавляем в команду нового участника

export type  LeaderType = 'team' | 'department' | 'member';

export type CertainTeamMemberType = {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    onboarding: boolean,
    status: boolean
}

export type DepartmentManagementDepartmentType = {
    companyId: number,
    createdAt: string,
    id: number,
    name: string,
    updatedAt: string,
    userId: number
}

export type DepartmentManagementCertainTeamType = {
    id: number,
    leaderName: string,
    secondaryLeaderName: string | null,
    link: string,
    members: Array<CertainTeamMemberType>,
    name: string,
    surveysTimeSend: string,
}

export type DepartmentManagementTeamType = {
    id: number | string,
    email: string,
    leader: string,
    membersCount: number | string,
    name: string,
    onboardingCompleted: boolean,
    secondaryLeaderName: string | null,
    status: boolean
}

export type DepartmentInfoType = {
    companyId: number,
    id: number,
    leader: string,
    link: string,
    name: string,
    teams: Array<DepartmentManagementTeamType>,
    teamscount: string
}

export type DepartmentManagementStateType = {
    loading: boolean,
    //для того. чтоб знать с каким контентом модалку открывать
    leader: LeaderType | '',
    departments: Array<DepartmentManagementDepartmentType>,
    chosenDepartment: DepartmentManagementDepartmentType | CompanyInfoDepartmentType | null,
    chosenTeam: any,
    departmentInfo: DepartmentInfoType,
    departmentCheckinReport: {
        chosenTeamFromSelect: DepartmentManagementTeamType | null,
        date: Date,
        members: Array<CheckinReportMemberType>,
    }
    certainTeamPage: {
        certainTeamPageInfo: DepartmentManagementCertainTeamType,
        certainTeamPageCheckinReports: {
            date: Date,
            members: Array<CheckinReportMemberType>,
        },
    }
}

const initialState: DepartmentManagementStateType = {
    loading: true,
    //для того. чтоб знать с каким контентом модалку открывать
    leader: '',
    departments: [],
    chosenDepartment: null,
    chosenTeam: null,
    departmentInfo: {
        companyId: 0,
        id: 0,
        leader: '',
        link: '',
        name: '',
        teams: [],
        teamscount: ''
    },
    departmentCheckinReport: {
        chosenTeamFromSelect: null,
        date: new Date(),
        members: [],
    },
    certainTeamPage: {
        certainTeamPageInfo: {
            id: 0,
            leaderName: '',
            secondaryLeaderName: null,
            link: '',
            members: [],
            name: '',
            surveysTimeSend: '',
        },
        certainTeamPageCheckinReports: {
            date: new Date(),
            members: [],
        },
    }
}


export const departmentManagementReducer = (state = initialState, action: AllActionType): DepartmentManagementStateType => {
    switch (action.type) {
        case 'SET-LOADING-DEPARTMENT-MANAGEMENT': {
            return {...state, loading: action.loading}
        }
        case 'SET-LEADER-TYPE-DEPARTMENT-MANAGEMENT': {
            return {...state, leader: action.leader}
        }
        case 'SET-ALL-DEPARTMENTS-DEPARTMENT-MANAGEMENT': {
            return {...state, departments: [...action.departments]}
        }
        case 'SET-CHOSEN-DEPARTMENT-DEPARTMENT-MANAGEMENT': {
            return {...state, chosenDepartment: action.department ? {...action.department} : action.department}
        }
        case 'SET-DEPARTMENT-INFO-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentInfo: {...action.info}}
        }
        case 'CHOOSE-TEAM-DEPARTMENT-MANAGEMENT-INFO': {
            return {...state, chosenTeam: {...action.team}}
        }
        case 'SET-CERTAIN-TEAM-INFO-DEPARTMENT-MANAGEMENT': {
            return {...state, certainTeamPage: {...state.certainTeamPage, certainTeamPageInfo: {...action.info}}}
        }
        case 'SET-CHOSEN-TEAM-FROM-SELECT-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                departmentCheckinReport: {
                    ...state.departmentCheckinReport,
                    chosenTeamFromSelect: action.team ? {...action.team} : null
                }
            }
        }
        case 'SET-DATE-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentCheckinReport: {...state.departmentCheckinReport, date: action.date}}
        }
        case 'SET-MEMBERS-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentCheckinReport: {...state.departmentCheckinReport, members: [...action.members]}}
        }
        case 'SET-MEMBERS-CERTAIN-TEAM-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageCheckinReports: {
                        ...state.certainTeamPage.certainTeamPageCheckinReports,
                        members: [...action.members]
                    }
                }
            }
        }
        case 'SET-DATE-CERTAIN-TEAM-PAGE-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageCheckinReports: {
                        ...state.certainTeamPage.certainTeamPageCheckinReports,
                        date: action.date
                    }
                }
            }
        }
        case 'ADD-TEAM-MEMBER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageInfo: {
                        ...state.certainTeamPage.certainTeamPageInfo,
                        members: [...state.certainTeamPage.certainTeamPageInfo.members, action.member]
                    }
                }
            }
        }
        case 'REMOVE-TEAM-MEMBER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageInfo: {
                        ...state.certainTeamPage.certainTeamPageInfo,
                        members: state.certainTeamPage.certainTeamPageInfo.members.filter(member => member.id !== action.memberId)
                    }
                }
            }
        }
        case 'CHANGE-DEPARTMENT-NAME-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentInfo: {...state.departmentInfo, name: action.name}}
        }
        case 'INVITE-TEAM-LEADER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                departmentInfo: {...state.departmentInfo, teams: [action.teamLeader, ...state.departmentInfo.teams]}
            }
        }
        case 'DELETE-TEAM-LEADER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                departmentInfo: {
                    ...state.departmentInfo,
                    teams: state.departmentInfo.teams.filter(team => team.id !== action.teamId)
                }
            }
        }
        case 'CLEAR-STATE-DEPARTMENT-MANAGEMENT': {
            return {
                ...state, leader: '', departments: [], chosenDepartment: null, chosenTeam: null,
                departmentInfo: {
                    ...state.departmentInfo,
                    companyId: 0,
                    id: 0,
                    leader: '',
                    link: '',
                    name: '',
                    teams: [],
                    teamscount: ''
                },
                departmentCheckinReport: {
                    ...state.departmentCheckinReport,
                    chosenTeamFromSelect: null,
                    date: new Date(),
                    members: []
                },
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageInfo: {
                        ...state.certainTeamPage.certainTeamPageInfo,
                        id: 0,
                        leaderName: '',
                        link: '',
                        members: [],
                        name: '',
                        surveysTimeSend: ''
                    },
                    certainTeamPageCheckinReports: {
                        ...state.certainTeamPage.certainTeamPageCheckinReports,
                        date: new Date(),
                        members: []
                    }
                }
            }
        }
        case 'DEPARTMENT-MANAGEMENT-FIX-SECONDARY_TEAM-LEADER': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageInfo: {
                        ...state.certainTeamPage.certainTeamPageInfo,
                        secondaryLeaderName: action.leader
                    }
                },
            }
        }
        case 'UPDATE-TEAM-LEADER-SECONDARY-NAME-DEPARTMENT-MANAGEMENT': {
            return {
                ...state, departmentInfo: {
                    ...state.departmentInfo,
                    teams: state.departmentInfo.teams.map(t => t.id === action.teamId ? {
                        ...t,
                        secondaryLeaderName: action.secondaryName
                    } : t)
                }
            }
        }
        default:
            return state;
    }
}

export type setLeaderTypeDepartmentManagementACType = ReturnType<typeof setLeaderTypeDepartmentManagementAC>;
export type setLoadingDepartmentManagementACType = ReturnType<typeof setLoadingDepartmentManagementAC>;
export type setDepartmentsDepartmentManagementACType = ReturnType<typeof setDepartmentsDepartmentManagementAC>;
export type setChosenDepartmentDepartmentManagementACType = ReturnType<typeof setChosenDepartmentDepartmentManagementAC>;
export type setDepartmentInfoDepartmentManagementACType = ReturnType<typeof setDepartmentInfoDepartmentManagementAC>;
export type setChosenTeamDepartmentManagementInfoACType = ReturnType<typeof setChosenTeamDepartmentManagementInfoAC>;

export type setCertainTeamInfoDepartmentManagementACType = ReturnType<typeof setCertainTeamInfoDepartmentManagementAC>;
export type setChosenTeamFromSelectDepartmentManagementACType = ReturnType<typeof setChosenTeamFromSelectDepartmentManagementAC>;
export type setDateDepartmentCheckinReportACType = ReturnType<typeof setDateDepartmentCheckinReportAC>;

export type setMembersDepartmentCheckinReportDepartmentManagementACType = ReturnType<typeof setMembersDepartmentCheckinReportDepartmentManagementAC>;

export type setDateCertainTeamPageCheckinReportDepartmentManagementACType = ReturnType<typeof setDateCertainTeamPageCheckinReportDepartmentManagementAC>;
export type setMembersCertainTeamCheckinReportDepartmentManagementACType = ReturnType<typeof setMembersCertainTeamCheckinReportDepartmentManagementAC>;

export type addTeamMemberDepartmentManagementACType = ReturnType<typeof addTeamMemberDepartmentManagementAC>;
export type removeMemberDepartmentManagementACType = ReturnType<typeof removeMemberDepartmentManagementAC>;

export type changeDepartmentNameDepartmentManagementACType = ReturnType<typeof changeDepartmentNameDepartmentManagementAC>;
export type inviteTeamLeaderDepartmentManagementACType = ReturnType<typeof inviteTeamLeaderDepartmentManagementAC>;
export type deleteTeamLeaderDepartmentManagementACType = ReturnType<typeof deleteTeamLeaderDepartmentManagementAC>;

export type clearDepartmentManagementStateACType = ReturnType<typeof clearDepartmentManagementStateAC>;
export type fixSecondaryTeamLeaderDepartmentManagementACType = ReturnType<typeof fixSecondaryTeamLeaderDepartmentManagementAC>;

export type updateTeamLeaderSecondaryNameDepartmentManagementACType = ReturnType<typeof updateTeamLeaderSecondaryNameDepartmentManagementAC>;
export const setLeaderTypeDepartmentManagementAC = (leader: LeaderType) => ({
    type: 'SET-LEADER-TYPE-DEPARTMENT-MANAGEMENT',
    leader
} as const);
export const setLoadingDepartmentManagementAC = (loading: boolean) => ({
    type: 'SET-LOADING-DEPARTMENT-MANAGEMENT',
    loading
} as const);
export const setDepartmentsDepartmentManagementAC = (departments: Array<DepartmentManagementDepartmentType>) => ({
    type: 'SET-ALL-DEPARTMENTS-DEPARTMENT-MANAGEMENT',
    departments
} as const);
export const setChosenDepartmentDepartmentManagementAC = (department: DepartmentManagementDepartmentType | CompanyInfoDepartmentType | null) => ({
    type: 'SET-CHOSEN-DEPARTMENT-DEPARTMENT-MANAGEMENT',
    department
} as const);
export const setDepartmentInfoDepartmentManagementAC = (info: DepartmentInfoType) => ({
    type: 'SET-DEPARTMENT-INFO-DEPARTMENT-MANAGEMENT',
    info
} as const);
export const setChosenTeamDepartmentManagementInfoAC = (team: DepartmentManagementTeamType) => ({
    type: 'CHOOSE-TEAM-DEPARTMENT-MANAGEMENT-INFO',
    team
} as const);

export const setCertainTeamInfoDepartmentManagementAC = (info: any) => ({
    type: 'SET-CERTAIN-TEAM-INFO-DEPARTMENT-MANAGEMENT',
    info
} as const);
export const setChosenTeamFromSelectDepartmentManagementAC = (team: DepartmentManagementTeamType | null) => ({
    type: 'SET-CHOSEN-TEAM-FROM-SELECT-DEPARTMENT-MANAGEMENT',
    team
} as const);
export const setDateDepartmentCheckinReportAC = (date: Date) => ({
    type: 'SET-DATE-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    date
} as const);
export const setMembersDepartmentCheckinReportDepartmentManagementAC = (members: Array<CheckinReportMemberType>) => ({
    type: 'SET-MEMBERS-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    members
} as const);

export const setDateCertainTeamPageCheckinReportDepartmentManagementAC = (date: Date) => ({
    type: 'SET-DATE-CERTAIN-TEAM-PAGE-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    date
} as const);
export const setMembersCertainTeamCheckinReportDepartmentManagementAC = (members: Array<CheckinReportMemberType>) => ({
    type: 'SET-MEMBERS-CERTAIN-TEAM-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    members
} as const);

export const addTeamMemberDepartmentManagementAC = (member: CertainTeamMemberType) => ({
    type: 'ADD-TEAM-MEMBER-DEPARTMENT-MANAGEMENT',
    member
} as const);
export const removeMemberDepartmentManagementAC = (memberId: string) => ({
    type: 'REMOVE-TEAM-MEMBER-DEPARTMENT-MANAGEMENT',
    memberId
} as const);

export const changeDepartmentNameDepartmentManagementAC = (name: string) => ({
    type: 'CHANGE-DEPARTMENT-NAME-DEPARTMENT-MANAGEMENT',
    name
} as const);
export const inviteTeamLeaderDepartmentManagementAC = (teamLeader: DepartmentManagementTeamType) => ({
    type: 'INVITE-TEAM-LEADER-DEPARTMENT-MANAGEMENT',
    teamLeader
} as const);
export const deleteTeamLeaderDepartmentManagementAC = (teamId: number) => ({
    type: 'DELETE-TEAM-LEADER-DEPARTMENT-MANAGEMENT',
    teamId
} as const);

export const clearDepartmentManagementStateAC = () => ({type: 'CLEAR-STATE-DEPARTMENT-MANAGEMENT'} as const);

export const fixSecondaryTeamLeaderDepartmentManagementAC = (leader: string | null) => ({
    type: 'DEPARTMENT-MANAGEMENT-FIX-SECONDARY_TEAM-LEADER', leader
} as const);

export const updateTeamLeaderSecondaryNameDepartmentManagementAC = (teamId: number | string, secondaryName: string | null) => ({
    type: 'UPDATE-TEAM-LEADER-SECONDARY-NAME-DEPARTMENT-MANAGEMENT',
    teamId, secondaryName
} as const);

export const getAllDepartmentsDepartmentManagementTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.getDepartments().then(res => {
        let chosenDepartment = getState().departmentManagement.chosenDepartment;
        //если департамент не выбран, то мы устанавливаем первый из полученного списка
        if (!chosenDepartment) {
            dispatch(setChosenDepartmentDepartmentManagementAC(res.data.departments[0]));
        }
        dispatch(setDepartmentsDepartmentManagementAC(res.data.departments));
    }).catch(err => {
        alert('wrong getting departments on department management');
    })
}

export const getDepartmentInfoDepartmentManagementTC = (departmentId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingDepartmentManagementAC(true));
    departmentManagementAPI.getDepartmentInfo(departmentId).then(res => {
        dispatch(setDepartmentInfoDepartmentManagementAC(res.data.department));
        dispatch(setLoadingDepartmentManagementAC(false));
    }).catch(err => {
        dispatch(setLoadingDepartmentManagementAC(false));
        alert('wrong getting info on department management');
    })
}

//для страницы конкретно выбранной команды
export const getCertainTeamInfoDepartmentManagementTC = (teamId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.getCertainTeamInfo(teamId).then(res => {
        let info = res.data.team;
        let newMembers = info.members.map((i: any) => ({...i, id: v1()}));
        info.members = newMembers;
        dispatch(setCertainTeamInfoDepartmentManagementAC(info));
    }).catch(err => {
        alert('wrong getting info for certain team department management');
    })
}

export const getInfoDepartmentCheckinReportDepartmentManagementTC = (teamId: number | string, date: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.getCheckinReportInfo(teamId, date).then(res => {
        let members = res.data.answers.map((m: CheckinReportMemberType) => ({...m, id: v1()}))
        dispatch(setMembersDepartmentCheckinReportDepartmentManagementAC(members));
    }).catch(err => {
        alert('wrong getting info for departmentCheckinReport department management');
    })
}

export const getInfoCertainTeamCheckinReportDepartmentManagementTC = (teamId: number, date: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.getCheckinReportInfo(teamId, date).then(res => {
        let members = res.data.answers.map((m: CheckinReportMemberType) => ({...m, id: v1()}))
        dispatch(setMembersCertainTeamCheckinReportDepartmentManagementAC(members));
    }).catch(err => {
        alert('wrong getting info for departmentCheckinReport for certain team department management');
    })
}

export const changeDepartmentManagerTC = (departmentId: number, info: {
    firstName: string,
    lastName: string,
    email: string,
    keep: boolean
}): AppThunk => async (dispatch, getState: () => RootStateType) => {

    departmentManagementAPI.changeDepartmentManager(departmentId, info).then(res => {
    }).catch(err => {
        alert('wrong changing department manager on department management');
    })
}

export const changeDepartmentNameTC = (departmentId: number,
                                       name: string,
                                       setError: (error: boolean) => void,
                                       setNotification: (notification: string) => void,
                                       navigate: (url: string) => void
): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let departments = getState().departmentManagement.departments;
    departmentManagementAPI.changeDepartmentName(departmentId, name).then(res => {
        let fixedDepartments = departments.map(d => d.id === departmentId ? {...d, name: name} : d)
        dispatch(changeDepartmentNameDepartmentManagementAC(name));
        dispatch(setDepartmentsDepartmentManagementAC(fixedDepartments));
    }).catch(error => {
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else if (status === 403) {
            setError(true);
            setNotification(error.response.data.message);
        } else {
            navigate('/pageOops');
        }
    })
}

export const deleteDepartmentTC = (departmentId: number, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.deleteDepartment(departmentId).then(res => {
        dispatch(setChosenDepartmentDepartmentManagementAC(null));
        navigate('/dashboard');
    }).catch(err => {
        alert('wrong delete department manager');
    })
}

export const inviteTeamLeaderDepartmentManagementTC = (departmentId: number, info: {
    firstName: string,
    lastName: string,
    email: string
}, teamLeader: DepartmentManagementTeamType,
   closeModalWindow: () => void,
   setError: (n: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.inviteTeamLeader(departmentId, info).then(res => {
        dispatch(inviteTeamLeaderDepartmentManagementAC(teamLeader));
        closeModalWindow();
    }).catch(err => {
        setError(err.response.data.message)
    })
}

export const reinviteTeamLeaderDepartmentManagementTC = (departmentId: number,
                                                         email: string,
                                                         setError: (notification: string) => void,
                                                         setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.reinviteTeamLeader(departmentId, email).then(res => {
        setSuccess('The invitation will be resent.')
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const deleteTeamLeaderDepartmentManagementTC = (departmentId: number,
                                                       email: string,
                                                       teamId: number,
                                                       setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    if (teamId < 0) {
        departmentManagementAPI.deleteInviteTeamLeader(departmentId, email).then(res => {
            dispatch(deleteTeamLeaderDepartmentManagementAC(teamId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    } else {
        departmentManagementAPI.deleteTeamLeader(teamId).then(res => {
            dispatch(deleteTeamLeaderDepartmentManagementAC(teamId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    }
}

export const nudgeTeamDepartmentManagementTC = (teamId: number,
                                                setError: (notification: string) => void,
                                                setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.nudgeTeam(teamId).then(res => {
        setSuccess('Leader will be nudged.')
    }).catch(err => {
        setError(err.response.data.message);
    })
}

//для всего, что касается мемберов, использую апишку с тим мэнэджмента
export const changeTeamLeaderDepartmentManagementTC = (teamId: number, info: {
    firstName: string,
    lastName: string,
    email: string,
    keep: boolean
}): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.changeTeamLeader(teamId, info).then(res => {

    }).catch(err => {
        alert('wrong changing team leader on department management');
    })
}

export const addTeamMemberDepartmentManagementTC = (teamId: number, info: {
    firstName: string,
    lastName: string,
    email: string
}, member: CertainTeamMemberType,
   closeModalWindow: () => void,
   setError: (n: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    teamManagementAPI.addTeamMember(teamId, info).then(res => {
        dispatch(addTeamMemberDepartmentManagementAC(member));
        closeModalWindow();
    }).catch(err => {
        setError(err.response.data.message)
    })
}

export const nudgeTeamMemberDepartmentManagementTC = (teamId: number,
                                                      email: string,
                                                      setError: (notification: string) => void,
                                                      setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.nudgeMember(teamId, {email}).then(res => {
        setSuccess('Member will be nudged.')
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const reinviteTeamMemberDepartmentManagementTC = (teamId: number,
                                                         email: string,
                                                         setError: (notification: string) => void,
                                                         setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.reinviteMember(teamId, {email}).then(res => {
        setSuccess('The invitation will be resent.')
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const removeTeamMemberDepartmentManagementTC = (memberStatus: boolean,
                                                       teamId: number,
                                                       email: string,
                                                       memberId: string,
                                                       setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    if (memberStatus) {
        teamManagementAPI.removeTeamMember(teamId, {email: email}).then(res => {
            dispatch(removeMemberDepartmentManagementAC(memberId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    } else {
        teamManagementAPI.removeTeamMemberInvite(teamId, {email: email}).then(res => {
            dispatch(removeMemberDepartmentManagementAC(memberId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    }
}

export const addNewSecondaryTeamLeaderTC = (teamId: string | number,
                                            userId: string | number | null,
                                            invitedUserInfo: { email: string, firstName: string, lastName: string },
                                            setError: (notification: string) => void,
                                            setSuccess: (notification: string) => void,
                                            closeModalWindow: () => void,
                                            whereFixSecondaryName: 'departmentManagement' | 'teamManagement' | null,
                                            fixTeams?: 'departmentManagement' | 'companyManagement'
): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    departmentManagementAPI.addNewSecondaryTeamLeader(teamId, invitedUserInfo).then(res => {
        if (whereFixSecondaryName) {
            whereFixSecondaryName === 'departmentManagement' && dispatch(fixSecondaryTeamLeaderDepartmentManagementAC('Pending'));
            whereFixSecondaryName === 'teamManagement' && dispatch(fixSecondaryTeamLeaderTeamManagementAC('Pending'));
        }

        //опционально. передаю на странице department management для обновления ui
        fixTeams && fixTeams === 'departmentManagement' && dispatch(updateTeamLeaderSecondaryNameDepartmentManagementAC(teamId, 'Pending'));
        fixTeams && fixTeams === 'companyManagement' && dispatch(updateTeamLeaderSecondaryNameCompanyManagementAC(teamId, 'Pending'));

        dispatch(setLoadingCommonReducerAC(false));
        closeModalWindow();
    }).catch(err => {
        setError(err.response.data.message);
        dispatch(setLoadingCommonReducerAC(false));
    })
}

export const chooseNewSecondaryTeamLeaderTC = (teamId: string | number,
                                               userId: number | string,
                                               setError: (notification: string) => void,
                                               setSuccess: (notification: string) => void,
                                               closeModalWindow: () => void,
                                               whereFixSecondaryName: 'departmentManagement' | 'teamManagement',
                                               fixTeams?: 'departmentManagement' | 'companyManagement'): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    departmentManagementAPI.chooseSecondaryTeamLeader(teamId, userId).then(res => {
        let membersFromTeamManagement = getState().teamManagement.teamInformation.members;
        let membersFromDepartmentManagement = getState().departmentManagement.certainTeamPage.certainTeamPageInfo.members;

        let checkMember = whereFixSecondaryName === 'teamManagement' ? membersFromTeamManagement.some(m => m.id === userId.toString())
            : whereFixSecondaryName === 'departmentManagement' ? membersFromDepartmentManagement.some(m => m.id === userId.toString())
                : false;

        if (checkMember) {
            whereFixSecondaryName === 'teamManagement' && dispatch(removeMemberTeamManagementAC(userId.toString()));
            whereFixSecondaryName === 'departmentManagement' && dispatch(removeMemberDepartmentManagementAC(userId.toString()));
        }

        whereFixSecondaryName === 'departmentManagement' && dispatch(fixSecondaryTeamLeaderDepartmentManagementAC(res.data.secondaryLeaderName));
        whereFixSecondaryName === 'teamManagement' && dispatch(fixSecondaryTeamLeaderTeamManagementAC(res.data.secondaryLeaderName));
        dispatch(setLoadingCommonReducerAC(false));
        //опционально. передаю на странице department management для обновления ui
        fixTeams && fixTeams === 'departmentManagement' && dispatch(updateTeamLeaderSecondaryNameDepartmentManagementAC(teamId, res.data.secondaryLeaderName));
        fixTeams && fixTeams === 'companyManagement' && dispatch(updateTeamLeaderSecondaryNameCompanyManagementAC(teamId, res.data.secondaryLeaderName));
        closeModalWindow();
    }).catch(err => {
        setError(err.response.data.message);
        dispatch(setLoadingCommonReducerAC(false));
    })
}

export const changeSecondaryTeamLeaderTC = (teamId: string | number,
                                            userId: string | number | null,
                                            invitedUserInfo: { email: string, firstName: string, lastName: string },
                                            infoKeep: { keep: boolean },
                                            setError: (notification: string) => void,
                                            setSuccess: (notification: string) => void,
                                            closeModalWindow: () => void,
                                            // whereFixSecondaryName: 'departmentManagement' | 'teamManagement' | null,
                                            // fixTeams?: 'departmentManagement' | 'companyManagement'
): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));

    let info = {...invitedUserInfo, keep: infoKeep.keep}

    departmentManagementAPI.changeSecondaryTeamLeader(teamId, info).then(res => {
        dispatch(setLoadingCommonReducerAC(false));
        closeModalWindow();
    }).catch(err => {

        dispatch(setLoadingCommonReducerAC(false));
        setError(err.response.data?.message);
    })

}

//01112023 поменяли логику, теперь changeSecondaryTeamLeaderTC смотри выше
// export const changeSecondaryTeamLeaderTC = (teamId: string | number,
//                                             userId: number | string,
//                                             infoKeep: { keep: boolean },
//                                             setError: (notification: string) => void,
//                                             setSuccess: (notification: string) => void,
//                                             closeModalWindow: () => void,
//                                             whereFixSecondaryName: 'departmentManagement' | 'teamManagement',): AppThunk => async (dispatch, getState: () => RootStateType) => {
//     dispatch(setLoadingCommonReducerAC(true));
//
//     departmentManagementAPI.changeSecondaryTeamLeader(teamId, userId, infoKeep).then(res => {
//
//         let membersFromTeamManagement = getState().teamManagement.teamInformation.members;
//         let membersFromDepartmentManagement = getState().departmentManagement.certainTeamPage.certainTeamPageInfo.members;
//
//         let checkMember = whereFixSecondaryName === 'teamManagement' ? membersFromTeamManagement.some(m => m.id === userId.toString())
//             : whereFixSecondaryName === 'departmentManagement' ? membersFromDepartmentManagement.some(m => m.id === userId.toString())
//                 : false;
//
//         if (checkMember) {
//             whereFixSecondaryName === 'teamManagement' && dispatch(removeMemberTeamManagementAC(userId.toString()));
//             whereFixSecondaryName === 'departmentManagement' && dispatch(removeMemberDepartmentManagementAC(userId.toString()));
//         } else if (res.data.user !== null) {
//             let userForDeleteUser = {
//                 ...res.data.user,
//                 id: res.data.user.id.toString()
//             }
//
//             whereFixSecondaryName === 'departmentManagement' && delete userForDeleteUser?.isTeamLeader;
//             whereFixSecondaryName === 'departmentManagement' && dispatch(addTeamMemberDepartmentManagementAC(userForDeleteUser));
//             whereFixSecondaryName === 'teamManagement' && dispatch(addMemberTeamManagementAC(userForDeleteUser));
//         }
//
//
//         whereFixSecondaryName === 'departmentManagement' && dispatch(fixSecondaryTeamLeaderDepartmentManagementAC(res.data.secondaryLeaderName));
//         whereFixSecondaryName === 'teamManagement' && dispatch(fixSecondaryTeamLeaderTeamManagementAC(res.data.secondaryLeaderName));
//         dispatch(setLoadingCommonReducerAC(false));
//         closeModalWindow();
//     }).catch(err => {
//         setError(err.response.data?.message);
//         dispatch(setLoadingCommonReducerAC(false));
//     })
// }

export const removeSecondaryTeamLeaderTC = (teamId: string | number,
                                            info: { keep: boolean },
                                            setError: (notification: string) => void,
                                            setSuccess: (notification: string) => void,
                                            closeModalWindow: () => void,
                                            whereFixSecondaryName: 'departmentManagement' | 'teamManagement',): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));

    departmentManagementAPI.removeSecondaryTeamLeader(teamId, info).then(res => {

        if (typeof res.data === 'object') {
            let userForDeleteUser = {
                ...res.data,
                id: res.data.id.toString()
            }

            whereFixSecondaryName === 'departmentManagement' && delete userForDeleteUser?.isTeamLeader;
            whereFixSecondaryName === 'departmentManagement' && dispatch(addTeamMemberDepartmentManagementAC(userForDeleteUser));
            whereFixSecondaryName === 'teamManagement' && dispatch(addMemberTeamManagementAC(userForDeleteUser));
        }

        whereFixSecondaryName === 'departmentManagement' && dispatch(fixSecondaryTeamLeaderDepartmentManagementAC(null));
        whereFixSecondaryName === 'teamManagement' && dispatch(fixSecondaryTeamLeaderTeamManagementAC(null));
        dispatch(setLoadingCommonReducerAC(false));
        closeModalWindow();
    }).catch(err => {
        setError(err.response?.data?.message);
        dispatch(setLoadingCommonReducerAC(false));
    })
}