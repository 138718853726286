import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {questionnaireAPI, teamSelectionForCheckinsAPI} from "../../api/api";
import {AnswerType} from "../../components/pages/questionnaire/checkinsQuestionnaire";

export type NormQuestionFromServerCheckinsQuestionnaireType = {
    label: string,
    normId: number,
    value: string,
}

export type MonthQuestionCheckinsQuestionnaireType = any;
export type UserTypeCheckinsQuestionnaireType = {
    email: string,
    id: number,
    firstName: string,
    lastName: string,
    notApplicable?: boolean
}

export type TeamType = {
    id: number,
    name: string,
    users: Array<UserTypeCheckinsQuestionnaireType>,
}

export type CheckinsQuestionnaireStateType = {
    companyName: string,
    isAdmin: boolean | null,
    emotion: number,
    isApplicableEmotions: boolean,
    isApplicableSelfProductivity: boolean,
    affectingFactors: Array<any>,
    elaborateField: any | null,
    todayProductivity: number,
    workEnvironmentOptions: any | null,
    isApplicableMotivator: boolean,
    isApplicableNorms: boolean,
    isApplicableWorkEnv: boolean,
    motivator: number,
    motivatorQuestion: any,
    motivatorQuestionText: string,
    normQuestions: Array<{label: string, normId: number, value: string}> | null,
    monthquestions: Array<any> | null,
    monthDay: boolean,
    workEnvironmentServer: any,
    motivatorQuestionId: number | null,
    workEnvironment: null | Array<number>,
    norms: {[key: number]: number} | {},
    monthQuestionsAnswers: any | {},
    answers: {[key: number]: number} | {},
    team: TeamType | null,
    period: string,
    surveys: Array<string> | null,
    teamName: string,
    showModal: boolean,
    message: string,
    errorStatus: null | number,
    isApplicableMonthQuestion: Array<any>,
}

const initialState: CheckinsQuestionnaireStateType = {
    companyName: '',
    isAdmin: null,
    emotion: 9,
    isApplicableEmotions: true,
    isApplicableSelfProductivity: true,
    affectingFactors: [],
    elaborateField: null,
    todayProductivity: 0,
    workEnvironmentOptions: null,
    isApplicableMotivator: true,
    isApplicableNorms: true,
    isApplicableWorkEnv: true,
    motivator: 0,
    motivatorQuestion: [],
    motivatorQuestionText: '',
    normQuestions: null,
    monthquestions: null,
    monthDay: false,
    workEnvironmentServer: undefined,
    motivatorQuestionId: null,
    workEnvironment: null,
    norms: {},
    monthQuestionsAnswers: {},
    answers: {},
    team: null,
    period: '',
    surveys: null,
    teamName: '',
    showModal: false,
    message: "",
    errorStatus: null,
    isApplicableMonthQuestion: [],
}

export const checkinsQuestionnaireReducer = (state = initialState, action: AllActionType): CheckinsQuestionnaireStateType => {
    switch(action.type) {
        case 'SET-MODAL-WINDOW-MODE-CHECKINS-QUESTIONNAIRE': {
            return {...state, message: action.message, errorStatus: action.errorStatus, showModal: action.showModal}
        }
        case 'SET-INFO-CHECKINS-QUESTIONNAIRE': {
            return {...state, ...action.info}
        }
        case 'SET-NON-APPLICABLE-EMOTIONS-CHECKINS-QUESTIONNAIRE': {
            return {...state, isApplicableEmotions: false, emotion: 2, elaborateField: null, affectingFactors: []}
        }
        case 'SET-EMOTION-CHECKINS-QUESTIONNAIRE': {
            return {...state, emotion: action.emotion}
        }
        case 'SET-AFFECTING-FACTORS-CHECKINS-QUESTIONNAIRE': {
            return {...state, affectingFactors: [...action.factors]}
        }
        case 'SET-ELABORATE-FIELD-CHECKINS-QUESTIONNAIRE': {
            return {...state, elaborateField: action.field}
        }
        case 'CHANGE-PRODUCTIVITY-TEAM-MEMBER-CHECKINS-QUESTIONNAIRE': {
            return {...state, answers: {...state.answers, [action.id]: action.value}}
        }
        case 'SET-NON-APPLICABLE-CHECKINS-QUESTIONNAIRE': {
            return {...state, answers: {...state.answers, [action.id]: 0,}, team: state.team && {...state.team, users: [...action.users]}}
        }
        case 'CHANGE-PRODUCTIVITY-CHECKINS-QUESTIONNAIRE': {
            return {...state, todayProductivity: action.productivity}
        }
        case 'SET-NON-APPLICABLE-SELF-PRODUCTIVITY-CHECKINS-QUESTIONNAIRE': {
            return {...state, isApplicableSelfProductivity: false, todayProductivity: 0}
        }
        case 'CHANGE-MONTH-QUESTIONS-ANSWERS-CHECKINS-QUESTIONNAIRE': {
            return {...state, monthQuestionsAnswers: {...state.monthQuestionsAnswers, [action.id]: action.value}}
        }
        case 'SET-IS-APPLICABLE-MONTH-QUESTION-CHECKINS-QUESTIONNAIRE': {
            return {...state, isApplicableMonthQuestion: [...action.isApplicableMonthQuestion]}
        }
        case 'SET-NON-APPLICABLE-MOTIVATOR-CHECKINS-QUESTIONNAIRE': {
            return {...state, isApplicableMotivator: false}
        }
        case 'CHANGE-MOTIVATORS-CHECKINS-QUESTIONNAIRE': {
            return {...state, motivator: action.motivator}
        }
        case 'CHANGE-NORMS-CHECKINS-QUESTIONNAIRE': {
            return {...state, norms: {...state.norms, [action.id]: action.norm}}
        }
        case 'SET-NON-APPLICABLE-NORMS-CHECKINS-QUESTIONNAIRE': {
            return {...state, isApplicableNorms: false}
        }
        case 'CHANGE-WORK-ENVIRONMENT-CHECKINS-QUESTIONNAIRE': {
            return {...state, workEnvironment: action.environment}
        }
        case 'CHANGE-WORK-ENVIRONMENT-OPTIONS-CHECKINS-QUESTIONNAIRE': {
            return {...state, workEnvironmentOptions: action.options}
        }
        case 'SET-NON-APPLICABLE-WORK-ENV-CHECKINS-QUESTIONNAIRE': {
            return {...state, isApplicableWorkEnv: false}
        }
        default: return state
    }
}

export type setInfoCheckinsQuestionnaireACType = ReturnType<typeof setInfoCheckinsQuestionnaireAC>;
export type setModalWindowModeCheckinsQuestionnaireACType = ReturnType<typeof setModalWindowModeCheckinsQuestionnaireAC>;
export type setNonApplicableEmotionCheckinsQuestionnaireACType = ReturnType<typeof setNonApplicableEmotionCheckinsQuestionnaireAC>;
export type setEmotionCheckinsQuestionnaireACType = ReturnType<typeof setEmotionCheckinsQuestionnaireAC>;
export type setAffectingFactorsCheckinsQuestionnaireACType = ReturnType<typeof setAffectingFactorsCheckinsQuestionnaireAC>;
export type setElaborateFieldCheckinsQuestionnaireACType = ReturnType<typeof setElaborateFieldCheckinsQuestionnaireAC>;
export type changeProductivityTeamMemberCheckinsQuestionnaireACType = ReturnType<typeof changeProductivityTeamMemberCheckinsQuestionnaireAC>;
export type setNotApplicableCheckinsQuestionnaireACType = ReturnType<typeof setNotApplicableCheckinsQuestionnaireAC>;
export type changeProductivityCheckinsQuestionnaireACType = ReturnType<typeof changeProductivityCheckinsQuestionnaireAC>;
export type setNonApplicableSelfProductivityCheckinsQuestionnaireACType = ReturnType<typeof setNonApplicableSelfProductivityCheckinsQuestionnaireAC>;
export type changeMonthQuestionsAnswersCheckinsQuestionnaireACType = ReturnType<typeof changeMonthQuestionsAnswersCheckinsQuestionnaireAC>;
export type setIsApplicableMonthQuestionCheckinsQuestionnaireACType = ReturnType<typeof setIsApplicableMonthQuestionCheckinsQuestionnaireAC>;
export type setNonApplicableMotivatorCheckinsQuestionnaireACType = ReturnType<typeof setNonApplicableMotivatorCheckinsQuestionnaireAC>;
export type changeMotivatorsCheckinsQuestionnaireACType = ReturnType<typeof changeMotivatorsCheckinsQuestionnaireAC>;
export type changeNormsCheckinsQuestionnaireACType = ReturnType<typeof changeNormsCheckinsQuestionnaireAC>;
export type setNonApplicableNormsCheckinsQuestionnaireACType = ReturnType<typeof setNonApplicableNormsCheckinsQuestionnaireAC>;
export type changeWorkEnvironmentCheckinsQuestionnaireACType = ReturnType<typeof changeWorkEnvironmentCheckinsQuestionnaireAC>;
export type changeWorkEnvironmentOptionsCheckinsQuestionnaireACType = ReturnType<typeof changeWorkEnvironmentOptionsCheckinsQuestionnaireAC>;
export type setNonApplicableWorkEnvCheckinsQuestionnaireACType = ReturnType<typeof setNonApplicableWorkEnvCheckinsQuestionnaireAC>;

export const setInfoCheckinsQuestionnaireAC = (info: any) => ({type: 'SET-INFO-CHECKINS-QUESTIONNAIRE', info}as const);
export const setModalWindowModeCheckinsQuestionnaireAC = (message: string, errorStatus: number, showModal: boolean) => ({type: 'SET-MODAL-WINDOW-MODE-CHECKINS-QUESTIONNAIRE', message, errorStatus, showModal}as const);
export const setNonApplicableEmotionCheckinsQuestionnaireAC = () => ({type: 'SET-NON-APPLICABLE-EMOTIONS-CHECKINS-QUESTIONNAIRE'}as const);
export const setEmotionCheckinsQuestionnaireAC = (emotion: number) => ({type: 'SET-EMOTION-CHECKINS-QUESTIONNAIRE', emotion}as const);
export const setAffectingFactorsCheckinsQuestionnaireAC = (factors: Array<any>) => ({type: 'SET-AFFECTING-FACTORS-CHECKINS-QUESTIONNAIRE', factors}as const);
export const setElaborateFieldCheckinsQuestionnaireAC = (field: any) => ({type: 'SET-ELABORATE-FIELD-CHECKINS-QUESTIONNAIRE', field}as const);
export const changeProductivityTeamMemberCheckinsQuestionnaireAC = (id: number, value: number) => ({type: 'CHANGE-PRODUCTIVITY-TEAM-MEMBER-CHECKINS-QUESTIONNAIRE', id, value}as const);
export const setNotApplicableCheckinsQuestionnaireAC = (id: number, users: Array<UserTypeCheckinsQuestionnaireType>) => ({type: 'SET-NON-APPLICABLE-CHECKINS-QUESTIONNAIRE', id, users}as const);
export const changeProductivityCheckinsQuestionnaireAC = (productivity: number) => ({type: 'CHANGE-PRODUCTIVITY-CHECKINS-QUESTIONNAIRE', productivity}as const);
export const setNonApplicableSelfProductivityCheckinsQuestionnaireAC = () => ({type: 'SET-NON-APPLICABLE-SELF-PRODUCTIVITY-CHECKINS-QUESTIONNAIRE'}as const);
export const changeMonthQuestionsAnswersCheckinsQuestionnaireAC = (id: number, value: string) => ({type: 'CHANGE-MONTH-QUESTIONS-ANSWERS-CHECKINS-QUESTIONNAIRE', id, value}as const);
export const setIsApplicableMonthQuestionCheckinsQuestionnaireAC = (isApplicableMonthQuestion: Array<any>) => ({type: 'SET-IS-APPLICABLE-MONTH-QUESTION-CHECKINS-QUESTIONNAIRE', isApplicableMonthQuestion}as const);
export const setNonApplicableMotivatorCheckinsQuestionnaireAC = () => ({type: 'SET-NON-APPLICABLE-MOTIVATOR-CHECKINS-QUESTIONNAIRE'}as const);
export const changeMotivatorsCheckinsQuestionnaireAC = (motivator: number) => ({type: 'CHANGE-MOTIVATORS-CHECKINS-QUESTIONNAIRE', motivator}as const);
export const changeNormsCheckinsQuestionnaireAC = (id: number, norm: string) => ({type: 'CHANGE-NORMS-CHECKINS-QUESTIONNAIRE', id, norm}as const);
export const setNonApplicableNormsCheckinsQuestionnaireAC = () => ({type: 'SET-NON-APPLICABLE-NORMS-CHECKINS-QUESTIONNAIRE'}as const);
export const changeWorkEnvironmentCheckinsQuestionnaireAC = (environment: Array<number>) => ({type: 'CHANGE-WORK-ENVIRONMENT-CHECKINS-QUESTIONNAIRE', environment}as const);
export const changeWorkEnvironmentOptionsCheckinsQuestionnaireAC = (options: string) => ({type: 'CHANGE-WORK-ENVIRONMENT-OPTIONS-CHECKINS-QUESTIONNAIRE', options}as const);
export const setNonApplicableWorkEnvCheckinsQuestionnaireAC = () => ({type: 'SET-NON-APPLICABLE-WORK-ENV-CHECKINS-QUESTIONNAIRE'}as const);

export const getInfoCheckinsQuestionnaireTC = (token: string, state: any, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
   //это стейт из location
    questionnaireAPI.getQuestions(token).then(res => {

        let norms = {};
        let monthQuestionsAnswers = {};
        let answers = {};
        let isApplicableMonthQuestion: any = [];

        res.data.normQuestions && res.data.normQuestions.forEach((item: NormQuestionFromServerCheckinsQuestionnaireType) => norms = {...norms, [item.normId]: 1});
        res.data.monthquestions && res.data.monthquestions.length && res.data.monthquestions.forEach((item: MonthQuestionCheckinsQuestionnaireType) => {
            monthQuestionsAnswers = {...monthQuestionsAnswers, [item.id]: 0};
            isApplicableMonthQuestion.push({
                id: item.id,
                isApplicable: true
            })
            }
        );
        
        res.data.team && res.data.team.users && res.data.team.users.length && res.data.team.users.forEach((item: UserTypeCheckinsQuestionnaireType) => answers = {...answers, [item.id]: 0})

        let info = {
            companyName: res.data.companyName,
            isAdmin: res.data.isAdmin,
            motivatorQuestion: res.data.motivatorQuestion,
            motivatorQuestionText: res.data.motivatorQuestion !== null ? res.data.motivatorQuestion.type : '',
            normQuestions: res.data.normQuestions,
            monthquestions: res.data.monthquestions,
            monthDay: res.data.monthDay,
            workEnvironmentServer: res.data.workEnvironment,
            motivatorQuestionId: res.data.motivatorQuestion
                ? res.data.motivatorQuestion.id
                : null,
            workEnvironment: 1,
            norms,
            monthQuestionsAnswers,
            answers,
            team: res.data.isAdmin ? res.data.team : null,
            period: res.data.period,
            surveys: state ? state.surveys : null,
            teamName: res.data.teamName,
            isApplicableMonthQuestion: isApplicableMonthQuestion
        }


        dispatch(setInfoCheckinsQuestionnaireAC(info));
    }).catch(error => {
        let status = error.response && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate("/404");
        } else if (status === 422) {
            dispatch(setModalWindowModeCheckinsQuestionnaireAC(error.response.data.message, 422, true));
        } else if (status === 400) {
            dispatch(setModalWindowModeCheckinsQuestionnaireAC('', 400, true));
        } else {
            navigate("/oops");
        }
    })
}

export const sendAnswersCheckinsQuestionnaireTC = (token: string, state: any,  info: AnswerType, navigate: (url: string, {}) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    questionnaireAPI.sendAnswers(token, info).then(res => {
        if(state.surveys && state.surveys.length !== 0) {
            let newToken = state.surveys[0];
            let filteredSurveys = state.surveys.filter((survey: string) => survey !== newToken);
            navigate(`/questionnaire/${newToken}`, {state: {surveys: filteredSurveys}});
            window.location.reload()
        } else {
            dispatch(setModalWindowModeCheckinsQuestionnaireAC('', 0, true));
        }
    })
}