import React, {useCallback, useEffect, useState} from "react";
import style from './deleteModalWindowBody.module.scss';
import ButtonWithImage from "../../../../parts/buttonWithImage/buttonWithImage";
import save from './../../../../../assets/images/common/save.png';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";


import {LoaderDots} from "@thumbtack/thumbprint-react";
import {SubmitHandler, useForm} from "react-hook-form";
import {removeSecondaryTeamLeaderTC} from "../../../../../redux/reducers/departmentManagementReducer";

type DeleteModalWindowPropsType = {
    itemType: 'admin' | 'department' | 'team' | 'member' | null,
    actionType: 'add' | 'change' | 'choose' | 'delete' | null,
    chosenItemForModalWindowId: number | string,
    closeModalWindow: () => void,
    setError: (err: string) => void,
    setSuccess: (notification: string) => void,
    whereFixSecondaryName: 'departmentManagement' | 'teamManagement',
}

type InputsType = {
    firstName: string,
    lastName: string,
    email: string,
    choice: string,
}

const DeleteModalWindowBody = ({
                                   itemType,
                                   actionType,
                                   closeModalWindow,
                                   chosenItemForModalWindowId,
                                   setError,
                                   setSuccess,
                                   whereFixSecondaryName
                               }: DeleteModalWindowPropsType) => {
    const dispatch = useDispatch();
    const loading = useSelector((state: RootStateType) => state.common.loading);

    let secondaryLeaderName = useSelector((state: RootStateType) => state.departmentManagement.certainTeamPage.certainTeamPageInfo.secondaryLeaderName);

    const {register, handleSubmit, watch, formState: {errors}} = useForm<InputsType>({
        defaultValues: {
            choice: '2',
        }
    });

    const onDeleteSubmit: SubmitHandler<InputsType> = (data) => {
        let info = {
            keep: data.choice === '2'
        }

        chosenItemForModalWindowId ? dispatch(removeSecondaryTeamLeaderTC(chosenItemForModalWindowId,
            info, setError, setSuccess, closeModalWindow, whereFixSecondaryName)) : null;
    }

    return (
        <>
            {itemType === 'team' ? <p className={style.title_hint}>You remove Secondary Team Leader </p> : null}
            {/*{itemType === 'team' ? <p>You are changing your Team Leader for the {departmentNameForTeamLeaderChange}</p> : null}*/}
            <form onSubmit={handleSubmit(onDeleteSubmit)}>
                <div className={style.choice_wrapper}>
                    <input {...register('choice')} id='choice1' type="radio" value="1"/>
                    <label htmlFor='choice1'>Remove previous leader from team</label>
                </div>
                <div className={style.choice_wrapper}>
                    <input  {...register('choice')} id='choice2' type="radio" value="2"/>
                    <label htmlFor='choice2'>Leave the previous leader as a team member</label>
                </div>

                <div className={style.button_wrapper}>
                    <ButtonWithImage height={46}
                                     name={'Save'}
                                     type={'submit'}
                                     margin={'0 auto'}
                                     disabled={loading}
                                     img={save}></ButtonWithImage>
                </div>
            </form>
        </>
    )
}
export default DeleteModalWindowBody;