import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {createTeamApi} from "../../api/api";
import {v1} from "uuid";
import {addRoleAC, setCurrentStatusAC} from "./commonReducer";

export type ModalWindowInfoType = {
    company: {
        id: number,
        name: string
    } | null,
    teams: Array<{id: number, name: string}> | null
}

type DepartmentType = {id: number, name: string};
type FrequencyType = {id: number, name: string};
type PerformingScoreType = {id: number, name: string};
type TeamType = {
    checkinDay?: null | number,
    companyId?: number,
    departamentId?: number | null,
    departmentName?: string | null,
    frequencyId?: number | null,
    id?: number,
    invites?: Array<{id: string, value: string}>,
    name?: string | null,
    performingScoreId?: number | null,
    surveysTimeSend?: string | null,
    workingDays?: Array<boolean> | null
} | null;

type CreateTeamPageType = {
    departments: Array<DepartmentType>,
    frequency: Array<FrequencyType>,
    performingScore: Array<PerformingScoreType>,
    team: TeamType
}


export type CreateTeamStateType = {
    loading: boolean,
    modalWindow: ModalWindowInfoType,
    createTeamPage: CreateTeamPageType,
}

const initialState: CreateTeamStateType = {
    loading: false,
    modalWindow: {
        company: null,
        teams: null
    },
    createTeamPage: {
        departments: [],
        frequency: [],
        performingScore: [],
        team: null
    }
}

export const createTeamReducer = (state = initialState, action: AllActionType): CreateTeamStateType => {
    switch (action.type) {
        case 'CREATE-TEAM-SET-LOADING': {
            return {...state, loading: action.loading}
        }
        case 'CREATE-TEAM-SET-MODAL-WINDOW-INFO': {
            return {...state, modalWindow: {...state.modalWindow, ...action.info}}
        }
        // case 'CREATE-TEAM-SET-INFO-FOR-NEW-CREATION': {
        //     return {...state, createTeamPage: {
        //         ...state.createTeamPage,
        //             departments: [...action.info.departments],
        //             frequency: [...action.info.frequency],
        //             performingScore: [...action.info.performingScore],
        //             team: action.info.team
        //         }}
        // }

        case 'CREATE-TEAM-SET-INFO-CONTINUE': {
            return {...state, createTeamPage: {
                    ...state.createTeamPage,
                    departments: [...action.info.departments],
                    frequency: [...action.info.frequency],
                    performingScore: [...action.info.performingScore],
                    team: {...action.info.team}
                }}
        }

        case 'CREATE-TEAM-SET-DEPARTMENT-ID': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, departamentId: action.id}}}
        }
        case 'CREATE-TEAM-SET-TEAM-NAME': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, name: action.name}}}
        }
        case 'CREATE-TEAM-SET-DEPARTMENT-NAME': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, departmentName: action.name}}}
        }
        case 'CREATE-TEAM-SET-EMAILS': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, invites: [...action.emails]}}}
        }
        case 'CREATE-TEAM-SET-PERFORMING-ID': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, performingScoreId: action.id}}}
        }
        case 'CREATE-TEAM-SET-CHECKIN-DAY': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, checkinDay: action.day}}}
        }
        case 'CREATE-TEAM-SET-FREQUENCY-ID': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, frequencyId: action.frequency}}}
        }
        case  'CREATE-TEAM-SET-TIME': {

            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, surveysTimeSend: action.time}}}
        }
        case 'CREATE-TEAM-CHANGE-WORKING-DAYS': {
            return {...state, createTeamPage: {...state.createTeamPage, team: {...state.createTeamPage.team, workingDays: [...action.days]}}}
        }

        default: return state
    }
}

export type setLoadingCreateTeamACType = ReturnType<typeof setLoadingCreateTeamAC>;

export type setInfoMadaWindowCreateTeamACType = ReturnType<typeof setInfoMadaWindowCreateTeamAC>;
export type setInfoCreateTeamForNewCreationACType = ReturnType<typeof setInfoCreateTeamForNewCreationAC>;
export type setInfoCreateTeamContinueACType = ReturnType<typeof setInfoCreateTeamContinueAC>;

export type setDepartmentIdTeamCreateACType = ReturnType<typeof setDepartmentIdTeamCreateAC>;
export type setTeamNameTeamCreateACType = ReturnType<typeof setTeamNameTeamCreateAC>;
export type setEmailsTeamNameTeamCreateACType = ReturnType<typeof setEmailsTeamNameTeamCreateAC>;
export type setPerformingScoreIdTeamCreateACType = ReturnType<typeof setPerformingScoreIdTeamCreateAC>;
export type setCheckinDayTeamCreateACType = ReturnType<typeof setCheckinDayTeamCreateAC>;
export type setFrequencyIdTeamCreateACType = ReturnType<typeof setFrequencyIdTeamCreateAC>;
export type setTimeTeamCreateACType = ReturnType<typeof setTimeTeamCreateAC>;
export type changeWorkingDaysTeamCreateACType = ReturnType<typeof changeWorkingDaysTeamCreateAC>;
export type setDepartmentNameTeamCreateACType = ReturnType<typeof setDepartmentNameTeamCreateAC>;


export const setLoadingCreateTeamAC = (loading: boolean) => ({type: 'CREATE-TEAM-SET-LOADING', loading}as const);

export const setInfoMadaWindowCreateTeamAC = (info: ModalWindowInfoType) => ({type: 'CREATE-TEAM-SET-MODAL-WINDOW-INFO', info}as const);
export const setInfoCreateTeamForNewCreationAC = (info: CreateTeamPageType) => ({type: 'CREATE-TEAM-SET-INFO-FOR-NEW-CREATION', info}as const);
export const setInfoCreateTeamContinueAC = (info: CreateTeamPageType) => ({type: 'CREATE-TEAM-SET-INFO-CONTINUE', info}as const)

export const setDepartmentIdTeamCreateAC = (id: number) => ({type: 'CREATE-TEAM-SET-DEPARTMENT-ID', id}as const);
export const setTeamNameTeamCreateAC = (name: string) => ({type: 'CREATE-TEAM-SET-TEAM-NAME', name}as const);
export const setDepartmentNameTeamCreateAC = (name: string) => ({type: 'CREATE-TEAM-SET-DEPARTMENT-NAME', name}as const);
export const setEmailsTeamNameTeamCreateAC = (emails: Array<{id: string, value: string}>) => ({type: 'CREATE-TEAM-SET-EMAILS', emails}as const);
export const setPerformingScoreIdTeamCreateAC = (id: number) => ({type: 'CREATE-TEAM-SET-PERFORMING-ID', id}as const);
export const setCheckinDayTeamCreateAC = (day: number | null) => ({type: 'CREATE-TEAM-SET-CHECKIN-DAY', day}as const);
export const setFrequencyIdTeamCreateAC = (frequency: number) => ({type: 'CREATE-TEAM-SET-FREQUENCY-ID', frequency}as const);
export const setTimeTeamCreateAC = (time: string) => ({type: 'CREATE-TEAM-SET-TIME', time}as const);
export const changeWorkingDaysTeamCreateAC = (days: Array<boolean> | []) => ({type: 'CREATE-TEAM-CHANGE-WORKING-DAYS', days}as const);


export const getInfoModalWindowCreateTeamTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCreateTeamAC(true));
    createTeamApi.getInfoForModalWindow().then(res => {
        dispatch(setLoadingCreateTeamAC(false));
        dispatch(setInfoMadaWindowCreateTeamAC(res.data));
    }).catch(err => {
        dispatch(setLoadingCreateTeamAC(false));
        alert('wrong getting  info on modal window create team');
    })
}

export const getInfoCreateTeamTC = (id: number | ''): AppThunk => async (dispatch, getState: () => RootStateType) => {

    dispatch(setLoadingCreateTeamAC(true));
    createTeamApi.getInfoCreateTeam(id).then(res => {
        let info = res.data;
        info.departments.unshift({id: 0, name: 'Other'});
        let newInvites = info.team.invites ? info.team.invites.map((i: string) => ({id: v1(), value: i})) : [{id: v1(), value: ''}];
        info.team.invites = newInvites;

        dispatch(setLoadingCreateTeamAC(false));

        // id.length ? dispatch(setInfoCreateTeamContinueAC(res.data)) : dispatch(setInfoCreateTeamForNewCreationAC(res.data))
        dispatch(setInfoCreateTeamContinueAC(info))
    }).catch(err => {
        dispatch(setLoadingCreateTeamAC(false));
        alert('wrong getting  info  create team');
    })
}

export const updateInfoCreateTeamTC = (id: number, info: {field: string, value: any}): AppThunk => async (dispatch, getState: () => RootStateType) => {

    createTeamApi.updateInfo(id,info).then(res => {

    }).catch(err => {
        alert('wrong updating  info  create team');
    })
}

export const createTeamTC = (id: number, setError: (notification: string) => void, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let allRoles = getState().common.roles;
    createTeamApi.createTeam(id).then(res => {
       // window.location.href = '/dashboard';
        if(!allRoles.filter(i => i.name === 'isAdmin')[0]) {
            localStorage.setItem('role', 'isAdmin');
            dispatch(setCurrentStatusAC('isAdmin'));
            dispatch(addRoleAC({name: 'isAdmin', value: true}));
        }
        navigate('/dashboard')
    }).catch(error => {
        let status = error.response.data.status;
        if (status === 500 || status === 404) {
           // window.location.href = '/404';
            navigate('/404')
        }
        if (status === 422 || status === 403 || (status > 400 && status !== 404)) {
            setError(error.response.data.message)
        }
    })
}