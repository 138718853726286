import React, {useEffect} from "react";
import style from './inviteMemberLeaderModalWindow.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";
import cancel from "../../../../../assets/images/teamManagement/cancel.png";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {v1} from "uuid";
import ButtonWithImage from "../../../../parts/buttonWithImage/buttonWithImage";
import save from "../../../../../assets/images/common/save.png";
import {
    addTeamMemberDepartmentManagementTC,
    CertainTeamMemberType, DepartmentManagementTeamType, inviteTeamLeaderDepartmentManagementTC
} from "../../../../../redux/reducers/departmentManagementReducer";
import {negativeId} from "../../../../../common";
import {useHookErrors} from "../../../../../redux/hookNavigate";
import MessageBox from "../../../../parts/messageBox/messageBox";

type InviteMemberLeaderModalWindowPropsType = {
    onChangeMemberModalWindowMode: (mode: boolean) => void
}

type FormikErrorType = {
    firstName?: string,
    lastName?: string,
    email?: string,
}

const InviteMemberLeaderModalWindow = (props: InviteMemberLeaderModalWindowPropsType) => {
    const {notification, isError, isSuccess, hideNotification, setError, setSuccess} = useHookErrors();
    const dispatch = useDispatch();
    let state = useSelector((state: RootStateType) => state.departmentManagement);
    let leader = state.leader;
    let chosenTeamId = state.chosenTeam?.id;
    let departmentId = state.departmentInfo.id;

    const closeMW = () => {
        props.onChangeMemberModalWindowMode(false);
    }

    const addNewMember = (info: {
        firstName: string,
        lastName: string,
        email: string
    }, member: CertainTeamMemberType) => {
        dispatch(addTeamMemberDepartmentManagementTC(chosenTeamId, info, member, closeMW, setError));
    }

    const inviteTeamLeader = (info: {
        firstName: string,
        lastName: string,
        email: string
    }, teamLeader: DepartmentManagementTeamType) => {
        dispatch(inviteTeamLeaderDepartmentManagementTC(departmentId, info, teamLeader, closeMW, setError));
    }

    useEffect(() => {
        document.body.classList.add('overflow-hidden')

        return (() => {
            document.body.classList.remove('overflow-hidden');
        })
    }, [])

    return (
        <div className={style.member_modal_wrapper}>
            <div className={style.member_modal_window} style={{position: 'relative'}}>
                {(isError || isSuccess) &&
                    <div style={{width: '50%',  position: 'absolute', top: 0, left: 0, zIndex: 114}}>
                        <MessageBox hideError={hideNotification}
                                    isSuccess={isSuccess}
                                    isError={isError}
                                    notification={notification}
                                    autoClosing={true}/></div>
                }

                <div className={style.member_modal_window_header}>
                    {leader == 'member' ? <p>Add Member</p> : <p>Invite Team Leader</p>}
                    <div className={style.cancel_button} onClick={() => props.onChangeMemberModalWindowMode(false)}>
                        <img src={cancel} alt=""/>
                    </div>
                </div>

                <div className={style.member_modal_window_separator}></div>

                <div className={style.member_modal_window_body}>
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                        }}

                        validate={(values) => {
                            let errors: FormikErrorType = {};

                            if (!values.firstName) {
                                errors.firstName = 'First name is required!';
                            } else if (!/^[a-zA-Z][a-zA-Z-]*[a-zA-Z]$/.test(values.firstName)) {
                                errors.firstName = 'Invalid first name format';
                            }
                            if (!values.lastName) {
                                errors.lastName = 'Last name is required!';
                            } else if (!/^[a-zA-Z][a-zA-Z-]*[a-zA-Z]$/.test(values.lastName)) {
                                errors.lastName = 'Invalid last name format';
                            }
                            if (!values.email) {
                                errors.email = 'Email is required!';
                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                errors.email = 'Invalid email address!';
                            }
                            return errors;
                        }}
                        onSubmit={(values) => {
                            let info = {
                                ...values
                            }

                            let member = {
                                id: v1(),
                                email: values.email,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                onboarding: false,
                                status: false
                            };

                            let teamLeader = {
                                id: +negativeId(),
                                email: values.email,
                                leader: values.firstName + values.lastName,
                                secondaryLeaderName: null,
                                membersCount: 0,
                                name: `N/A`,
                                onboardingCompleted: false,
                                status: false
                            };

                            leader === 'member' ? addNewMember(info, member) : inviteTeamLeader(info, teamLeader);
                          //  props.onChangeMemberModalWindowMode(false);
                        }}
                    >
                        <Form>
                            <div className={style.input_text_wrapper}>
                                <label htmlFor="email">Email</label>
                                <Field id="email" name="email" type="text" placeholder='Enter email'/>
                            </div>
                            <ErrorMessage name="email" component='div' className={style.error_message}/>

                            <div className={style.input_text_wrapper}>
                                <label htmlFor="firstName">First Name</label>
                                <Field id="firstName" name="firstName" type="text" placeholder='Enter first name'/>
                            </div>
                            <ErrorMessage name="firstName" component='div' className={style.error_message}/>

                            <div className={style.input_text_wrapper}>
                                <label htmlFor="lastName">Last Name</label>
                                <Field id="lastName" name="lastName" type="text" placeholder='Enter last name'/>
                            </div>
                            <ErrorMessage name="lastName" component='div' className={style.error_message}/>

                            <div className={style.button_wrapper}>
                                <ButtonWithImage
                                    height={46}
                                    name={leader === 'member' ? `Save and add Member` : 'Send invite'}
                                    type={'submit'}
                                    margin={'0 auto'}
                                    img={save}></ButtonWithImage>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default InviteMemberLeaderModalWindow;