import React from "react";
import style from './buttonWithoutImage.module.scss';

type ButtonWithoutImagePropsType = {
    name: string,
    width?: number,
    marginBottom?: number,
    marginLeft?: number,
    disabled?: boolean,
    borderRadius?: number,
    boxShadow?: string,
    onClickHandler?: () => void,
    padding?: string,
    type?: "button" | "submit" | "reset",
    opacity?: string
}

const ButtonWithoutImage = (props: ButtonWithoutImagePropsType) => {

    return (
        <button className={`${style.button_without_image} ${props.disabled ? style.disabled_button : ''}`}
                disabled={props.disabled}
                style={{
                    width: `${!props.width ? '100%' : `${props.width}rem`}`,
                    marginBottom: `${props.marginBottom ? props.marginBottom : 0}rem`,
                    marginLeft: `${props.marginLeft ? props.marginLeft : ''}rem`,
                    borderRadius: `${props.borderRadius ? props.borderRadius : ''}px`,
                    boxShadow: props.boxShadow ? props.boxShadow : '',
                    padding: props.padding ? props.padding : '',
                    opacity: props.opacity ? props.opacity : ''
                }}
                onClick={props.onClickHandler}
                type={props.type}>
            {props.name}
        </button>
    )
}

export default ButtonWithoutImage;