import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {faqAPI} from "../../api/api";

export type QuestionFaqType = {
    answer: string,
    disliked: boolean,
    dislikesCount: string,
    id: number,
    liked: boolean,
    likesCount: string,
    question: string,
    section: string
}

export type FaqStateType = {
    loading: boolean,
    preparedQuestions: Array<{ name: string, listQuestions: Array<QuestionFaqType> }>,
    links: Array<{ url: string, title: string }>
}

const initialState: FaqStateType = {
    loading: false,
    preparedQuestions: [],
    links: []
}

export const faqReducer = (state = initialState, action: AllActionType): FaqStateType => {
    switch (action.type) {
        case 'FAQ-SET-LOADING': {
            return {...state, loading: action.loading}
        }
        case 'FAQ-SET-PREPARED-QUESTIONS': {
            return {...state, preparedQuestions: [...action.questions]}
        }
        case 'FAQ-LIKE-QUESTION': {
            return {
                ...state,
                preparedQuestions: state.preparedQuestions.map(section => section.name === action.question.section
                    ? {
                        ...section, listQuestions: section.listQuestions.map(q => q.id === action.question.id ? {
                            ...q,
                            likesCount: action.likesCount,
                            liked: true,
                            disliked: false,
                            dislikesCount: q.disliked ? (+q.dislikesCount - 1).toString() : q.dislikesCount
                        } : q)
                    } : section)
            }
        }
        case 'FAQ-DISLIKE-QUESTION': {
            return {
                ...state,
                preparedQuestions: state.preparedQuestions.map(section => section.name === action.question.section
                    ? {
                        ...section, listQuestions: section.listQuestions.map(q => q.id === action.question.id ? {
                            ...q,
                            dislikesCount: action.dislikesCount,
                            disliked: true,
                            liked: false,
                            likesCount: q.liked ? (+q.likesCount - 1).toString() : q.likesCount
                        } : q)
                    } : section)
            }
        }
        case 'FAQ-SET-LINKS': {
            return {...state, links: [...action.links]}
        }
        default:
            return state
    }
}

export type setLoadingFaqACType = ReturnType<typeof setLoadingFaqAC>;
export type setPreparedQuestionsFaqACType = ReturnType<typeof setPreparedQuestionsFaqAC>;
export type likeQuestionFaqACType = ReturnType<typeof likeQuestionFaqAC>;
export type dislikeQuestionFaqACType = ReturnType<typeof dislikeQuestionFaqAC>;
export type setLinksFaqACType = ReturnType<typeof setLinksFaqAC>;

export const setLoadingFaqAC = (loading: boolean) => ({type: 'FAQ-SET-LOADING', loading} as const);
export const setPreparedQuestionsFaqAC = (questions: Array<{
    name: string,
    listQuestions: Array<QuestionFaqType>
}>) => ({type: 'FAQ-SET-PREPARED-QUESTIONS', questions} as const);
export const likeQuestionFaqAC = (question: QuestionFaqType, likesCount: string) => ({
    type: 'FAQ-LIKE-QUESTION',
    question,
    likesCount
} as const);
export const dislikeQuestionFaqAC = (question: QuestionFaqType, dislikesCount: string) => ({
    type: 'FAQ-DISLIKE-QUESTION',
    question,
    dislikesCount
} as const);
export const setLinksFaqAC = (links: Array<{ url: string, title: string }>) => ({
    type: 'FAQ-SET-LINKS',
    links
} as const);

export const getQuestionsListFaqTC = (word: string, section: any): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingFaqAC(true));
    faqAPI.getQuestionsList(word, section).then(res => {

        let preparedList: Array<any> = [];

        res.data.map((item: QuestionFaqType) => {
            let idx = preparedList.findIndex((sectionObj) => sectionObj.name === item.section);
            if (idx >= 0 && preparedList[idx].listQuestions) {
                if (!preparedList[idx] || !preparedList[idx].listQuestions) {
                    preparedList[idx]['listQuestions'] = [];
                }
                ;

                preparedList[idx].listQuestions.push(item);
            } else {
                preparedList.push({
                    name: item.section,
                    listQuestions: [item]
                })
            }
        });

        preparedList = preparedList.filter((item) => {
            return item.listQuestions.length
        });

        dispatch(setLoadingFaqAC(false));
        dispatch(setPreparedQuestionsFaqAC(preparedList));
    }).catch(error => {
        dispatch(setLoadingFaqAC(false));
        alert('something wrong gettin questions list on main FAQ page')
    })
}

export const likeQuestionFaqTC = (question: QuestionFaqType): AppThunk => async (dispatch, getState: () => RootStateType) => {
    faqAPI.likeQuestion(question.id).then(res => {
        dispatch(likeQuestionFaqAC(question, res.data.number))
    }).catch(err => {
        alert('something wrong when like question on faq')
    })
}

export const dislikeQuestionFaqTC = (question: QuestionFaqType): AppThunk => async (dispatch, getState: () => RootStateType) => {
    faqAPI.dislikeQuestion(question.id).then(res => {
        dispatch(dislikeQuestionFaqAC(question, res.data.number))
    }).catch(err => {
        alert('something wrong when dislike question on faq')
    })
}