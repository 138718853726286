import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {feedbackAPI} from "../../api/api";

export type FeedbackTypesType = {
    createdAt: string,
    id: number,
    text: string,
    updatedAt: string,
}

export type FeedbackStateType = {
    feedbackTypes: Array<FeedbackTypesType> | null,
    chosenFeedbackType: { value: number, label: string } | null,
    descriptionText: string,
    images: Array<{ url: string, fileName: string }>,
    rate: number | null,
    score: { value: number, label: string } | null,
}

const initialState: FeedbackStateType = {
    feedbackTypes: null,
    chosenFeedbackType: null,
    descriptionText: '',
    images: [],
    rate: null,
    score: null,
}

export const feedbackReducer = (state = initialState, action: AllActionType): FeedbackStateType => {
    switch (action.type) {
        case 'FEEDBACK-GET-FEEDBACK-TYPES': {
            return {...state, feedbackTypes: [...action.feedbackTypes]}
        }
        case 'FEEDBACK-SET-CHOSEN-FEEDBACK-TYPE': {
            return {...state, chosenFeedbackType: action.chosenFeedbackType}
        }
        case 'FEEDBACK-SET-DESCRIPTION-TEXT': {
            return {...state, descriptionText: action.text}
        }
        case 'FEEDBACK-SET-SCREENSHOT': {
            return {...state, images: [...state.images, action.body]}
        }
        case 'FEEDBACK-DELETE-SCREENSHOT': {
            return {...state, images: state.images.filter(i => i.url !== action.url)}
        }
        case 'FEEDBACK-SET-RATE': {
            return {...state, rate: action.rate}
        }
        case 'FEEDBACK-SET-SCORE': {
            return {...state, score: {...action.score}}
        }
        case 'FEEDBACK-CLEAR-STATE': {
            return {...state, score: null, rate: null, images: [], descriptionText: '', chosenFeedbackType: null}
        }

        default:
            return state;
    }
}

export type getFeedbackTypesFeedbackACType = ReturnType<typeof getFeedbackTypesFeedbackAC>;
export type setChosenFeedbackTypeFeedbackACType = ReturnType<typeof setChosenFeedbackTypeFeedbackAC>;
export type setDescriptionTextFeedbackACType = ReturnType<typeof setDescriptionTextFeedbackAC>;
export type setScreenShootFeedbackACType = ReturnType<typeof setScreenShootFeedbackAC>;
export type deleteScreenShootFeedbackACType = ReturnType<typeof deleteScreenShootFeedbackAC>;
export type setRateFeedbackACType = ReturnType<typeof setRateFeedbackAC>;
export type setScoreFeedbackACType = ReturnType<typeof setScoreFeedbackAC>;
export type clearFeedbackStateACType = ReturnType<typeof clearFeedbackStateAC>;

export const getFeedbackTypesFeedbackAC = (feedbackTypes: Array<FeedbackTypesType>) => ({
    type: 'FEEDBACK-GET-FEEDBACK-TYPES',
    feedbackTypes
} as const);
export const setChosenFeedbackTypeFeedbackAC = (chosenFeedbackType: any) => ({
    type: 'FEEDBACK-SET-CHOSEN-FEEDBACK-TYPE',
    chosenFeedbackType
} as const);
export const setDescriptionTextFeedbackAC = (text: string) => ({type: 'FEEDBACK-SET-DESCRIPTION-TEXT', text} as const);
export const setScreenShootFeedbackAC = (body: { url: string, fileName: string }) => ({
    type: 'FEEDBACK-SET-SCREENSHOT',
    body
} as const);
export const deleteScreenShootFeedbackAC = (url: string) => ({type: 'FEEDBACK-DELETE-SCREENSHOT', url} as const);
export const setRateFeedbackAC = (rate: number) => ({type: 'FEEDBACK-SET-RATE', rate} as const);
export const setScoreFeedbackAC = (score: { value: number, label: string }) => ({
    type: 'FEEDBACK-SET-SCORE',
    score
} as const);
export const clearFeedbackStateAC = () => ({type: 'FEEDBACK-CLEAR-STATE'} as const);

export const getFeedbackTypesFeedbackTC = (setLoadingMode: (mode: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    setLoadingMode(true);
    feedbackAPI.getFeedbackTypes().then(res => {
        dispatch(getFeedbackTypesFeedbackAC(res.data));
        setLoadingMode(false);
    }).catch(err => {
        setLoadingMode(false);
        alert('something wrong getting feedback types')
    })
}

export const saveScreenShootFeedbackTC = (photoFile: File, setLoadingMode: (mode: boolean) => void,): AppThunk => async (dispatch) => {
    setLoadingMode(true);
    feedbackAPI.saveScreenShoot(photoFile).then(res => {
        dispatch(setScreenShootFeedbackAC({url: res.data.url, fileName: res.data.fileName}))
        setLoadingMode(false);
    }).catch(error => {
        setLoadingMode(false);
        alert('something wrong saving image');
    })
}

export const deleteScreenShootFeedbackTC = (obj: {
    url: string,
    fileName: string
}, setLoadingMode: (mode: boolean) => void,): AppThunk => async (dispatch) => {
    setLoadingMode(true);
    feedbackAPI.deleteScreenShoot(obj.fileName).then((res: any) => {
        dispatch(deleteScreenShootFeedbackAC(obj.url));
        setLoadingMode(false);
    }).catch((error: any) => {
        setLoadingMode(false);
        alert('image couldn\'t be removed');
    })
}

export const sendFeedbackTC = (answer: {
                                   text: string,
                                   rate: number,
                                   estimateRecommendation: number,
                                   image: Array<string>,
                                   feedbackTypeId: number
                               },
                               closeFeedbackWindow: () => void,
                               setError: (mode: boolean) => void,
                               setNotificationText: (text: string) => void,
                               setSuccessMode: (mode: boolean) => void): AppThunk => async (dispatch) => {
    feedbackAPI.sendFeedback(answer).then((res: any) => {
        closeFeedbackWindow();
        setError(false);
        setSuccessMode(true);
        setNotificationText('Your feedback has been sent!');
        dispatch(clearFeedbackStateAC());
    }).catch((error: any) => {
        alert('wrong sending feedback')
    })
}

export const sendSuggestionTC = (text: string,
                                 cancelSuggestion: () => void,
                                 setNotificationText: (text: string) => void,
                                 setSuccessMode: (mode: boolean) => void): AppThunk => async (dispatch) => {
    feedbackAPI.sendSuggestion(text).then((res: any) => {
        cancelSuggestion();
        setNotificationText('Your suggestion has been sent');
        setSuccessMode(true);
    }).catch((error: any) => {
        alert('wrong sending suggestion')
    })
}