import React, {useCallback, useEffect} from "react";
import style from './header.module.scss';
import InsertedImageRound from "../insertedImageRound/insertedImageRound";
import SwitchAccount from "../switchAccount/switchAccount";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import {getUserInfo, setAccountModeAC, setNotificationsModeAC,} from "../../../redux/reducers/commonReducer";
import {useLocation} from "react-router-dom";
import menu from './../../../assets/images/common/menu.png';
import alarm from './../../../assets/images/header/alarm_new.png';
import SwitchNotifications from "../switchNotifications/switchNotifications";

type HeaderPropsType = {
    pageName: string
}

const Header = React.memo((props: HeaderPropsType) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateType) => state.common);
    const allNotifications = useSelector((state: RootStateType) => state.notificationsMenu.allNotifications);
    const unreadNotifications = allNotifications?.filter(n => !n.seen)?.length;
    const notificationsButtonColor =  allNotifications?.some((n: any) => !n.seen) ? 'red' : 'white';
    const accountMode = state.accountMode;
    const notificationsMode = state.notificationsMode;
    const shortName = state.userInfo.shortName;
    const showSidebar = state.showSidebar;
    const role = state.currentStatus;
    const tourCompleted = state.tourCompleted;


    let location = useLocation();
    let url = location.pathname;

    return (
        <div className={`${style.header} header`} style={{justifyContent: (url == 'questionnaire/checkins') ? '' : 'space-between'}}>

            <div className={'menu_button'} style={{marginLeft: `${showSidebar ? '30px' : '0'}`}}>
                <img src={menu} alt=""/>
            </div>
            <h1>{props.pageName}</h1>

            {url !== '/teamManagement/dashboard' &&
            <div className={`${style.header_buttons}`}>
                    <div className={`${style.header_buttons_wrapper} ${(role === 'isModerator' && !tourCompleted) ? 'edit_profile_icon_for_moderator' : ''}
                ${(role === 'isDepartmentAdmin' && !tourCompleted) ? 'edit_profile_icon_for_DA' : ''}
                ${(role === 'isAdmin' && !tourCompleted) ? 'edit_profile_icon_for_leader' : ''}
                ${(role === 'isMember' && !tourCompleted) ? 'edit_profile_icon_for_member' : ''}`}>
                        {/*пока закомментила кнопку для нотификаций*/}
                        <div className='notifications_button'>
                            {unreadNotifications ? <div className='notification_amount_wrapper'>{unreadNotifications}</div> : null}
                            <InsertedImageRound width={2.8125} height={2.8125} text={shortName} color={notificationsButtonColor}
                                               image={alarm} active={true}/>
                        </div>
                        <div className='accounts_button'>
                            <InsertedImageRound width={2.8125} height={2.8125} text={shortName} color={'yellow'} active={true}/></div>
                    </div>
                    <div
                        className={style.role}>{role === 'isAdmin' ? 'Team Leader' : role === 'isModerator' ? 'Administrator' : role === 'isDepartmentAdmin' ? 'Department Leader' : 'Member'}</div>

            </div>}

            {accountMode && <SwitchAccount/>}
            {notificationsMode && <SwitchNotifications/>}

        </div>
    )
})


export default Header;
