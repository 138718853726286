import React, {SyntheticEvent, useState} from "react";
import style from './../companyChart.module.scss';
import add_role from './../../../../../assets/images/companyManagement/addLeader_black.png';
import {useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";
import NodeItem from "./nodeItem/nodeItem";
import ArrowLabel from "./arrowLabel/arrowLabel";
import {useDrag} from "react-dnd";

type CustomNodeLabelPropsType = {
    nodeData: any,
    toggleNode: any,
    foreignProps: any,
}
const CustomNodeLabel = ({nodeData, foreignProps, toggleNode}: CustomNodeLabelPropsType) => {
    let isCollapsed = nodeData.__rd3t.collapsed;

    // const [{ isDragging }, drag] = useDrag(() => ({
    //     type: 'NODE',
    //     item: { id: nodeData.__rd3t.id },
    //     end: (item, monitor) => {
    //         debugger
    //         const dropResult = monitor.getDropResult()
    //         if (item && dropResult) {
    //             debugger
    //             alert(`You dropped ${item?.id} into!`)
    //         }
    //     },
    //     collect: (monitor) => ({
    //         isDragging: !!monitor.isDragging(),
    //     }),
    // }));

    const onNodeClick = (evt: SyntheticEvent) => {
        toggleNode();
    }


    return (
        <foreignObject {...foreignProps} onClick={onNodeClick}
                       style={{marginTop: nodeData?.attributes?.level === 4 ? '40px' : '0'}}
            // ref={drag}
        >
            {nodeData.__rd3t.depth === 0 ? <div className={style.root_node}>{nodeData.name}</div> :
                <div className={style.custom_node_wrapper}>
                    {nodeData.attributes.text ? <div className={style.null_dep_node}>{nodeData.attributes.text}</div>
                        :
                        <>
                            <NodeItem nodeData={nodeData}/>
                            {nodeData.attributes?.level === 3 && nodeData.attributes?.secondaryLeader ?
                                <>
                                    <div
                                        style={{
                                            width: '2px',
                                            height: '15px',
                                            backgroundColor: 'grey',
                                            margin: '0 auto'
                                        }}></div>
                                    <NodeItem nodeData={nodeData} isSecondary={true}/>
                                </> : null
                            }
                        </>
                    }
                    {nodeData?.children?.length && isCollapsed ?
                        <ArrowLabel childrenCount={nodeData.children.length}/> : null}
                </div>
            }

        </foreignObject>
    )
}

export default CustomNodeLabel;