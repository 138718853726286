import React from "react";
import style from "./insertedImageRound.module.scss";

export type InsertedImageRoundPropsType = {
    width: number,
    height: number,
    image?: string,
    text?: string,
    active?: boolean,
    color?: string,
    handler?: () => void,
    bold?: boolean,
    background?: string,
    cursor?: string
}

const InsertedImageRound = React.memo((props: InsertedImageRoundPropsType) => {

    const clickButton = (e: any) => {
        props.handler && props.handler();
    }

    return (
        <div
            className={`${style.inserted_image_round} ${props.active && props.color === 'blue' ? style.active_blue : props.active && props.color === 'red' ? style.active_red :
                props.active && props.color === 'yellow' ? style.active_yellow : ''}`}
            style={{
                width: `${props.width}rem`, height: `${props.height}rem`, backgroundColor: props.background,
                cursor: props.cursor
            }}
            onClick={clickButton}>
            {
                props.image ?
                    <img src={`${props.image}`} alt=""/>
                    :
                    <div>{props.text}</div>
            }
        </div>
    )
})

export default InsertedImageRound;