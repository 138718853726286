import axios, {AxiosResponse} from "axios";
import {CompanyStepAnswerType} from "../components/pages/onboaring/stepCompany/stepCompany";
import {StepYoyAnswerType} from "../components/pages/onboaring/stepYou/stepYou";
import {StepTeamAnswerType} from "../components/pages/onboaring/stepTeam/stepTeam";
import {SendPulseDataType} from "../components/pages/digitalPulse/build/build";
import {DataForUpdateType, FrequencyDayType} from "../redux/reducers/digitalPulseReducer";
import {QuestionnaireAnswerType} from "../components/pages/digitalPulse/questionnaire/questionnaire";
import {UpdateInfoType} from "../redux/reducers/profileReducer";
import {StepDepartmentAnswerType} from "../components/pages/onboaring/stepDepartment/stepDepartment";

let instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

export const getHeader = () => {
    let role = localStorage.getItem('role');
    let token = localStorage.getItem("token");

    if (!token) {
        window.location.href = '/signIn'
    } else {
        return {
            headers: {
                // Authorization: `Bearer ${token}`,
                'role': `${role}`
            }
        };
    }
};

export const getPreviewHeader = () => {
    let role = localStorage.getItem('role');
    let token = localStorage.getItem("previewToken");

    if (!token) {
        window.location.href = '/signIn'
    } else {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                'role': `${role}`
            }
        };
    }
};

export const checkinReport = {
    getRoles() {
        return instance.get(`/utilities/role/`, getHeader());
    },
    setTourCompleted() {
        return instance.post(`/tour/complete`, {}, getHeader())
    }
}

export const userAPI = {
    getData() {
        return instance.get("/profile", getHeader());
    }
}

export const authAPI = {
    //если нет токена, то это на sign up кнопку регистрация
    signup(info: {
        email: string,
        companyName: string,
        firstName: string,
        lastName: string,
        phone: string,
        timeZone: string
    }) {
        return instance.post(`/auth/register`, info)

    },
    //если токен есть, то это на sign up кнопку регистрация
    signupWithToken(info: {
        email: string,
        companyName: string,
        firstName: string,
        lastName: string,
        phone: string,
        timeZone: string,
        token: string
    }) {
        return instance.post(`auth/checkInvite/register`, info)
    },
    checkToken(token: string) {
        return instance.post(`/auth/confirm/token`, {token: token})
    },
    setPassword(info: { token?: string, password: string }) {
        return instance.post(`/auth/confirm/setPassword`, info)
    },
    checkRecaptcha(response: any) {
        return instance.post('auth/verify', {response})
    },
    //по нажатию на кнопк log in
    authLogin(info: { email: string, password: string }) {
        return instance.post(`/auth/login`, info);
    },
    authLoginData(info: { token: string }) {
        return instance.post(`/auth/loginData`, info);
    },
    //проверка токена при загрузке signIn
    authLoginInviteCheck(token: string) {
        return instance.get(`/invite/${token}/check`);
    },
    //дополнитльный зпрос который отправляется по нажатию на кнопку login если урл с токеном
    authLoginInviteJoin(token: string) {
        return instance.post(`/invite/${token}/join`, {}, getHeader())
    },
    authResetPassword(info: { email: string }) {
        return instance.post(`/auth/reset`, info);
    },
    authValidateResetToken(info: { token: string }) {
        return instance.post(`/auth/reset/token`, info);
    },
    authUpdatePassword(info: { token: string, password: string }) {
        return instance.post(`/auth/reset/updatePassword`, info)
    },
    reconfirmEmail(email: string) {
        return instance.post(`/auth/confirm/reconfirm`, {email: email})
    },
    authCheckInvite(token: string) {
        return instance.post(`/auth/checkInvite`, {token: token})
    },
    //for new authentication
    getUserAccountData(email: string) {
        return instance.post(`/auth/prepare`, {email})
    },
    getAccountsWithSessions(accounts: Array<{name: string, email: string}>) {
        return instance.post(`/auth/getSessions`, {accounts})
    },
    logout() {
        return instance.get('/auth/logout');
    }
}

export const onboardingAPI = {
    getRoles() {
        return instance.get('/onboarding/roles', getHeader());
    },
    getInfoStepYou() {
        return instance.get('/onboarding/me', getHeader());
    },
    sendAnswerYouStep(answer: StepYoyAnswerType) {
        return instance.post('/onboarding/me', answer, getHeader())
    },
    getInfoStepCompany() {
        return instance.get('/onboarding/company', getHeader());
    },
    sendAnswerCompanyStep(answer: CompanyStepAnswerType<string>) {
        return instance.post('/onboarding/company', answer, getHeader())
    },
    getInfoStepDepartment() {
        return instance.get(`/onboarding/department`, getHeader());
    },
    sendAnswerStepDepartment(answer: StepDepartmentAnswerType) {
        return instance.post('/onboarding/department', answer, getHeader());
    },
    getInfoStepTeam() {
        return instance.get('/onboarding/team', getHeader());
    },
    stepAnswerStepTeam(answer: StepTeamAnswerType<string>) {
        return instance.post('/onboarding/team', answer, getHeader());
    },
    getNorms() {
        return instance.get('/onboarding/norms', getHeader())
    },
    sendNorms(norms: { norms: Array<number> }) {
        return instance.post('/onboarding/norms', norms, getHeader())
    },
    getMotivators() {
        return instance.get('/onboarding/motivators', getHeader())
    },
    sendMotivators(answer: any) {
        return instance.post('/onboarding/motivators', answer, getHeader())
    },
    finishOnboarding() {
        return instance.get('/onboarding/finish', getHeader())
    },
}

export const dashboardAPI = {
    getTourCompletedFlag() {
        return instance.get(`/dashboard/tour`, getHeader());
    },
    companyOverview(period: string, departmentId?: string) {
        return instance.get(`/dashboard/company?period=${period}&departmentId=${departmentId}`, getHeader());
    },
    teamsOverview(period: string, departments: Array<number>) {
        return instance.post(`/dashboard/teams/overview?period=${period}`, departments, getHeader());
    },
    //инфа по конкретной команде
    getTeamInfo(teamId: number | null, period: string, memberId?: string, previewToken?: boolean) {
        return instance.get(`/dashboard/teams/${teamId}?period=${period}&member=${memberId ? memberId : ''}`, previewToken ? getPreviewHeader() : getHeader());
    },
    //все команды а потом из них выбираю первую попавшуюся где он лидер \ модератор и тд
    getAllTeams() {
        return instance.get(`/dashboard/teams`, getHeader());
    },
    getCompanyAnalytics(obj: any) {
        return instance.post(`/dashboard/company/analytics`, obj, getHeader());
    },
    //для pdf отчетов
    getInfoForModalWindowPDFReports() {
        return instance.get(`dashboard/report/data`, getHeader())
    },
    sharePdfReport(info: any) {
        return instance.post(`dashboard/report/export`, info, getHeader())
    }
}

export const coachingCornerAPI = {
    getCoachingCornerInsights(teamId: number | null, offset: number, scroll: number) {
        return instance.get(
            `/coach/${teamId}/insights?offset=${offset}`,
            getHeader()
        );
    },
    getCoachingCornerTrends(teamId: number | null, offset: number, scroll: number) {
        return instance.get(
            `/coach/${teamId}/trends?offset=${offset}`,
            getHeader()
        );
    },
    getAllTeams() {
        return instance.get(`/coach/teams`, getHeader());
    },
    likeItem(itemId: number) {
        return instance.get(`/coach/${itemId}/like`, getHeader());
    },
    dislikeItem(itemId: number) {
        return instance.get(`/coach/${itemId}/dislike`, getHeader());
    },
    getPosts(teamId: number) {
        return instance.get(`/coach/post/${teamId}/get`, getHeader());
    },
    readPost(postId: number) {
        return instance.get(`/coach/post/${postId}/read`, getHeader());
    },
    addNewPost(teamId: number, post: { text: string, anonymously: boolean, isMotivation: boolean }) {
        return instance.post(`/coach/post/${teamId}/create`, post, getHeader())
    }
}

export const companyManagementAPI = {
    getDepartments() {
        return instance.get("/utilities/departaments", getHeader())
    },
    getCertainDepartmentInfo(department: any) {
        return instance.get(`/company?department=${department}`, getHeader())
    },
    getCheckinReportInfo(teamId: number, date: string) {
        return instance.get(`/checkins/${teamId}/?date=${date}`, getHeader())
    },
    getAllTeams() {
        return instance.get(`/dashboard/teams`, getHeader());
    },
    inviteAdmin(companyId: number, info: { firstName?: string, lastName?: string, email?: string }) {
        return instance.post(`/invite/company/${companyId}/create`, info, getHeader())
    },
    deleteAdmin(companyId: number, info: { email: string }) {
        return instance.post(`/company/${companyId}/admin/delete`, info, getHeader())
    },
    deleteAdminInvite(companyId: number, info: { email: string }) {
        return instance.post(`/invite/company/${companyId}/delete`, info, getHeader())
    },
    reinviteAdmin(companyId: number, info: { email: string }) {
        return instance.post(`/invite/company/${companyId}/resend`, info, getHeader())
    },
    nudgeAdmin(companyId: number, info: { email: string }) {
        return instance.post(`/company/${companyId}/admin/nudge`, info, getHeader())
    },
    //с этого места все переписать на department leader
    addDepartmentLeader(companyId: number, info: { firstName: string, lastName: string, email: string }) {
        return instance.post(`/invite/company/${companyId}/department/create`, info, getHeader())
    },
    deleteInviteDepartmentLeader(companyId: number, info: { email: string }) {
        // return instance.post(`/invite/company/${companyId}/department/delete`, info, getHeader())
        return instance.post(`/invite/company/${companyId}/department/delete`, info, getHeader())
    },
    deleteDepartmentLeader(departmentId: string) {
        return instance.post(`/company/department/${departmentId}/delete`, {}, getHeader())
    },
    changeDepartmentLeader(departmentId: string, info: {
        firstName: string,
        lastName: string,
        email: string,
        keep: boolean
    }) {
        return instance.post(`/invite/department/${departmentId}/leader/change/create`, info, getHeader())
    },
    //for team leaders
    deleteInviteTeamLeader(departmentId: number, info: {email: string}) {
        return instance.post(`/invite/department/${departmentId}/delete`, info, getHeader())
    },

    deleteTeamLeader(teamId: number) {
        return instance.post(`/company/team/${teamId}/delete`, {}, getHeader())
    },
    changeTeamLeader(teamId: string, info: { firstName: string, lastName: string, email: string, keep: boolean }) {
        return instance.post(`/invite/team/${teamId}/leader/change/create`, info, getHeader())
    },
    getPossibleDepartmentLeaders() {
        return instance.get(`/dashboard/report/data`, getHeader());
    },
    getPossibleTeamLeaders() {
        return instance.get(`/secondaryLeader/users`, getHeader());
    },
    chooseDepartmentLeader(departmentId: number|string, userId: number | string) {
        return instance.post(`company/department/${departmentId}/leader`,   {userId} , getHeader())
    },
    getCommonCompanyDiagram(period?: string) {
        return instance.get(`/company/diagram?period=${period}`, getHeader())
    },
    shareDownloadOrganizationDiagram(info: any) {
       // debugger
        return instance.post(`/company/diagram/export`, info, getHeader())
    }

}

export const teamManagementAPI = {
    getDepartments() {
        return instance.get("/utilities/departaments", getHeader())
    },
    getAllTeams(previewToken: boolean) {
        return instance.get(`/dashboard/teams`, previewToken ? getPreviewHeader() : getHeader());
    },
    getChosenTeamInfo(teamId: number | null) {
        return instance.get(`/team/${teamId}`, getHeader());
    },
    addTeamMember(teamId: number, info: { firstName: string, lastName: string, email: string }) {
        return instance.post(`/invite/team/${teamId}/create`, info, getHeader());
    },
    removeTeamMemberInvite(teamId: number, info: { email: string }) {
        return instance.post(`/invite/team/${teamId}/delete`, info, getHeader());
    },
    removeTeamMember(teamId: number, info: { email: string }) {
        return instance.post(`/team/${teamId}/member/delete`, info, getHeader());
    },
    reinviteMember(teamId: number, info: { email: string }) {
        return instance.post(`/invite/team/${teamId}/resend`, info, getHeader());
    },
    nudgeMember(teamId: number, info: { email: string }) {
        return instance.post(`/team/${teamId}/member/nudge`, info, getHeader());
    },
    changeTeamName(name: string, teamId: number) {
        return instance.post(`/team/${teamId}/update/name`, {name: name}, getHeader());
    },
    saveNewCheckinInfo(teamId: number, info: {
        checkinDay: number | null,
        workingDays: Array<number>,
        frequencyId: number,
        time: string
    }) {
        return instance.post(`/team/${teamId}/update`, info, getHeader());
    },
    getCheckinReportInfo(teamId: number, date: string) {
        return instance.get(`/checkins/${teamId}/?date=${date}`, getHeader())
    },
    changeTeamLeader(teamId: number, info: { firstName: string, lastName: string, email: string, keep: boolean }) {
        return instance.post(`/invite/team/${teamId}/leader/change/create`, info, getHeader())
    },
    getTokenForDashboard(userId: string) {
        return instance.post(`dashboard/preview/token/create`, {userId}, getHeader())
    },
    removeTokenOnDashboard() {
        return instance.get(`dashboard/preview/token/delete`, getHeader());
    },
    deleteTeam(teamId: number) {
        return instance.post(`company/team/${teamId}/delete`, {teamId}, getHeader())
    }
}

export const digitalPulseAPI = {
    getTime() {
        return instance.get("/pulse/time", getHeader());
    },
    getAllTeams() {
        return instance.get(`/dashboard/teams`, getHeader());
    },
    getDepartments() {
        return instance.get("/utilities/departaments", getHeader())
    },
    getQuestionTypes() {
        return instance.get("/pulse/types", getHeader())
    },
    createDigitalPulse(data: SendPulseDataType) {
        return instance.post("/pulse/create", data, getHeader())
    },
    activatePulse(id: number) {
        return instance.get(`/pulse/${id}/active`, getHeader())
    },
    editActivePulse(id: number, body: {endDate: string, recurringOn: boolean, frequency: string | null, days: Array<boolean>}) {
        return instance.post(`/pulse/${id}/manage`, body, getHeader())
    },
    getPulseList() {
        return instance.get(`/pulse`, getHeader());
    },
    deletePulse(id: number) {
        return instance.get(`/pulse/${id}/delete`, getHeader())
    },
    deactivatePulse(id: number) {
        return instance.get(`/pulse/${id}/disable`, getHeader())
    },
    nudgePulse(id: number) {
        return instance.get(`/pulse/${id}/nudge`, getHeader())
    },
    getFilteredPulseList(date: string, status: string, creator: number | string) {
        return instance.get(`/pulse/?date=${date}&status=${status}&user=${creator}`, getHeader())
    },
    getPulse(id: number) {
        return instance.get(`/pulse/${id}`, getHeader())
    },
    updateDigitalPulse(pulseId: number, data: DataForUpdateType) {
        return instance.post(`/pulse/${pulseId}`, data, getHeader())
    },
    getTeamsForCertainPuls(pulsId: number) {
        return instance.get(`/pulse/${pulsId}/teams`, getHeader())
    },
    //берем определенный пульс для определенной команды
    getCertainPulseForCertainTeam(pulseId: number, teamId: number) {
        return instance.get(`/pulse/results/${pulseId}/${teamId}`, getHeader())
    },
    getPulsesForReadyToUseSurveys() {
        return instance.get('/pulse/default', getHeader());
    },
    //добавила для получения департаментов на ready to use survey
    getDepartmentsForReadyToUseSurvey() {
        return instance.get("/pulse/departments", getHeader())
    },
    createPulseOnReadyToUseSurveys(answer: any) {
        return instance.post(`pulse/${answer.id}/default/create`, answer, getHeader());
    },
    getInfoForQuestionnaire(token: string) {
        return instance.get(`/pulse/${token}/questions`)
    },
    sendPulseAnswers(token: string, answer: QuestionnaireAnswerType) {
        return instance.post(`/pulse/${token}/answers`, answer)
    },
}

export const profileAPI = {
    getProfileInfoEditPage() {
        return instance.get(`/profile`, getHeader())
    },
    removePhoto() {
        return instance.get("/profile/image/remove", getHeader())
    },
    savePhoto(photoFile: File) {
        const formData = new FormData()
        formData.append("image", photoFile)
        const headers = Object.assign(getHeader()?.headers || {}, {'Access-Control-Allow-Origin': '*'})
        return instance.post("/profile/image/set", formData, {headers})
    },
    updateInfo(answer: UpdateInfoType) {
        return instance.post(`/profile`, answer, getHeader());
    },
    getInfoMembership() {
        return instance.get(`/profile/membership`, getHeader())
    },
    changePassword(currentPassword: string, newPassword: string) {
        return instance.post(`/profile/password/update`, {currentPassword, newPassword}, getHeader())
    },
    getInfoAssessmentPage() {
        return instance.get(`/profile/assesment`, getHeader())
    },
    getInfoValuesPage() {
        return instance.get(`/profile/values`, getHeader())
    },
    saveValuesPage(values: Array<number>) {
        return instance.post(`/profile/values`, {norms: values}, getHeader())
    },
    getMotivatorsPage() {
        return instance.get(`/profile/motivators`, getHeader())
    },
    saveMotivatorsPage(ratings: Array<{ id: number }>, priorities: Array<{ id: number }>) {
        return instance.post(`/profile/motivators`, {ratings, priorities}, getHeader())
    },
    getEmailSecurityPage() {
        return instance.get(`/profile/email`, getHeader());
    },
    deleteAccountEditPage() {
        return instance.get(`/profile/block`, getHeader());
    },
}

export const createTeamApi = {
    getInfoForModalWindow() {
        return instance.get(`/teamCreate`, getHeader());
    },
    getInfoCreateTeam(id: number | '') {
        return instance.get(`/teamCreate/team?id=${id}`, getHeader())
    },
    updateInfo(id: number, info: { field: string, value: any }) {
        return instance.post(`/teamCreate/team?id=${id}`, info, getHeader())
    },
    createTeam(id: number) {
        return instance.get(`/teamCreate/team/create?id=${id}`, getHeader())
    }
}

export const teamSelectionForCheckinsAPI = {
    getTeams() {
        return instance.get("/survey/surveys", getHeader())
    }
}

export const createDepartmentApi = {
    getInfoForModalWindow() {
        return instance.get(`/departmentCreate`, getHeader());
    },
    getInfoCreateDepartment(id: number | '') {
        return instance.get(`/departmentCreate/department?id=${id}`, getHeader())
    },
    updateInfo(id: number, info: { field: string, value: any }) {
        return instance.post(`/departmentCreate/department?id=${id}`, info, getHeader())
    },
    createDepartment(id: number) {
        return instance.get(`/departmentCreate/department/create?id=${id}`, getHeader())
    }
}

export const stripeAPI = {
    setup() {
        return instance.get(`/stripe/setup`, getHeader());
    },
    checkPayment() {
        return instance.get(`/stripe/check-payment`, getHeader());
    },
    createSession(priceId: string, companyId: number, quantity: number) {
        return instance.post("/stripe/create-checkout-session", {priceId, companyId, quantity}, getHeader());
    },
    portal(sessionId: string | number, companyId: number) {
        return instance.post("/stripe/customer-portal", {sessionId, companyId}, getHeader());
    },
}

export const assessmentAPI = {
    getQuestions() {
        return instance.get("/assesment", getHeader());
    },
    sendAnswers(answers: { [key: string]: number }, currentStep: number) {
        return instance.post("/assesment", {answers, currentStep}, getHeader());
    },
    getPersonality() {
        return instance.get(`/assesment/personality`, getHeader())
    },
    retakeAssessment() {
        return instance.post(`/assesment/personality/destroy`, {}, getHeader())
    }
}

export const faqAPI = {
    getQuestionsList(word: string, section: any) {
        return instance.post(`/faq`, {word, section}, getHeader())
    },
    likeQuestion(questionId: number) {
        return instance.get(`/faq/like/${questionId}`, getHeader());
    },
    dislikeQuestion(questionId: number) {
        return instance.get(`/faq/dislike/${questionId}`, getHeader());
    }
}

export const checkInParticipationAPI = {
    getReasons() {
        return instance.get(`/utilities/unsubscribe/reasons`)
    },
    sendReason(userId: number, response: any) {
        return instance.post(`/utilities/unsubscribe/reasons/${userId}`, response)
    },
}

export const departmentManagementAPI = {
    getDepartmentInfo(departmentId: number) {
        return instance.get(`/department/${departmentId}`, getHeader())
    },
    getDepartments() {
        return instance.get(`/dashboard/departments`, getHeader())
    },
    getCertainTeamInfo(teamId: number) {
        return instance.get(`/department/team/${teamId}`, getHeader())
    },
    getCheckinReportInfo(teamId: number | string, date: string) {
        return instance.get(`/checkins/${teamId}/?date=${date}`, getHeader())
    },
    changeDepartmentManager(departmentId: number, info: {
        firstName: string,
        lastName: string,
        email: string,
        keep: boolean
    }) {
        return instance.post(`/invite/department/${departmentId}/leader/change/create`, info, getHeader())
    },
    changeTeamLeader(teamId: number, info: { firstName: string, lastName: string, email: string, keep: boolean }) {
        return instance.post(`/invite/team/${teamId}/leader/change/create`, info, getHeader())
    },
    addTeamMember(teamId: number, info: { firstName: string, lastName: string, email: string }) {
        return instance.post(`/invite/team/${teamId}/create`, info, getHeader());
    },
    changeDepartmentName(id: number, name: string) {
        return instance.post(`/department/${id}/update/name`, {name: name}, getHeader())
    },
    deleteDepartment(id: number) {
        return instance.post(`/department/${id}/delete`, {}, getHeader())
    },
    inviteTeamLeader(id: number, info: { firstName: string, lastName: string, email: string }) {
        return instance.post(`/invite/department/${id}/create`, info, getHeader());
    },
    reinviteTeamLeader(id: number, email: string) {
        return instance.post(`/invite/department/${id}/resend`, {email}, getHeader())
    },
    deleteTeamLeader(teamId: number) {
        return instance.post(`/department/team/${teamId}/delete`, {}, getHeader())
    },
    deleteInviteTeamLeader(departmentId: number, email: string) {
        return instance.post(`/invite/department/${departmentId}/delete`, {email}, getHeader())
    },
    nudgeTeam(teamId: number) {
        return instance.get(`/company/team/${teamId}/nudge`, getHeader());
    },
    chooseSecondaryTeamLeader(teamId: number|string, userId: number | string) {
        return instance.post(`/secondaryLeader/team/${teamId}/add`,   {userId} , getHeader())
    },
    removeSecondaryTeamLeader(teamId: number|string, info: {keep: boolean}) {
        return instance.post(`/secondaryLeader/team/${teamId}/remove`,   info , getHeader())
    },
    // changeSecondaryTeamLeader(teamId: number|string, userId: number | string, infoKeep: { keep: boolean }) {
    //     return instance.post(`/secondaryLeader/team/${teamId}/change`,   {userId, ...infoKeep} , getHeader())
    // },
    //изменили 01112023
    changeSecondaryTeamLeader(teamId: number|string, info: { firstName: string, lastName: string, email: string, keep: boolean }) {
        return instance.post(`invite/team/${teamId}/leader/secondary/change/create`,   info, getHeader())
    },
    addNewSecondaryTeamLeader(teamId: number|string, info: {email: string, firstName: string, lastName: string}) {
        return instance.post(`/invite/team/${teamId}/leader/secondary/create`, info, getHeader())
    }
}

export const questionnaireAPI = {
    getQuestions(token: string) {
        return instance.post("/survey/questions", {token});
    },
    sendAnswers(token: string, object: any) {
        return instance.post("/survey/answers", {
            token,
            ...object,
        });
    },
};

export const notificationsAPI = {
    getNotifications() {
        return instance.get("dashboard/notifications", getHeader())
    },
    makeNotificationSeenUnseen(body: { notification: { id: number, seen: boolean } } | {
        notification: Array<number>
    }) {
        return instance.post("dashboard/notifications", body, getHeader())
    },
    addNewPost(teamId: number, post: { text: string, anonymously: boolean, isMotivation: boolean }) {
        return instance.post(`/coach/post/${teamId}/create`, post, getHeader())
    },
    onClickActionButton(url: string, body: { email: string, notification: number }) {
        return instance.post(url, body, getHeader())
    }
}

export const feedbackAPI = {
    getFeedbackTypes() {
        return instance.get(`feedback/feedbackTypes`, getHeader())
    },
    saveScreenShoot(photoFile: File) {
        const formData = new FormData()
        formData.append("image", photoFile)
        const headers = Object.assign(getHeader()?.headers || {}, {'Access-Control-Allow-Origin': '*'})
        return instance.post(`feedback/image/set`, formData, {headers})
    },
    deleteScreenShoot(fileName: string) {
        return instance.post(`feedback/image/remove`, {fileName}, getHeader())
    },
    sendFeedback(answer: {
        text: string,
        rate: number,
        estimateRecommendation: number,
        image: Array<string>,
        feedbackTypeId: number
    }) {
        return instance.post(`feedback`, answer, getHeader())
    },
    sendSuggestion(text: string) {
        return instance.post(`/feedback/suggection`, {text}, getHeader())
    }
}

