import React from "react";
import style from './trendItem.module.scss';
import InsertedImageRound from "../../../parts/insertedImageRound/insertedImageRound";
import like from '../../../../assets/images/coachingCorner/like.png';
import like_fill from '../../../../assets/images/coachingCorner/like_fill.png';
import InsertedImage from "../../../parts/insertedImage/insertedImage";
import likeIcon from '../../../../assets/images/coachingCorner/icw-like.svg';
import clockIcon from '../../../../assets/images/coachingCorner/icw_clock.svg';
import cornerIcon from '../../../../assets/images/coachingCorner/icw_corner2.svg';
import barchartIcon from '../../../../assets/images/coachingCorner/icw_barchart.svg';
import brandIcon from '../../../../assets/images/coachingCorner/icw_brand.png';
import {TrendItemType} from "../Trends/trends";

export type TrendItemPropsType = {
    trend: TrendItemType,
    like: () => void,
    dislike: () => void,
    onClickItem: () => void
}

const TrendItem = (props: TrendItemPropsType) => {
    const colors = {
        yellow: '#FFC20E',
        green: '#6EBC20',
        red: '#db022b',
        white: '#fff'
    };

    let backgroundColor = (props.trend.color === 'very_good' || props.trend.color === 'good') ? `${colors.green}`
        : props.trend.color === 'neutral' ? `${colors.yellow}` : props.trend.color === 'bad' ? `${colors.red}` : `${colors.white}`;

    let image = props.trend.iconClass === "004-bar-chart" ? barchartIcon : props.trend.iconClass === "127-like" ? likeIcon
        : props.trend.iconClass === "icw_clock" ? clockIcon : props.trend.iconClass === "icw_corner2" ? cornerIcon
            : props.trend.iconClass === "brand-icon" ? brandIcon : '';

    return (
        <div className={style.insight_item} style={{opacity: props.trend.seen ? '.5' : '1'}}>
            <InsertedImageRound height={4.1875} width={4.1875} image={image} background={backgroundColor}/>

            <div className={`${style.insight_item_info} border`}>
                <div className={style.insight_item_info_text_block} onClick={props.onClickItem}>

                    <div className={style.insight_item_info_text_block_header}>{props.trend.modalHeader}</div>
                    <div className={style.insight_item_info_text_block_text}>{props.trend.insightBody}</div>
                </div>

                <div className={style.insight_item_info_helpful_block}>Helpful?</div>

                <div className={style.insight_item_info_icons_block}>
                    <div className={style.insight_item_info_icons_block_time}>{props.trend.daysAgo}</div>

                    <div className={style.insight_item_info_icons_block_icons}>
                        <div className={style.insight_item_info_helpful_block_mobile}>Helpful?</div>
                        {props.trend.liked ?
                            <InsertedImage width={1.3125} height={1.3125} image={like_fill} cursor={'pointer'}
                                           handler={props.like}/> :
                            <InsertedImage width={1.3125} height={1.3125} image={like} cursor={'pointer'}
                                           handler={props.like}/>
                        }
                        {props.trend.disliked ?
                            <InsertedImage width={1.3125} height={1.3125} image={like_fill} cursor={'pointer'}
                                           rotate={true}
                                           handler={props.dislike} margin={'0 15px 0 0'}/> :
                            <InsertedImage width={1.3125} height={1.3125} image={like} cursor={'pointer'} rotate={true}
                                           handler={props.dislike} margin={'0 15px 0 0'}/>
                        }
                        <div className={'red_point'} style={{display: props.trend.seen ? 'none' : 'block'}}></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TrendItem;