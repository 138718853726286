import React from "react";
import style from './itemForChoose.module.scss';
import teamImage from './../../../../../assets/images/createTeam/ic_team-blue.svg';

export type ItemForChoosePropsType = {
    name: string,
    backGround?: string
}
const ItemForChoose = (props: ItemForChoosePropsType) => {
    const mouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
        if (props.backGround) {
            e.currentTarget.classList.remove('bg_teams')
            e.currentTarget.classList.add('hovered')
        }
        e.currentTarget.classList.add('hovered')
    }

    const mouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        if (props.backGround) {
            e.currentTarget.classList.add('bg_teams')
            e.currentTarget.classList.remove('hovered')
        }
        e.currentTarget.classList.remove('hovered')
    }

    return (
        <div className={`${style.item} ${props.backGround ? 'bg_teams' : ''}`} onMouseLeave={mouseLeave}
             onMouseOver={mouseOver}>
            <div className={style.item_image}>
                <img src={teamImage} alt=""/>
            </div>
            <div className={style.item_name}>{props.name}</div>
        </div>
    )
}

export default ItemForChoose;