import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {notificationsAPI} from "../../api/api";

export type NotificationType = {
    buttonClicked: boolean | null,
    buttonLink: string | null,
    buttonTitle: string | null,
    color: string | null,
    createdAt: string | null,
    icon: string | null,
    id: number,
    message: string | null,
    props: any,
    role: 'isDepartmentAdmin' | 'isMember' | 'isModerator' | 'isAdmin' | any,
    seen: boolean,
    textArea: boolean,
    textAreaPlaceholder: string | null,
    title: string | null,
    updatedAt: string | null,
    userId: number,
    teamId: number | null,
    textAreaAnonymous: boolean | null
}

export type NotificationsMenuType = {
    allNotifications: Array<NotificationType>,
    adminCount: number,
    departmentLeaderCount: number,
    memberCount: number,
    moderatorCount: number,
}

const initialState: NotificationsMenuType = {
    allNotifications: [],
    adminCount: 0,
    departmentLeaderCount: 0,
    memberCount: 0,
    moderatorCount: 0,
}

export const notificationsMenuReducer = (state = initialState, action: AllActionType): NotificationsMenuType => {
    switch (action.type) {
        case 'GET-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: [...action.allNotifications]}
        }
        case 'READ-ALL-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER': {
            return {
                ...state,
                allNotifications: state.allNotifications.map((notification: NotificationType) => ({
                    ...notification,
                    seen: true
                }))
            }
        }
        case 'READ-CERTAIN-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER': {
            return {
                ...state,
                allNotifications: state.allNotifications.map((notification: NotificationType) => notification.id === action.body.id ? {
                    ...notification,
                    seen: action.body.seen
                } : notification)
            }
        }
        case 'ADD-NEW-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER': {
            return {
                ...state,
                allNotifications: action.currentStatus !== action.notifications.role ? [...state.allNotifications] : [action.notifications, ...state.allNotifications],
                adminCount: action.notifications.role === 'isAdmin' ? state.adminCount + 1 : state.adminCount,
                departmentLeaderCount: action.notifications.role === 'isDepartmentAdmin' ? state.departmentLeaderCount + 1 : state.departmentLeaderCount,
                memberCount: action.notifications.role === 'isMember' ? state.memberCount + 1 : state.memberCount,
                moderatorCount: action.notifications.role === 'isModerator' ? state.moderatorCount + 1 : state.moderatorCount,
            }
        }
        case 'SET-UNREAD-NOTIFICATIONS-AMOUNT-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, ...action.counts}
        }
        case 'SET-BUTTON-CLICKED-MENU-NOTIFICATIONS-REDUCER': {
            return {
                ...state,
                allNotifications: state.allNotifications.map(n => n.id === action.notificationId ? {
                    ...n,
                    buttonClicked: true
                } : n)
            }
        }
        default:
            return state;
    }
}

export type getNotificationsNotificationsReducerACType = ReturnType<typeof getNotificationsNotificationsReducerAC>;
export type readAllNotificationsMenuNotificationsReducerACType = ReturnType<typeof readAllNotificationsMenuNotificationsReducerAC>;
export type readCertainNotificationMenuNotificationsReducerACType = ReturnType<typeof readCertainNotificationMenuNotificationsReducerAC>;
export type addNewNotificationsMenuNotificationsReducerACType = ReturnType<typeof addNewNotificationsMenuNotificationsReducerAC>;
export type setUnreadNotificationsCountNotificationsReducerACType = ReturnType<typeof setUnreadNotificationsCountNotificationsReducerAC>;
export type setButtonClickedMenuNotificationsReducerACType = ReturnType<typeof setButtonClickedMenuNotificationsReducerAC>;

export const getNotificationsNotificationsReducerAC = (allNotifications: Array<NotificationType>) => ({
    type: 'GET-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER',
    allNotifications
} as const);
export const readAllNotificationsMenuNotificationsReducerAC = () => ({type: 'READ-ALL-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER'} as const);
export const readCertainNotificationMenuNotificationsReducerAC = (body: {
    id: number,
    seen: boolean
}) => ({type: 'READ-CERTAIN-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER', body} as const);
export const addNewNotificationsMenuNotificationsReducerAC = (notifications: NotificationType, currentStatus: string) => ({
    type: 'ADD-NEW-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER',
    notifications,
    currentStatus
} as const);
export const setUnreadNotificationsCountNotificationsReducerAC = (counts: {
    adminCount: number,
    departmentLeaderCount: number,
    memberCount: number,
    moderatorCount: number
}) => ({type: 'SET-UNREAD-NOTIFICATIONS-AMOUNT-MENU-NOTIFICATIONS-REDUCER', counts} as const);
export const setButtonClickedMenuNotificationsReducerAC = (notificationId: number) => ({
    type: 'SET-BUTTON-CLICKED-MENU-NOTIFICATIONS-REDUCER',
    notificationId
} as const);

export const getNotificationsTC = (currentStatus: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.getNotifications().then(res => {
        let counts = {
            adminCount: res.data.adminCount,
            departmentLeaderCount: res.data.departmentLeaderCount,
            memberCount: res.data.memberCount,
            moderatorCount: res.data.moderatorCount,
        }
        let notificationsForCertainRole = res.data.notifications.filter((n: any) => n.role === currentStatus)
        dispatch(setUnreadNotificationsCountNotificationsReducerAC(counts))
        dispatch(getNotificationsNotificationsReducerAC(notificationsForCertainRole))
    }).catch(err => {
        let status = err.response.data.status;
        if (status === 401) {
            window.location.href = '/signIn';
            return
        } else {
            alert('something wrong getting notifications')
        }
    })
}

export const readNotificationMenuNotificationsReducerTC = (body: { notification: { id: number, seen: boolean } } | {
    notification: Array<number>
}): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.makeNotificationSeenUnseen(body).then(res => {
        if (Array.isArray(body.notification)) {
            dispatch(readAllNotificationsMenuNotificationsReducerAC())
        } else {
            dispatch(readCertainNotificationMenuNotificationsReducerAC(body.notification))
        }
    }).catch(err => {
        alert('something wrong reading notifications')
    })
}

export const sendMessageFromTextareaMenuNotificationsTC = (teamId: number,
                                                           post: {
                                                               text: string,
                                                               anonymously: boolean,
                                                               isMotivation: boolean
                                                           },
                                                           notificationId: number,
                                                           seen: boolean,
                                                           showToolTipe: () => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.addNewPost(teamId, post).then(res => {
        showToolTipe();
    }).catch(err => {
        alert('wrong sending message from notifications menu')
    })
}

export const addNewNotificationTC = (res: any): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let currentStatus = getState().common.currentStatus;
    dispatch(addNewNotificationsMenuNotificationsReducerAC(res, currentStatus))
}

export const clickActionButtonMenuNotificationsTC = (notificationId: number,
                                                     seen: boolean,
                                                     url: string,
                                                     body: { email: string, notification: number },
                                                     showToolTipe: () => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.onClickActionButton(url, body).then(res => {
        showToolTipe();
        dispatch(setButtonClickedMenuNotificationsReducerAC(notificationId));
    }).catch(err => {
        alert('wrong clicking action button on notifications menu')
    })
}

