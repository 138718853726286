import React, {useEffect, useState} from "react";
import style from './motivationChayBox.module.scss';
import CreateMessageItem from "./createMessageItem/createMessageItem";
import MessageItem from "./messageItem/messageItem";
import {useDispatch, useSelector} from "react-redux";
import {activeTabCoachingCornerAC, coachingCornerGetPostsTC} from "../../../../redux/reducers/coachingCornerReducer";
import {RootStateType} from "../../../../redux/store/store";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import Tour, {ReactourStep} from "reactour";
import {useNavigate} from "react-router-dom";
import {setAccountModeAC, setTourCompletedTC} from "../../../../redux/reducers/commonReducer";
import {setActiveTabTeamAC} from "../../../../redux/reducers/dashboardReducer";
import ModalWindowCurseWords from "./modalWindowCurseWords/modalWindowCurseWords";
import {closeTour, getScrollingBehaviourForTour} from "../../../../common";

const MotivationChatBox = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector((state: RootStateType) => state.coachingCorner);
    const {currentStatus, tourCompleted} = useSelector((state: RootStateType) => state.common);

    const oldPostsForTour = [{
        anonymously: true,
        createdAt: "2022-05-26T22:05:33.479Z",
        daysAgo: "21 days ago",
        id: 2422,
        logo_url: "https://new.api.enterprise.getunboxt.com/utilities/static/anon.png",
        name: "Anonymous",
        seen: true,
        source: "",
        text: "Hello, I am feeling a bit down today due to the news.",
    }, {
        anonymously: true,
        createdAt: "2022-05-26T22:05:33.479Z",
        daysAgo: "25 days ago",
        id: 242222,
        logo_url: "https://new.api.enterprise.getunboxt.com/utilities/static/anon.png",
        name: "Anonymous",
        seen: true,
        source: "",
        text: "Hello, I am fine.",
    }]

    const oldPosts = tourCompleted ? state.oldPosts : oldPostsForTour;

    const blockWithOldMessages = document.getElementById('blockWithOldMessages');

    const watchOldMessagesScroll = () => {
        let scroll = window.scrollY;
        let docHeight = document.body.scrollHeight;
        let scrollTop = document.body.scrollTop;

        if (blockWithOldMessages) {
            const size = blockWithOldMessages?.getBoundingClientRect();
            //  const height = size.bottom - size.top;
            const height = blockWithOldMessages.clientHeight;
            // const prevCont = size.bottom - height;

            // console.log(scroll, size.bottom, height, document.body.scrollHeight, document.documentElement.clientHeight)
            if (size.bottom < document.documentElement.clientHeight - 30) {
                // console.log('get bottom')
            }
        }
    }

    const stepsModerator: Array<ReactourStep> = [];

    const stepsLeader: Array<ReactourStep> = [
        {
            selector: '',
            content: () => (
                <div className="hint-body"></div>
            ),
            action: () => {
                dispatch(activeTabCoachingCornerAC('trends'))
            }
        },
        {
            selector: ".motivation_tab_leader",
            position: 'right',
            content: () => (
                <div className="hint_body">
                    As a team leader, you have the opportunity to motivate your team via the motivation chat box.
                    Feel free to send anonymous or direct messages from you that are personalized to help motivate your
                    team member(s). Some of your team members will share additional details regarding their sentiment
                    for the day/week/month which can help guide you on crafting a motivational message to support them.
                </div>
            ),
        },

        {
            content: "",
            action: () => {
                window.scrollTo(0,0);
                dispatch(activeTabCoachingCornerAC('insights'));
                navigate('/teamManagement', {replace: true});
            }
        },
    ];

    const stepsMember: Array<ReactourStep> = [
        {
            selector: '',
            content: () => (
                <div className="hint-body"></div>
            ),
            action: () => {
                dispatch(activeTabCoachingCornerAC('trends'))
            }
        },
        {
            selector: ".motivation_tab_member",
            position: 'right',
            content: () => (
                <div className="hint_body">
                    As a team member, you have the opportunity to motivate your team via the motivation chat box.
                    Feel free to send anonymous or direct messages from you that are personalized to help motivate your
                    team member(s). Some of your team members will share additional details regarding their sentiment
                    for the day/week/month which can help guide you on crafting a motivational message to support them.
                    Additionally, you are able to share situations you are experiencing that are positively or
                    negatively impacting your own sentiment or motivation.
                </div>
            ),
        },
        {
            selector: '.edit_profile_icon_for_member',
            position: 'left',
            content: () => (
                <div className="hint_body">
                    You can switch views from admin to team leader to team member by clicking on your account.
                </div>
            ),
        },

        {
            content: "",
            action: () => {
                dispatch(activeTabCoachingCornerAC('insights'));
                dispatch(setAccountModeAC(true));
                navigate('/profile', {replace: true})
            }
        },
    ];

    const steps = currentStatus === 'isModerator' ? stepsModerator
        : currentStatus === 'isAdmin' ? stepsLeader : currentStatus === 'isMember' ? stepsMember : [];

    const disableBody = (target: any) => disableBodyScroll(target);
    const enableBody = (target: any) => enableBodyScroll(target);

    //для показа модпалки с ошибкой отправки сообщения с матерными словами
    const [curseWordsMode, setCurseWordsMode] = useState<boolean>(false);

    const switchCurseWordsMode = () => {
        setCurseWordsMode(!curseWordsMode);
    }

    //для хранения сообщения в модалке-предупреждении
    const [warningText, setWarningText] = useState<string>('');
    const onChangeWarningText = (text: string) => {
        setWarningText(text);
    }

    useEffect(() => {
        curseWordsMode ? disableBody(document.getElementsByTagName('body')[0]) : enableBody(document.getElementsByTagName('body')[0]);
    }, [curseWordsMode])


    useEffect(() => {
        window.addEventListener('scroll', watchOldMessagesScroll);

        return () => {
            window.removeEventListener('scroll', watchOldMessagesScroll)
        }

    }, [blockWithOldMessages])

    useEffect(() => {
        state.chosenTeamId && dispatch(coachingCornerGetPostsTC(state.chosenTeamId));
    }, [state.chosenTeamId])

    useEffect(() => {
        getScrollingBehaviourForTour(tourCompleted)
    }, [tourCompleted])

    return (
        <div className={`${style.motivation}`}>
            {steps.length ? <Tour steps={steps}
                                  isOpen={!tourCompleted}
                                  onRequestClose={() => closeTour(currentStatus, navigate, dispatch)}
                                  closeWithMask={false}
                                  showNumber={false}
                                  nextButton={<span>NEXT</span>}
                                  prevButton={<span>PREV</span>}
                                  showNavigationNumber={false}
                                  disableDotsNavigation={true}
                                  disableInteraction={true}
                                 // onAfterOpen={disableBody}
                                 // onBeforeClose={enableBody}
                                  startAt={1}
            /> : null}

            {curseWordsMode && <ModalWindowCurseWords switchCurseWordsMode={switchCurseWordsMode}
                                                      setWarningText={onChangeWarningText}
                                                      warningText={warningText}/>}


            <div className={style.motivation_body}>
                {state.postFromMotivation &&
                    <div className={style.motivation_body_text}>{state.itemInModalWindow?.insightBody}</div>}
                <CreateMessageItem switchCurseWordsMode={switchCurseWordsMode} setWarningText={onChangeWarningText}/>

                <div className={style.block_with_new_messages}>
                    {
                        state.newPosts.map(message => <MessageItem key={message.id} post={message}/>)
                    }
                </div>

                <div className={style.posts_separator}>
                    <p>New Messages ({state.newPosts.length})</p>
                </div>

                <div className={style.block_with_old_messages} id={'blockWithOldMessages'}>
                    {
                        oldPosts.map(message => <MessageItem key={message.id} post={message}/>)
                    }
                </div>
            </div>
            {state.loading && <LoaderDots/>}
        </div>
    )
}

export default MotivationChatBox;