import React, {useEffect} from "react";
import style from './changeLeaderDepartmentManagementModalWindow.module.scss';
import {useDispatch, useSelector} from "react-redux";
import cancel from "../../../../../assets/images/teamManagement/cancel.png";
import {ErrorMessage, Field, Form, Formik} from "formik";
import ButtonWithImage from "../../../../parts/buttonWithImage/buttonWithImage";
import save from "../../../../../assets/images/common/save.png";
import {
    changeDepartmentManagerTC,
    changeTeamLeaderDepartmentManagementTC,
} from "../../../../../redux/reducers/departmentManagementReducer";
import {RootStateType} from "../../../../../redux/store/store";

type ChangeLeaderDepartmentManagementModalWindowPropsType = {
    onChangeLeaderModalWindowMode: (mode: boolean) => void,
}

type FormikErrorType = {
    firstName?: string,
    lastName?: string,
    email?: string,
}

const ChangeLeaderDepartmentManagementModalWindow = (props: ChangeLeaderDepartmentManagementModalWindowPropsType) => {
    const dispatch = useDispatch();
    let state = useSelector((state: RootStateType) => state.departmentManagement);
    let leader = state.leader;

    //чтоб выцепить имя лидера команды для модалки когда меняем тим лидера
    const team = state.certainTeamPage.certainTeamPageInfo;
    let teamLeaderName = team.leaderName;
    let id = leader === 'department' ? state.chosenDepartment?.id : state.chosenTeam.id;
    let name = leader === 'department' ? state.chosenDepartment?.name : state.chosenTeam.name;


    const onChangeTeamLeader = (id: number, info: {
        firstName: string,
        lastName: string,
        email: string,
        keep: boolean
    }) => {
        dispatch(changeTeamLeaderDepartmentManagementTC(id, info));
    }

    const onChangeDepartmentManager = (id: number, info: {
        firstName: string,
        lastName: string,
        email: string,
        keep: boolean
    }) => {
        dispatch(changeDepartmentManagerTC(id, info));
    }

    useEffect(() => {
        document.body.classList.add('overflow-hidden')

        return (() => {
            document.body.classList.remove('overflow-hidden');
        })
    }, [])

    return (
        <div className={style.leader_modal_wrapper}>
            <div className={style.leader_modal_window}>
                <div className={style.leader_modal_window_header}>
                    {leader === 'team' ? <p>Change Team Leader</p> : <p>Change Department Manager</p>}
                    <div className={style.cancel_button} onClick={() => props.onChangeLeaderModalWindowMode(false)}>
                        <img src={cancel} alt=""/>
                    </div>
                </div>

                <div className={style.leader_modal_window_separator}></div>

                <div className={style.leader_modal_window_body}>
                    {leader === 'team' ? <p>You are changing your Team Leader for the {name}</p> :
                        <p>You are changing your Department Manager for the {name}</p>}

                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            choice: '2',
                        }}
                        validate={(values) => {
                            let errors: FormikErrorType = {};

                            if (!values.firstName) {
                                errors.firstName = 'First name is required!';
                            } else if (!/^[a-zA-Z][a-zA-Z-]*[a-zA-Z]$/.test(values.firstName)) {
                                errors.firstName = 'Invalid first name format';
                            }
                            if (!values.lastName) {
                                errors.lastName = 'Last name is required!';
                            } else if (!/^[a-zA-Z][a-zA-Z-]*[a-zA-Z]$/.test(values.lastName)) {
                                errors.lastName = 'Invalid last name format';
                            }
                            if (!values.email) {
                                errors.email = 'Email is required!';
                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                errors.email = 'Invalid email address!';
                            }
                            return errors;
                        }}
                        onSubmit={(values) => {
                            let info = {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email,
                                keep: values.choice === '2'
                            }

                            leader === 'team' ? onChangeTeamLeader(id, info) : onChangeDepartmentManager(id, info);
                            props.onChangeLeaderModalWindowMode(false);
                        }}
                    >
                        <Form>
                            <div className={style.choice_wrapper}>
                                <Field id='choice2' type="radio" name="choice" value="2" checked/>
                                <label
                                    htmlFor='choice2'>{leader === 'team' ? `Keep ${teamLeaderName}  as a team member` : 'Leave the previous manager in the department'}</label>
                            </div>
                            <div className={style.choice_wrapper}>
                                <Field id='choice1' type="radio" name="choice" value="1"/>
                                <label
                                    htmlFor='choice1'>{leader === 'team' ? `Remove ${teamLeaderName} as team leader` : 'Remove previous manager from the department'}</label>
                            </div>

                            <div className={style.input_text_wrapper}>
                                <label htmlFor="email">Email</label>
                                <Field id="email" name="email" type="text" placeholder='Enter email'/>
                            </div>
                            <ErrorMessage name="email" component='div' className={style.error_message}/>

                            <div className={style.input_text_wrapper}>
                                <label htmlFor="firstName">First Name</label>
                                <Field id="firstName" name="firstName" type="text" placeholder='Enter first name'/>
                            </div>
                            <ErrorMessage name="firstName" component='div' className={style.error_message}/>

                            <div className={style.input_text_wrapper}>
                                <label htmlFor="lastName">Last Name</label>
                                <Field id="lastName" name="lastName" type="text" placeholder='Enter last name'/>
                            </div>
                            <ErrorMessage name="lastName" component='div' className={style.error_message}/>

                            <div className={style.button_wrapper}>
                                <ButtonWithImage
                                    height={46}
                                    name={leader === 'team' ? `Save and Add Team Leader` : 'Save and Add Manager'}
                                    type={'submit'}
                                    margin={'0 auto'}
                                    img={save}></ButtonWithImage>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default ChangeLeaderDepartmentManagementModalWindow;