import React, {ChangeEvent, useCallback, useRef} from "react";
import style from './itemWithTextarea.module.scss';
import {NotificationType} from "../../../../../redux/reducers/notificationsMenuReducer";
import info_icon from './../../../../../assets/images/common/info_2.png';
import close_icon from './../../../../../assets/images/common/close_2.png';

export type ItemWithTextareaPropsType = {
    sendMessage: (teamId: number, post: { text: string, anonymously: boolean, isMotivation: boolean }) => void,
    notification: NotificationType
}

const ItemWithTextarea = (props: ItemWithTextareaPropsType) => {
    const infoMessageRef = useRef<HTMLDivElement>(null);
    //для анонимности
    const [anonym, changeAnonym] = React.useState<boolean>(false);
    const [showInfo, setShowInfo] = React.useState<boolean>(false);
    //срабатывает когда по инпуту чекаем
    const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        changeAnonym(e.target.checked);
    }
    //для изменения текста в текстареа
    const [text, changeText] = React.useState<string>('');

    const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        changeText(e.currentTarget.value);
    }

    //жмем на кнопку отправить (Send)
    const onSendMessage = () => {
        if (text.trim().length) {
            let message = text.replace(/\n/ig, ' ')
            let post = {
                text: message,
                anonymously: anonym,
                isMotivation: false
            }
            // props.notificationType === 1 ? props.sendMessage(text.replace(/\n/ig, ' '), anonym) : props.sendMessage(text.replace(/\n/ig, ' '))
            changeText('')
            props.sendMessage(props.notification.teamId || 1, post)
        }
    }

    const disabledButton = !text.trim().length;

    const toggleInfoMessage = () => {
        !showInfo ? infoMessageRef?.current?.classList.add(style.show) : infoMessageRef?.current?.classList.remove(style.show);
        setShowInfo(!showInfo)
    }

    return (
        <>
            <div className={style.block_with_textarea}>
                <textarea
                    placeholder={props.notification.textAreaPlaceholder || ''}
                    // onKeyPress={enterPress}
                    onChange={onChangeText}
                    value={text}/>
                <div className={style.send_button} onClick={onSendMessage} id={`${props.notification.id}`}
                   data-tooltipe={`Action completed!`}
                   style={{color: disabledButton ? 'rgba(38, 151, 254, .5)' : 'rgba(38, 151, 254, 1)'}}>
                    <span className={style.btn_span}></span>
                    <div className={style.info_btn} onClick={toggleInfoMessage}>
                        <img src={!showInfo ? info_icon : close_icon} alt="info_icon"/>
                    </div>
                    <div className={style.info_message} ref={infoMessageRef}>The message will show up in the motivation chat box within coaching corner</div>
                    Send
                </div>
            </div>

            {props.notification.textAreaAnonymous && <div className={style.block_with_checkbox}>
                <input className={style.block_with_checkbox_input} onChange={onCheck}
                       id={`anonymity ${props.notification.id}`}
                       type="checkbox"
                       name="anonymity"/>
                <label className={style.block_with_checkbox_label}
                       htmlFor={`anonymity ${props.notification.id}`}
                >Send Anonymously</label>
            </div>}
        </>
    )
}

export default ItemWithTextarea;