import React, {useState} from "react";
import style from './buttonLinkInvite.module.scss';
import linkIcon from "../../../../../assets/images/common/link.png";
import closeIcon from "../../../../../assets/images/companyManagement/close.png";
import infoIcon from "../../../../../assets/images/companyManagement/info.png";
import del from "../../../../../assets/images/common/trash.png";

export type btnLinkType = null
    | 'admin cm'
    | 'department cm'
    | 'team leader cm'
    | 'delete dm'
    | 'department dm'
    | 'delete tm'
    | 'team tm'
    | 'team dmctl';

type ButtonLinkInvitePropsType = {
    index: number,
    link: string,
    btnName: string,
    who: btnLinkType,
    infoMode: boolean,
    whoInfoMessage: btnLinkType,
    showInfoMessage: (e: React.MouseEvent<HTMLDivElement>, who: btnLinkType) => void,
    onSubmitAction?: () => void
}
const ButtonLinkInvite = ({
                              index,
                              link,
                              btnName,
                              who,
                              infoMode,
                              whoInfoMessage,
                              showInfoMessage,
                              onSubmitAction,
                          }: ButtonLinkInvitePropsType) => {


    const onClickInfoIcon = (e: React.MouseEvent<HTMLDivElement>) => {
        who && showInfoMessage(e, who)
    }

    const copyLink = (link: string, index: number) => {
        let btn = document.querySelectorAll(".btn")[index];
        let span = document.querySelectorAll(".btn span")[index];

        navigator.clipboard.writeText(link).then(res => {
            // console.log('LINK WAS COPIED')
        });

        let data = btn?.getAttribute("data-tooltipe");
        //@ts-ignore
        span.style.display = "block";
        //@ts-ignore
        span.innerHTML = data;

        setTimeout(() => {
            //@ts-ignore
            span.style.display = "none";
        }, 2000);
    };

    const btnLinkInfoText = {
        ['admin cm']: 'Distribute this link to allow the Admins of your company who are responsible for managing your organization to signup. They will be able to view the data of all teams and departments.',
        ['team leader cm']: 'Share this link to your company’s Team Leaders and ask them to signup and create their own team',
        ['department cm']: 'Share this link to your company’s Department Leaders and ask them to signup and create their own department',
        ['delete dm']: 'Permanently delete your department.',
        ['department dm']: 'Share this link to team leaders that should sign up within your department.',
        ['delete tm']: 'Permanently delete your team.',
        ['team tm']: 'Share this link to team members that should sign up within your team',
        ['team dmctl']: 'Share this link to team members that should sign up within your team'
    }

    return (
        <button className={`button_with_image btn`} style={{maxWidth: '183px', width: '100%'}} data-tooltipe={'Copied!'}
                onClick={() => onSubmitAction ? onSubmitAction() : copyLink(link, index)}>
            <div className='button_with_image_img'>
                <img src={who && who.includes('delete') ? del : linkIcon} alt={btnName}/>
            </div>
            <div className={'button_with_image_value'}>
                <span></span>
                {btnName}
            </div>
            <div className={style.info_icon} onClick={onClickInfoIcon}>
                <img src={infoMode && whoInfoMessage === who ? closeIcon : infoIcon} alt="info_icon"/>
            </div>

            {whoInfoMessage === who ?
                <div className={`${style.info_message} ${infoMode ? [style.shown] : [style.unshown]}`}>
                    {whoInfoMessage && btnLinkInfoText[whoInfoMessage]}
                </div> : null}
        </button>
    )
}

export default ButtonLinkInvite;