import React, {useEffect} from "react";
import style from './modalWindowCurseWords.module.scss';
import close from './../../../../../assets/images/common/close.png';
import chat from './../../../../../assets/images/coachingCorner/chat.png';
import ButtonWithoutImage from "../../../../parts/buttonWithoutImage/buttonWithoutImage";

export type ModalWindowCurseWordsPropsType = {
    switchCurseWordsMode: () => void,
    setWarningText: (text: string) => void,
    warningText: string
}

const ModalWindowCurseWords = (props: ModalWindowCurseWordsPropsType) => {
    //по клику на эту кнопку сделать активной текстареа
    const onClickResend = () => {
        props.switchCurseWordsMode();
    }

    const onClickCloseButton = () => {
        props.switchCurseWordsMode();
    }

    useEffect(() => {
        return () => {
            let el = document.getElementById('motivation_chatbox_textarea');
            el?.focus();
            //при закрытии модалки текст варнинга зачищаем
            props.setWarningText('');
        }
    }, [])

    return (
        <div className={style.modal_wrapper}>
            <div className={`${style.modal_body} border_1`}>
                <div className={style.modal_body_header}>
                    <div className={style.modal_body_header_close_button} onClick={onClickCloseButton}>
                        <img src={close} alt=""/>
                    </div>
                </div>

                <div className={style.modal_body_content}>
                    <div className={style.modal_body_content_title}>Your message could not be sent.</div>

                    <div className={style.modal_body_content_info}>
                        <div className={style.image_wrapper}>
                            <img src={chat} alt=""/>
                        </div>
                        <div className={style.text_wrapper}>
                            <p>There was an error sending this message as it may contain offensive language</p>
                            <p>{props.warningText}</p>
                        </div>
                    </div>

                    <div className={style.modal_body_content_button}>
                        <ButtonWithoutImage width={11.438} name={'Resend'} onClickHandler={onClickResend}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalWindowCurseWords;