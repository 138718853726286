import {applyMiddleware, combineReducers, createStore} from "redux";
import {commonReducer} from "../reducers/commonReducer";
import {dashboardReducer} from "../reducers/dashboardReducer";
import thunkMiddleware, {ThunkAction} from "redux-thunk";
import {AllActionType} from "../../types";
import {onboardingReducer} from "../reducers/onboardingReducer";
import {teamManagementReducer} from "../reducers/teamManagementReducer";
import {companyManagementReducer} from "../reducers/companyManagementReducer";
import {signUpReducer} from "../reducers/signUpReducer";
import {setPasswordReducer} from "../reducers/setPasswordReducer";
import {signInReducer} from "../reducers/signInReducer";
import {coachingCornerReducer} from "../reducers/coachingCornerReducer";
import {digitalPulseReducer} from "../reducers/digitalPulseReducer";
import {profileReducer} from "../reducers/profileReducer";
import {createTeamReducer} from "../reducers/createTeamReducer";
import {assessmentReducer} from "../reducers/assessmentReducer";
import {faqReducer} from "../reducers/faqReducer";
import {departmentManagementReducer} from "../reducers/departmentManagementReducer";
import {createDepartmentReducer} from "../reducers/createDepartmentReducer";
import {unsubscribePageReducer} from "../reducers/unsubscribePageReducer";
import {teamSelectionForCheckinsReducer} from "../reducers/teamSelectionForCheckinsReducer";
import {checkinsQuestionnaireReducer} from "../reducers/checkinsQuestionnaireReducer";
import {notificationsMenuReducer} from "../reducers/notificationsMenuReducer";
import {feedbackReducer} from "../reducers/feedbackReducer";


const rootReducer = combineReducers({
    signIn: signInReducer,
    signUp: signUpReducer,
    setPassword: setPasswordReducer,
    common: commonReducer,
    onboarding: onboardingReducer,
    dashboard: dashboardReducer,
    teamManagement: teamManagementReducer,
    companyManagement: companyManagementReducer,
    coachingCorner: coachingCornerReducer,
    digitalPulse: digitalPulseReducer,
    profile: profileReducer,
    createTeam: createTeamReducer,
    assessment: assessmentReducer,
    faq: faqReducer,
    departmentManagement: departmentManagementReducer,
    createDepartment: createDepartmentReducer,
    unsubscribePage: unsubscribePageReducer,
    teamSelectionForCheckinsPage: teamSelectionForCheckinsReducer,
    checkinsQuestionnaire: checkinsQuestionnaireReducer,
    notificationsMenu: notificationsMenuReducer,
    feedback: feedbackReducer,
})

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))

export type RootStateType = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootStateType, unknown, AllActionType>;

//чтобы смотереть в консоли store
//@ts-ignore
window.store = store;
