import {AllActionType} from "../../types";
import {AppThunk} from "../store/store";
import {digitalPulseAPI} from "../../api/api";
import {formatDate, transformTeamAnswers} from "../../common";
import {v1} from "uuid";
import {SendPulseDataType} from "../../components/pages/digitalPulse/build/build";
import {ActiveTabType, StatusType} from "../../components/pages/digitalPulse/manage/manage";
import {QuestionnaireAnswerType} from "../../components/pages/digitalPulse/questionnaire/questionnaire";
import {Dispatch, SetStateAction} from "react";

const initialDays = [
    {id: 1, title: 'M', active: true},
    {id: 2, title: 'T', active: true},
    {id: 3, title: 'W', active: true},
    {id: 4, title: 'Th', active: true},
    {id: 5, title: 'F', active: true},
    {id: 6, title: 'St', active: false},
    {id: 7, title: 'Sun', active: false},
]

const initialFrequencies = [
    {label: 'Daily', value: 1},
    {label: 'Weekly', value: 2},
    {label: 'Monthly', value: 3},
    {label: 'Yearly', value: 4}
];

const initialQuestionsBuildPage = [{
    id: v1(),
    value: '',
    type: null,
    options: [],
    editMode: false
},
    {
        id: v1(),
        value: '',
        type: null,
        options: [],
        editMode: false
    }];

export type QuestionOnQuestionnaireType = {
    answer: string,
    id: number,
    options: null | Array<string>,
    text: string,
    type: number
}

export type QuestionFromPulseOnReady = {
    pool: null,
    text: string,
    typeId: number,
    id: number,
    isChecked: boolean
}

export type PulseTypeFromReady = {
    id: number,
    name: string,
    questions: Array<{ pool: null, text: string, typeId: number }>,
    showCheckbox: boolean
};

export type DataForUpdateType = {
    questions: Array<{ text: string, typeId: number, pool: Array<string> | null }>,
    teams: Array<number>,
    name: string,
    startDate: string,
    endDate: string,
    time: string,
    recurringOn: boolean,
    frequency: null | { label: string, value: number },
    days: Array<FrequencyDayType>,
}

export type FrequencyDayType = {
    id: number,
    title: string,
    active: boolean
}

export type PulseType = {
    creator: string,
    createdAt: string,
    creatorId: number,
    endDate: string,
    id: number,
    isActive: boolean,
    isDraft: boolean,
    membersCount: number,
    name: string,
    responsesCount: number,
    startDate: string,
    status: string,
    time: string,
    updatedAt: string,
    recurringOn: boolean,
    frequency: null | { label: string, value: number },
    days: Array<FrequencyDayType>,
}

export type OptionType = {
    id: string,
    value: string,
    editMode: boolean
}

export type QuestionType = {
    createdAt: string,
    id: number,
    name: string,
    updatedAt: string,
    value: number,
    label: string
}

export type UserQuestionType = {
    id: string,
    value: string,
    type: QuestionType | null,
    options: Array<OptionType>,
    editMode: boolean
}

export type TeamFromServerType = {
    departamentId: number,
    id: number,
    isAdmin: boolean,
    isMember: boolean,
    leader: string,
    name: string,
    onboardingCompleted: boolean,
}

export type TeamType = {
    departamentId: number,
    id: number,
    isAdmin: boolean,
    isMember: boolean,
    label: string,
    leader: string,
    name: string,
    onboardingCompleted: boolean,
    value: number
}

export type DepartmentType = {
    id: number,
    name: string,
    value: number,
    label: string
}

export type QuestionTypeFromManage = {
    createdAt: string,
    digitalPulseId: number,
    id: number,
    pool: null | Array<string>,
    text: string,
    typeId: number,
    typeName: string,
    updatedAt: string
}

export type TeamTypeFromManage = {
    id: number,
    teamName: string,
    leaderName: string
}

export type PulseTypeFromManage = {
    companyName: string,
    createdAt: string,
    creatorId: number,
    endDate: string,
    id: number,
    isActive: boolean,
    isDraft: boolean,
    membersCount: number,
    name: string,
    responsesCount: number,
    startDate: string,
    status: string,
    time: string,
    updatedAt: string,
    recurringOn: boolean,
    frequency: null | { label: string, value: number },
    days: Array<FrequencyDayType>,
}

export type BuildPageStateType = {
    checkinName: string,
    startDate: string,
    endDate: string,
    recurringOn: boolean,
    frequency: null | { label: string, value: number },
    days: Array<FrequencyDayType>,
    time: string,
    timeFromServer: string | null,
    filteredTeams: Array<TeamType>,
    chosenDepartments: Array<DepartmentType>,
    chosenTeams: Array<TeamType>,
    userQuestions: Array<UserQuestionType>
}

export type ManagePageStateType = {
    pulseList: Array<PulseTypeFromManage>,
    creators: Array<{ value: number, label: string }>
    teamName: string,
    teams: Array<TeamTypeFromManage>,
    questions: Array<UserQuestionType>,
    chosenPulse: PulseTypeFromManage,
    companyName: string
}

export type EditPageStateType = {
    checkinName: string,
    startDate: string,
    endDate: string,
    recurringOn: boolean,
    frequency: null | { label: string, value: number },
    days: Array<FrequencyDayType>,
    time: string,
    chosenPulse: PulseTypeFromManage,
    timeFromServer: string | null,
    filteredTeams: Array<TeamType>,
    chosenDepartments: Array<DepartmentType>,
    chosenTeams: Array<TeamType>,
    userQuestions: Array<UserQuestionType>
}

export type ResultPageStateType = {
    pulseList: Array<PulseType>,
    creators: Array<{ value: number, label: string }>
    chosenTeam: { id: number, name: string },
    teams: Array<{ id: number, name: string }>,
    questions: Array<any>,
    membersCount: number,
    responsesCount: number | null,
    chosenPulse: PulseType,
}

export type ReadyPageStateType = {
    pulses: Array<PulseTypeFromReady>,
    chosenPulse: PulseTypeFromReady | null,
    questions: Array<QuestionFromPulseOnReady>,
    chosenQuestions: Array<QuestionFromPulseOnReady>,
    modalWindowInfo: {
        checkinName: string,
        startDate: string,
        endDate: string,
        time: string,
        timeFromServer: string | null,
        departments: Array<{ value: number, label: string }>,
        chosenDepartments: Array<{ value: number, label: string }>,
    }
}

export type DigitalPulseStateType = {
    allTeams: Array<TeamType>,
    allDepartments: Array<DepartmentType>,
    questionsTypes: Array<QuestionType>,
    isErrorNotification: null | string,
    activeTab: ActiveTabType,
    previewMode: boolean,
    loading: boolean,
    filteredDate: string,
    filteredStatus: string | StatusType,
    filteredCreator: string | StatusType,
    buildPage: BuildPageStateType,
    managePage: ManagePageStateType,
    editPage: EditPageStateType,
    resultPage: ResultPageStateType,
    readyPage: ReadyPageStateType,
    questionnaire: {
        teamName: string,
        companyName: string,
        questions: Array<QuestionOnQuestionnaireType>
    }
}

const initialState: DigitalPulseStateType = {
    allTeams: [],
    allDepartments: [],
    questionsTypes: [],
    isErrorNotification: null,
    activeTab: 'Manage',
    previewMode: false,
    loading: false,
    filteredDate: '',
    filteredStatus: '',
    filteredCreator: '',
    buildPage: {
        checkinName: 'Enter Checkin name',
        startDate: '',
        endDate: '',
        recurringOn: false,
        frequency: null,
        days: initialDays,
        time: '12:00',
        timeFromServer: null,
        filteredTeams: [],
        chosenDepartments: [],
        chosenTeams: [],
        userQuestions: initialQuestionsBuildPage,
    },
    managePage: {
        pulseList: [],
        creators: [],
        teamName: '',
        teams: [],
        questions: [],
        chosenPulse: {
            companyName: '',
            createdAt: '',
            creatorId: 0,
            endDate: '',
            id: 0,
            isActive: false,
            isDraft: false,
            membersCount: 0,
            name: '',
            responsesCount: 0,
            startDate: '',
            status: '',
            time: '',
            updatedAt: '',
            recurringOn: false,
            frequency: null,
            days: [],
        },
        companyName: ''
    },
    editPage: {
        chosenPulse: {
            companyName: '',
            createdAt: '',
            creatorId: 0,
            endDate: '',
            id: 0,
            isActive: false,
            isDraft: false,
            membersCount: 0,
            name: '',
            responsesCount: 0,
            startDate: '',
            status: '',
            time: '',
            updatedAt: '',
            recurringOn: false,
            frequency: null,
            days: [],
        },
        checkinName: '',
        startDate: '',
        endDate: '',
        recurringOn: false,
        frequency: null,
        days: [
            {id: 1, title: 'M', active: true},
            {id: 2, title: 'T', active: true},
            {id: 3, title: 'W', active: true},
            {id: 4, title: 'Th', active: true},
            {id: 5, title: 'F', active: true},
            {id: 6, title: 'St', active: false},
            {id: 7, title: 'Sun', active: false},
        ],
        time: '',
        timeFromServer: null,
        filteredTeams: [],
        chosenDepartments: [],
        chosenTeams: [],
        userQuestions: [
            {
                id: v1(),
                value: '',
                type: null,
                options: [],
                editMode: false
            },
            {
                id: v1(),
                value: '',
                type: null,
                options: [],
                editMode: false
            }]
    },
    resultPage: {
        pulseList: [],
        creators: [],
        chosenTeam: {id: 0, name: ''},
        teams: [],
        questions: [],
        membersCount: 0,
        responsesCount: null,
        chosenPulse: {
            creator: '',
            createdAt: '',
            creatorId: 0,
            endDate: '',
            id: 0,
            isActive: false,
            isDraft: false,
            membersCount: 0,
            name: '',
            responsesCount: 0,
            startDate: '',
            status: '',
            time: '',
            updatedAt: '',
            recurringOn: false,
            frequency: null,
            days: [],
        },
    },
    readyPage: {
        pulses: [],
        chosenPulse: null,
        //вопросы выбранного пульса
        questions: [],
        //чекнутые вопросы выбранного пульса
        chosenQuestions: [],
        modalWindowInfo: {
            checkinName: 'Check-in 1',
            startDate: '',
            endDate: '',
            time: '12:00',
            timeFromServer: null,
            departments: [],
            chosenDepartments: [],
        }
    },
    questionnaire: {
        teamName: '',
        companyName: '',
        questions: []
    }

}

export const digitalPulseReducer = (state = initialState, action: AllActionType) => {
    switch (action.type) {
        case 'DIGITAL-PULSE-SET-LOADING': {
            return {...state, loading: action.loading}
        }
        case 'DIGITAL-PULSE-SET-ERROR-NOTIFICATION': {
            return {...state, isErrorNotification: action.notification}
        }
        case 'DIGITAL-PULSE-SET-ACTIVE-TAB': {
            return {...state, activeTab: action.tab}
        }
        case 'DIGITAL-PULSE-SET-PREVIEW-MODE': {
            return {...state, previewMode: action.mode}
        }
        case 'DIGITAL-PULSE-SET-QUESTIONS-TYPES': {
            return {...state, questionsTypes: [...action.types]}
        }
        case 'DIGITAL-PULSE-SET-ALL-TEAMS': {
            return {...state, allTeams: [...action.teams]}
        }
        case 'DIGITAL-PULSE-SET-ALL-DEPARTMENTS': {
            return {...state, allDepartments: [...action.departments]}
        }
        case 'DIGITAL-PULSE-BUILD-SET-RECURRING': {
            return {...state, buildPage: {...state.buildPage, recurringOn: action.recurring}}
        }
        case 'DIGITAL-PULSE-BUILD-SET-FREQUENCY-DAY': {
            return {
                ...state, buildPage: {
                    ...state.buildPage,
                    days: state.buildPage.days.map(d => d.id === action.dayId ? {...d, active: !d.active} : d)
                }
            }
        }
        case 'DIGITAL-PULSE-BUILD-SET-FREQUENCY': {
            return {...state, buildPage: {...state.buildPage, frequency: action.frequency}}
        }
        case 'DIGITAL-PULSE-BUILD-CLEAR-STATE': {
            return {
                ...state, buildPage: {
                    ...state.buildPage,
                    checkinName: 'Enter Checkin name',
                    startDate: '',
                    endDate: '',
                    recurringOn: false,
                    frequency: null,
                    days: initialDays,
                    time: '12:00',
                    timeFromServer: null,
                    filteredTeams: [],
                    chosenDepartments: [],
                    chosenTeams: [],
                    userQuestions: initialQuestionsBuildPage
                }
            }
        }
        case 'DIGITAL-PULSE-BUILD-SET-CHECKIN-NAME': {
            return {...state, buildPage: {...state.buildPage, checkinName: action.name}}
        }
        case 'DIGITAL-PULSE-SET-START-DATE': {
            return {...state, buildPage: {...state.buildPage, startDate: action.date}}
        }
        case 'DIGITAL-PULSE-SET-END-DATE': {
            return {...state, buildPage: {...state.buildPage, endDate: action.date}}
        }
        case 'DIGITAL-PULSE-SET-TIME': {
            return {...state, buildPage: {...state.buildPage, time: action.time}}
        }
        case 'DIGITAL-PULSE-SET-TIME-FROM-SERVER': {
            return {...state, buildPage: {...state.buildPage, timeFromServer: action.time}}
        }
        case 'DIGITAL-PULSE-CHOOSE-TEAM': {
            return {...state, buildPage: {...state.buildPage, chosenTeams: [...action.teams]}}
        }
        case 'DIGITAL-PULSE-CHOOSE-DEPARTMENT': {
            return {...state, buildPage: {...state.buildPage, chosenDepartments: [...action.departments]}}
        }
        case 'DIGITAL-PULSE-SET-FILTERED-TEAMS': {
            return {...state, buildPage: {...state.buildPage, filteredTeams: [...action.teams]}}
        }
        case 'DIGITAL-PULSE-BUILD-CHANGE-USER-QUESTION-TYPE': {
            let questionsList = state.buildPage.userQuestions.map(q => q.id === action.questionId ? {
                ...q,
                type: {...action.questionType}
            } : q);
//здесь происходит зачистка опций если переключился с двоечки на другой тип
            return {
                ...state, buildPage: {
                    ...state.buildPage, userQuestions: questionsList.map(q => {
                        if (q.id === action.questionId && q.type?.id === 2 && !q.options.length) {
                            return {...q, options: [...action.options]}
                        } else if (q.id === action.questionId && action.questionType?.id !== 2 && q.options.length) {
                            return {...q, options: []}
                        } else {
                            return q
                        }
                    })
                }
            }
        }
        case 'DIGITAL-PULSE-BUILD-ADD-NEW-QUESTION': {
            if (state.buildPage.userQuestions.length > 4) {
                return {...state};
            }
            let newQuestion = {
                id: v1(),
                value: '',
                type: null,
                options: [],
                editMode: false
            }
            let newQuestionsList = [...state.buildPage.userQuestions];
            newQuestionsList.splice(action.index + 1, 0, newQuestion);
            return {...state, buildPage: {...state.buildPage, userQuestions: [...newQuestionsList]}}
        }
        case 'DIGITAL-PULSE-BUILD-REMOVE-QUESTION': {
            if (state.buildPage.userQuestions.length === 1) {
                return {...state};
            }
            return {
                ...state,
                buildPage: {
                    ...state.buildPage,
                    userQuestions: state.buildPage.userQuestions.filter(q => q.id !== action.questionId)
                }
            }
        }
        case 'DIGITAL-PULSE-BUILD-CHANGE-QUESTION-TEXT': {
            return {
                ...state,
                buildPage: {
                    ...state.buildPage,
                    userQuestions: state.buildPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        value: action.text
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-BUILD-CHANGE-OPTION-IN-MULTIPLE-QUESTION': {
            return {
                ...state,
                buildPage: {
                    ...state.buildPage,
                    userQuestions: state.buildPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        options: q.options.map(o => o.id === action.option.id ? {...action.option} : o)
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-BUILD-ADD-NEW-OPTION-IN-MULTIPLE-QUESTION': {
            let newOption = {id: v1(), value: `new option`, editMode: false};
            return {
                ...state,
                buildPage: {
                    ...state.buildPage,
                    userQuestions: state.buildPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        options: [...q.options, newOption]
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-BUILD-DELETE-OPTION-IN-MULTIPLE-QUESTION': {
            return {
                ...state,
                buildPage: {
                    ...state.buildPage,
                    userQuestions: state.buildPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        options: q.options.filter(o => o.id !== action.optionId)
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-MANAGE-EDIT-ACTIVE-PULSE': {
            let {endDate, days, frequency, recurringOn} = action.body;
            let newEndDate = endDate ? formatDate(new Date(endDate)) : '';
            let newDays = days.length > 0 ? initialDays.map((i, index) => ({
                ...i,
                active: days[index]
            })) : initialDays;
            let newFrequency = initialFrequencies.filter(f => f.label === frequency)[0] || null;
            return {
                ...state, managePage: {
                    ...state.managePage,
                    pulseList: state.managePage.pulseList.map(p => p.id === action.pulseId
                        ? {...p, endDate: newEndDate, days: newDays, frequency: newFrequency, recurringOn} : p)
                }
            }

        }
        case 'DIGITAL-PULSE-MANAGE-SET-PULSE-AND-CREATORS-LIST': {
            return {
                ...state,
                managePage: {...state.managePage, pulseList: [...action.pulseList], creators: [...action.creators]}
            }
        }
        case 'DIGITAL-PULSE-MANAGE-MAKE-PULSE-ACTIVE': {
            return {
                ...state,
                managePage: {
                    ...state.managePage,
                    pulseList: state.managePage.pulseList.map(i => i.id === action.pulseId ? {
                        ...i,
                        status: 'Active'
                    } : i)
                }
            }
        }
        case 'DIGITAL-PULSE-MANAGE-DELETE-PULSE': {
            return {
                ...state,
                managePage: {...state.managePage, pulseList: state.managePage.pulseList.filter(i => i.id !== action.id)}
            }
        }
        case 'DIGITAL-PULSE-MANAGE-DEACTIVE-PULSE': {
            return {
                ...state,
                managePage: {
                    ...state.managePage,
                    pulseList: state.managePage.pulseList.map(i => i.id === action.id ? {...i, status: 'Disabled'} : i)
                }
            }
        }
        case 'DIGITAL-PULSE-MANAGE-SET-FILTERED-DATE': {
            return {...state, filteredDate: action.date}
        }
        case 'DIGITAL-PULSE-MANAGE-SET-FILTERED-STATUS': {
            return {...state, filteredStatus: action.status}
        }
        case 'DIGITAL-PULSE-MANAGE-SET-FILTERED-CREATOR': {
            return {...state, filteredCreator: action.creator}
        }
        case 'DIGITAL-PULSE-RESET-FILTERS': {
            return {...state, filteredDate: '', filteredStatus: '', filteredCreator: ''}
        }
        case 'DIGITAL-PULSE-MANAGE-SET-INFO-FOR-VIEW-SURVEY': {
            return {
                ...state, managePage: {
                    ...state.managePage, teamName: action.teamName, companyName: action.companyName,
                    chosenPulse: {...action.chosenPulse}, teams: [...action.teams], questions: [...action.questions]
                }
            }
        }
        case 'DIGITAL-PULSE-MANAGE-CHANGE-OPTION-IN-MULTIPLE-QUESTION': {
            return {
                ...state,
                managePage: {
                    ...state.managePage,
                    questions: state.managePage.questions.map(q => q.id === action.questionId ? {
                        ...q,
                        options: q.options.map(o => o.id === action.option.id ? {...action.option} : o)
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-MANAGE-CHANGE-QUESTION-TEXT': {
            return {
                ...state,
                managePage: {
                    ...state.managePage,
                    questions: state.managePage.questions.map(q => q.id === action.questionId ? {
                        ...q,
                        value: action.text
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-EDIT-SET-RECURRING': {
            return {...state, editPage: {...state.editPage, recurringOn: action.recurring}}
        }
        case 'DIGITAL-PULSE-EDIT-SET-FREQUENCY-DAY': {
            return {
                ...state, editPage: {
                    ...state.editPage,
                    days: state.editPage.days.map(d => d.id === action.dayId ? {...d, active: !d.active} : d)
                }
            }
        }
        case 'DIGITAL-PULSE-EDIT-SET-FREQUENCY': {
            return {...state, editPage: {...state.editPage, frequency: action.frequency}}
        }
        case 'DIGITAL-PULSE-EDIT-SET-INFO-FOR-PULSE': {
            return {...state, editPage: {...state.editPage, ...action.data}}
        }
        case 'DIGITAL-PULSE-EDIT-SET-CHECKIN-NAME': {
            return {...state, editPage: {...state.editPage, checkinName: action.name}}
        }
        case 'DIGITAL-PULSE-EDIT-SET-TIME': {
            return {...state, editPage: {...state.editPage, time: action.time}}
        }
        case 'DIGITAL-PULSE-EDIT-SET-START-DATE': {
            return {...state, editPage: {...state.editPage, startDate: action.date}}
        }
        case 'DIGITAL-PULSE-EDIT-SET-END-DATE': {
            return {...state, editPage: {...state.editPage, endDate: action.date}}
        }
        case 'DIGITAL-PULSE-EDIT-CHOOSE-TEAM': {
            return {...state, editPage: {...state.editPage, chosenTeams: [...action.teams]}}
        }
        case 'DIGITAL-PULSE-EDIT-CHOOSE-DEPARTMENT': {
            return {...state, editPage: {...state.editPage, chosenDepartments: [...action.departments]}}
        }
        case 'DIGITAL-PULSE-EDIT-SET-FILTERED-TEAMS': {
            return {...state, editPage: {...state.editPage, filteredTeams: [...action.teams]}}
        }
        case 'DIGITAL-PULSE-EDIT-CHANGE-USER-QUESTION-TYPE': {
            let questionsList = state.editPage.userQuestions.map(q => q.id === action.questionId ? {
                ...q,
                type: {...action.questionType}
            } : q);
//здесь происходит зачистка опций если переключился с двоечки на другой тип
            return {
                ...state, editPage: {
                    ...state.editPage, userQuestions: questionsList.map(q => {
                        if (q.id === action.questionId && q.type?.id === 2 && !q.options.length) {
                            return {...q, options: [...action.options]}
                        } else if (q.id === action.questionId && action.questionType?.id !== 2 && q.options.length) {
                            return {...q, options: []}
                        } else {
                            return q
                        }
                    })
                }
            }
        }
        case 'DIGITAL-PULSE-EDIT-ADD-NEW-QUESTION': {
            if (state.editPage.userQuestions.length > 4) {
                return {...state};
            }
            let newQuestion = {
                id: v1(),
                value: '',
                type: null,
                options: [],
                editMode: false
            }
            let newQuestionsList = [...state.editPage.userQuestions];
            newQuestionsList.splice(action.index + 1, 0, newQuestion);
            return {...state, editPage: {...state.editPage, userQuestions: [...newQuestionsList]}}
        }
        case 'DIGITAL-PULSE-EDIT-REMOVE-QUESTION': {
            if (state.editPage.userQuestions.length === 1) {
                return {...state};
            }
            return {
                ...state,
                editPage: {
                    ...state.editPage,
                    userQuestions: state.editPage.userQuestions.filter(q => q.id !== action.questionId)
                }
            }
        }
        case 'DIGITAL-PULSE-EDIT-CHANGE-QUESTION-TEXT': {
            return {
                ...state,
                editPage: {
                    ...state.editPage,
                    userQuestions: state.editPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        value: action.text
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-EDIT-CHANGE-OPTION-IN-MULTIPLE-QUESTION': {
            return {
                ...state,
                editPage: {
                    ...state.editPage,
                    userQuestions: state.editPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        options: q.options.map(o => o.id === action.option.id ? {...action.option} : o)
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-EDIT-DELETE-OPTION-IN-MULTIPLE-QUESTION': {
            return {
                ...state,
                editPage: {
                    ...state.editPage,
                    userQuestions: state.editPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        options: q.options.filter(o => o.id !== action.optionId)
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-EDIT-ADD-NEW-OPTION-IN-MULTIPLE-QUESTION': {
            let newOption = {id: v1(), value: `new option`, editMode: false};
            return {
                ...state,
                editPage: {
                    ...state.editPage,
                    userQuestions: state.editPage.userQuestions.map(q => q.id === action.questionId ? {
                        ...q,
                        options: [...q.options, newOption]
                    } : q)
                }
            }
        }
        case 'DIGITAL-PULSE-RESULT-SET-PULSE-AND-CREATORS-LIST': {
            return {
                ...state,
                resultPage: {...state.resultPage, pulseList: [...action.pulseList], creators: [...action.creators]}
            }
        }
        case 'DIGITAL-PULSE-RESULT-SET-INFO-FOR-CERTAIN-PULSE': {
            return {
                ...state,
                resultPage: {
                    ...state.resultPage,
                    chosenPulse: {...action.data.chosenPulse},
                    teams: [...action.data.teams],
                    chosenTeam: {...action.data.chosenTeam}
                }
            }
        }
        case 'DIGITAL-PULSE-RESULT-SET-CHOSEN-TEAM': {
            return {...state, resultPage: {...state.resultPage, chosenTeam: {...action.chosenTeam}}}
        }
        case 'DIGITAL-PULSE-RESULT-SET-ANSWERS-OF-CERTAIN-PULSE-CERTAIN-TEAM': {
            return {
                ...state,
                resultPage: {
                    ...state.resultPage,
                    questions: [...action.data.questions],
                    responsesCount: action.data.responsesCount,
                    membersCount: action.data.membersCount
                }
            }
        }
        case 'DIGITAL-PULSE-RESULT-CLEAR-STATE': {
            return {...state, resultPage: {...state.resultPage, ...action.data}}
        }
        case 'DIGITAL-PULSE-READY-GET-PULSES': {
            return {...state, readyPage: {...state.readyPage, pulses: [...action.pulses]}}
        }
        case 'DIGITAL-PULSE-READY-SET-CHOSEN-PULSE': {
            return {...state, readyPage: {...state.readyPage, chosenPulse: {...action.pulse}}}
        }
        case 'DIGITAL-PULSE-READY-SET-QUESTIONS-OF-CERTAIN-PULSE': {
            return {...state, readyPage: {...state.readyPage, questions: [...action.questions]}}
        }
        case 'DIGITAL-PULSE-READY-SET-CHOSEN-QUESTIONS-OF-CERTAIN-PULSE': {
            return {...state, readyPage: {...state.readyPage, chosenQuestions: [...action.questions]}}
        }
        case 'DIGITAL-PULSE-READY-CHECK-CERTAIN-QUESTION': {
            return {
                ...state, readyPage: {
                    ...state.readyPage,
                    questions: state.readyPage.questions.map(question => question.id === action.questionId ? {
                        ...question,
                        isChecked: !action.isChecked
                    } : question)
                }
            }
        }
        case 'DIGITAL-PULSE-READY-CHECK-ALL-QUESTIONS': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    questions: state.readyPage.questions.map(question => question ? {
                        ...question,
                        isChecked: true
                    } : question)
                }
            }
        }
        case 'DIGITAL-PULSE-READY-UNCHECK-ALL-QUESTIONS': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    questions: state.readyPage.questions.map(question => question ? {
                        ...question,
                        isChecked: false
                    } : question)
                }
            }
        }
        case 'DIGITAL-PULSE-READY-GET-ALL-DEPARTMENTS': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    modalWindowInfo: {...state.readyPage.modalWindowInfo, departments: [...action.departments]}
                }
            }
        }
        case 'DIGITAL-PULSE-READY-GET-CHOSEN-DEPARTMENTS': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    modalWindowInfo: {...state.readyPage.modalWindowInfo, chosenDepartments: [...action.departments]}
                }
            }
        }
        case 'DIGITAL-PULSE-READY-SET-START-DATE': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    modalWindowInfo: {...state.readyPage.modalWindowInfo, startDate: action.data}
                }
            }
        }
        case 'DIGITAL-PULSE-READY-SET-END-DATE': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    modalWindowInfo: {...state.readyPage.modalWindowInfo, endDate: action.data}
                }
            }
        }
        case 'DIGITAL-PULSE-READY-SET-TIME-FROM-SERVER': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    modalWindowInfo: {...state.readyPage.modalWindowInfo, timeFromServer: action.time}
                }
            }
        }
        case 'DIGITAL-PULSE-READY-SET-TIME': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    modalWindowInfo: {...state.readyPage.modalWindowInfo, time: action.time}
                }
            }
        }
        case 'DIGITAL-PULSE-READY-SET-CHECKIN-NAME': {
            return {
                ...state,
                readyPage: {
                    ...state.readyPage,
                    modalWindowInfo: {...state.readyPage.modalWindowInfo, checkinName: action.name}
                }
            }
        }
        case 'DIGITAL-PULSE-QUESTIONNARIE-SET-INFO-FOR-QUESTIONNAIRE': {
            return {...state, questionnaire: {...state.questionnaire, ...action.info}}
        }
        case 'DIGITAL-PULSE-QUESTIONNAIRE-CHOOSE-ANSWER': {
            return {
                ...state,
                questionnaire: {
                    ...state.questionnaire,
                    questions: state.questionnaire.questions.map(question => question.id === action.questionId ? {
                        ...question,
                        answer: action.answer
                    } : question)
                }
            }
        }
        default:
            return state;
    }
}

export type setLoadingDigitalPulseACType = ReturnType<typeof setLoadingDigitalPulseAC>;
export type setErrorNotificationDigitalPulseACType = ReturnType<typeof setErrorNotificationDigitalPulseAC>;

export type setActiveTabDigitalPulseACType = ReturnType<typeof setActiveTabDigitalPulseAC>;
export type setPreviewModeDigitalPulseACType = ReturnType<typeof setPreviewModeDigitalPulseAC>;
export type setCheckinNameDigitalPulseBuildACType = ReturnType<typeof setCheckinNameDigitalPulseBuildAC>;
export type setStartDateDigitalPulseACType = ReturnType<typeof setStartDateDigitalPulseAC>;
export type setEndDateDigitalPulseACType = ReturnType<typeof setEndDateDigitalPulseAC>;
export type setRecurringDigitalPulseBuildACType = ReturnType<typeof setRecurringDigitalPulseBuildAC>;
export type clearStateDigitalPulseBuildPageACType = ReturnType<typeof clearStateDigitalPulseBuildPageAC>;

export type chooseFrequencyDayDigitalPulseBuildACType = ReturnType<typeof chooseFrequencyDayDigitalPulseBuildAC>;
export type setFrequencyDigitalPulseBuildACType = ReturnType<typeof setFrequencyDigitalPulseBuildAC>;
export type setTimeDigitalPulseACType = ReturnType<typeof setTimeDigitalPulseAC>;
export type setTimeFromServerDigitalPulseACType = ReturnType<typeof setTimeFromServerDigitalPulseAC>;
export type setAllTeamsDigitalPulseACType = ReturnType<typeof setAllTeamsDigitalPulseAC>;
export type choseTeamDigitalPulseACType = ReturnType<typeof choseTeamDigitalPulseAC>;
export type setAllDepartmentsDigitalPulseACType = ReturnType<typeof setAllDepartmentsDigitalPulseAC>;
export type choseDepartmentDigitalPulseACType = ReturnType<typeof choseDepartmentDigitalPulseAC>;
export type setFilteredTeamsDigitalPulseACType = ReturnType<typeof setFilteredTeamsDigitalPulseAC>;
export type setQuestionsTypesDigitalPulseACType = ReturnType<typeof setQuestionsTypesDigitalPulseAC>;

export type changeUserQuestionTypeDigitalPulseBuildACType = ReturnType<typeof changeUserQuestionTypeDigitalPulseBuildAC>;
export type addNewQuestionDigitalPulseBuildACType = ReturnType<typeof addNewQuestionDigitalPulseBuildAC>;
export type removeUserQuestionDigitalPulseBuildACType = ReturnType<typeof removeUserQuestionDigitalPulseBuildAC>;
export type changeQuestionTextDigitalPulseBuildACType = ReturnType<typeof changeQuestionTextDigitalPulseBuildAC>;
export type changeOptionInMultipleQuestionDigitalPulseBuildACType = ReturnType<typeof changeOptionInMultipleQuestionDigitalPulseBuildAC>;
export type addNewOptionInMultipleQuestionDigitalPulseACType = ReturnType<typeof addNewOptionInMultipleQuestionDigitalPulseAC>;
export type deleteOptionInMultipleQuestionDigitalPulseACType = ReturnType<typeof deleteOptionInMultipleQuestionDigitalPulseAC>;

export type editActivePulseManagePageDigitalPulseACType = ReturnType<typeof editActivePulseManagePageDigitalPulseAC>
export type setPulseListAndCreatorsManagePageDigitalPulseACType = ReturnType<typeof setPulseListAndCreatorsManagePageDigitalPulseAC>;
export type makePulseAsActiveManagePageDigitalPulseACType = ReturnType<typeof makePulseAsActiveManagePageDigitalPulseAC>;
export type deletePulseManageDigitalPulseACType = ReturnType<typeof deletePulseManageDigitalPulseAC>;
export type deactivePulseManageDigitalPulseACType = ReturnType<typeof deactivePulseManageDigitalPulseAC>;
export type nudgePulseManageDigitalPulseACType = ReturnType<typeof nudgePulseManageDigitalPulseAC>;
export type setInfoForViewSurveyManageDigitalPulseACType = ReturnType<typeof setInfoForViewSurveyManageDigitalPulseAC>;
export type changeOptionInMultipleQuestionDigitalPulseManagedACType = ReturnType<typeof changeOptionInMultipleQuestionDigitalPulseManagedAC>;
export type changeQuestionTextDigitalPulseManagedACType = ReturnType<typeof changeQuestionTextDigitalPulseManagedAC>;

export type setFilteredDateManageDigitalPulseACType = ReturnType<typeof setFilteredDateManageDigitalPulseAC>;
export type setFilteredStatusManageDigitalPulseACType = ReturnType<typeof setFilteredStatusManageDigitalPulseAC>;
export type setFilteredCreatorDigitalPulseACType = ReturnType<typeof setFilteredCreatorDigitalPulseAC>;
export type resetAllFiltersDigitalPulseACType = ReturnType<typeof resetAllFiltersDigitalPulseAC>;

export type setRecurringDigitalPulseEditACType = ReturnType<typeof setRecurringDigitalPulseEditAC>;
export type chooseFrequencyDayDigitalPulseEditACType = ReturnType<typeof chooseFrequencyDayDigitalPulseEditAC>;
export type setFrequencyDigitalPulseEditACType = ReturnType<typeof setFrequencyDigitalPulseEditAC>;
export type setInfoForPulseOnEditDigitalPulseACType = ReturnType<typeof setInfoForPulseOnEditDigitalPulseAC>;
export type setCheckinNameDigitalPulseEditACType = ReturnType<typeof setCheckinNameDigitalPulseEditAC>;
export type setTimeDigitalPulseEditACType = ReturnType<typeof setTimeDigitalPulseEditAC>;
export type setStartDateDigitalPulseEditACType = ReturnType<typeof setStartDateDigitalPulseEditAC>;
export type setEndDateDigitalPulseEditACType = ReturnType<typeof setEndDateDigitalPulseEditAC>;
export type choseTeamDigitalPulseEditACType = ReturnType<typeof choseTeamDigitalPulseEditAC>;
export type choseDepartmentDigitalPulseEditACType = ReturnType<typeof choseDepartmentDigitalPulseEditAC>;
export type setFilteredTeamsDigitalPulseEditACType = ReturnType<typeof setFilteredTeamsDigitalPulseEditAC>;
export type changeUserQuestionTypeDigitalPulseEditACType = ReturnType<typeof changeUserQuestionTypeDigitalPulseEditAC>;
export type addNewQuestionDigitalPulseEditACType = ReturnType<typeof addNewQuestionDigitalPulseEditAC>;
export type removeUserQuestionDigitalPulseEditACType = ReturnType<typeof removeUserQuestionDigitalPulseEditAC>;
export type changeQuestionTextDigitalPulseEditACType = ReturnType<typeof changeQuestionTextDigitalPulseEditAC>;
export type changeOptionInMultipleQuestionDigitalPulseEditACType = ReturnType<typeof changeOptionInMultipleQuestionDigitalPulseEditAC>;
export type deleteOptionInMultipleQuestionDigitalPulseEditACType = ReturnType<typeof deleteOptionInMultipleQuestionDigitalPulseEditAC>;
export type addNewOptionInMultipleQuestionDigitalPulseEditACType = ReturnType<typeof addNewOptionInMultipleQuestionDigitalPulseEditAC>;

export type setPulseListAndCreatorsResultPageDigitalPulseACType = ReturnType<typeof setPulseListAndCreatorsResultPageDigitalPulseAC>;
export type setInfoForCertainPulseDigitalPulseResultACType = ReturnType<typeof setInfoForCertainPulseDigitalPulseResultAC>;
export type setChosenTeamDigitalPulseResultACType = ReturnType<typeof setChosenTeamDigitalPulseResultAC>;
export type setAnswersOfCertainPulseCertainTeamACType = ReturnType<typeof setAnswersOfCertainPulseCertainTeamAC>;
export type clearStateDigitalPulseCertainResultACType = ReturnType<typeof clearStateDigitalPulseCertainResultAC>;

export type getPulsesOnReadyToUseSurveysDigitalPulseACType = ReturnType<typeof getPulsesOnReadyToUseSurveysDigitalPulseAC>;
export type setChosePulseOnReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setChosePulseOnReadyToUseSurveysDigitalPulseAC>;
export type setQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC>;
export type setChosenQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setChosenQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC>;
export type checkAllQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseACType = ReturnType<typeof checkAllQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC>;
export type uncheckAllQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseACType = ReturnType<typeof uncheckAllQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC>;
export type checkCertainQuestionOfCertainPulseOnReadyToUseSurveysDigitalPulseACType = ReturnType<typeof checkCertainQuestionOfCertainPulseOnReadyToUseSurveysDigitalPulseAC>;

export type setAllDepartmentsReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setAllDepartmentsReadyToUseSurveysDigitalPulseAC>;
export type setChosenDepartmentsReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setChosenDepartmentsReadyToUseSurveysDigitalPulseAC>;
export type setStartDateReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setStartDateReadyToUseSurveysDigitalPulseAC>;
export type setEndDateReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setEndDateReadyToUseSurveysDigitalPulseAC>;
export type setTimeFromServerDigitalPulseReadyACType = ReturnType<typeof setTimeFromServerDigitalPulseReadyAC>;
export type setTimeReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setTimeReadyToUseSurveysDigitalPulseAC>;
export type setCheckinNameReadyToUseSurveysDigitalPulseACType = ReturnType<typeof setCheckinNameReadyToUseSurveysDigitalPulseAC>;

export type setInfoForQuestionnaireDigitalPulseACType = ReturnType<typeof setInfoForQuestionnaireDigitalPulseAC>;
export type chooseAnswerOnQuestionnaireDigitalPulseACType = ReturnType<typeof chooseAnswerOnQuestionnaireDigitalPulseAC>;

//FIRST RENDER
export const setAllTeamsDigitalPulseAC = (teams: Array<TeamType>) => ({
    type: 'DIGITAL-PULSE-SET-ALL-TEAMS',
    teams
} as const);
export const setAllDepartmentsDigitalPulseAC = (departments: Array<DepartmentType>) => ({
    type: 'DIGITAL-PULSE-SET-ALL-DEPARTMENTS',
    departments
} as const);
export const setQuestionsTypesDigitalPulseAC = (types: Array<QuestionType>) => ({
    type: 'DIGITAL-PULSE-SET-QUESTIONS-TYPES',
    types
} as const);

//COMMON
export const setLoadingDigitalPulseAC = (loading: boolean) => ({type: 'DIGITAL-PULSE-SET-LOADING', loading} as const);

export const setErrorNotificationDigitalPulseAC = (notification: null | string) => ({
    type: 'DIGITAL-PULSE-SET-ERROR-NOTIFICATION',
    notification
} as const);


export const setActiveTabDigitalPulseAC = (tab: ActiveTabType) => ({
    type: 'DIGITAL-PULSE-SET-ACTIVE-TAB',
    tab
} as const);
export const setPreviewModeDigitalPulseAC = (mode: boolean) => ({
    type: 'DIGITAL-PULSE-SET-PREVIEW-MODE',
    mode
} as const);

//BUILD
export const setCheckinNameDigitalPulseBuildAC = (name: string) => ({
    type: 'DIGITAL-PULSE-BUILD-SET-CHECKIN-NAME',
    name
} as const);
export const setStartDateDigitalPulseAC = (date: string) => ({type: 'DIGITAL-PULSE-SET-START-DATE', date} as const);
export const setEndDateDigitalPulseAC = (date: any) => ({type: 'DIGITAL-PULSE-SET-END-DATE', date} as const);
export const setTimeDigitalPulseAC = (time: string) => ({type: 'DIGITAL-PULSE-SET-TIME', time} as const);
export const setTimeFromServerDigitalPulseAC = (time: string | null) => ({
    type: 'DIGITAL-PULSE-SET-TIME-FROM-SERVER',
    time
} as const);
export const setRecurringDigitalPulseBuildAC = (recurring: boolean) => ({
    type: 'DIGITAL-PULSE-BUILD-SET-RECURRING',
    recurring
} as const);

export const chooseFrequencyDayDigitalPulseBuildAC = (dayId: number) => ({
    type: 'DIGITAL-PULSE-BUILD-SET-FREQUENCY-DAY',
    dayId
} as const);
export const setFrequencyDigitalPulseBuildAC = (frequency: null | { label: string, value: number }) => ({
    type: 'DIGITAL-PULSE-BUILD-SET-FREQUENCY',
    frequency
} as const);
export const choseTeamDigitalPulseAC = (teams: Array<TeamType>) => ({
    type: 'DIGITAL-PULSE-CHOOSE-TEAM',
    teams
} as const);
export const choseDepartmentDigitalPulseAC = (departments: Array<DepartmentType>) => ({
    type: 'DIGITAL-PULSE-CHOOSE-DEPARTMENT',
    departments
} as const);
export const setFilteredTeamsDigitalPulseAC = (teams: Array<TeamType>) => ({
    type: 'DIGITAL-PULSE-SET-FILTERED-TEAMS',
    teams
} as const);

export const changeUserQuestionTypeDigitalPulseBuildAC = (questionId: string, questionType: QuestionType, options: Array<OptionType>) => ({
    type: 'DIGITAL-PULSE-BUILD-CHANGE-USER-QUESTION-TYPE',
    questionId,
    questionType,
    options
} as const);
export const addNewQuestionDigitalPulseBuildAC = (index: number) => ({
    type: 'DIGITAL-PULSE-BUILD-ADD-NEW-QUESTION',
    index
} as const);
export const removeUserQuestionDigitalPulseBuildAC = (questionId: string) => ({
    type: 'DIGITAL-PULSE-BUILD-REMOVE-QUESTION',
    questionId
} as const);
export const changeQuestionTextDigitalPulseBuildAC = (questionId: string, text: string) => ({
    type: 'DIGITAL-PULSE-BUILD-CHANGE-QUESTION-TEXT',
    questionId,
    text
} as const);
export const changeOptionInMultipleQuestionDigitalPulseBuildAC = (questionId: string, option: OptionType) => ({
    type: 'DIGITAL-PULSE-BUILD-CHANGE-OPTION-IN-MULTIPLE-QUESTION',
    questionId,
    option
} as const);
export const addNewOptionInMultipleQuestionDigitalPulseAC = (questionId: string) => ({
    type: 'DIGITAL-PULSE-BUILD-ADD-NEW-OPTION-IN-MULTIPLE-QUESTION',
    questionId
} as const);
export const deleteOptionInMultipleQuestionDigitalPulseAC = (questionId: string, optionId: string) => ({
    type: 'DIGITAL-PULSE-BUILD-DELETE-OPTION-IN-MULTIPLE-QUESTION',
    questionId,
    optionId
} as const);

export const clearStateDigitalPulseBuildPageAC = () => ({type: 'DIGITAL-PULSE-BUILD-CLEAR-STATE'} as const);

//MANAGE
export const editActivePulseManagePageDigitalPulseAC = (pulseId: number, body: {
    endDate: string,
    recurringOn: boolean,
    frequency: string | null,
    days: Array<FrequencyDayType>
}) => ({
    type: 'DIGITAL-PULSE-MANAGE-EDIT-ACTIVE-PULSE',
    pulseId,
    body,
} as const)
export const setPulseListAndCreatorsManagePageDigitalPulseAC = (pulseList: Array<any>, creators: Array<{
    value: number,
    label: string
}>) => ({type: 'DIGITAL-PULSE-MANAGE-SET-PULSE-AND-CREATORS-LIST', pulseList, creators} as const);
export const makePulseAsActiveManagePageDigitalPulseAC = (pulseId: number) => ({
    type: 'DIGITAL-PULSE-MANAGE-MAKE-PULSE-ACTIVE',
    pulseId
} as const)
export const deletePulseManageDigitalPulseAC = (id: number) => ({
    type: 'DIGITAL-PULSE-MANAGE-DELETE-PULSE',
    id
} as const);
export const deactivePulseManageDigitalPulseAC = (id: number) => ({
    type: 'DIGITAL-PULSE-MANAGE-DEACTIVE-PULSE',
    id
} as const);
export const nudgePulseManageDigitalPulseAC = (id: number) => ({type: 'DIGITAL-PULSE-MANAGE-NUDGE-PULSE', id} as const);
export const changeOptionInMultipleQuestionDigitalPulseManagedAC = (questionId: string, option: OptionType) => ({
    type: 'DIGITAL-PULSE-MANAGE-CHANGE-OPTION-IN-MULTIPLE-QUESTION',
    questionId,
    option
} as const);
export const changeQuestionTextDigitalPulseManagedAC = (questionId: string, text: string) => ({
    type: 'DIGITAL-PULSE-MANAGE-CHANGE-QUESTION-TEXT',
    questionId,
    text
} as const);

export const setInfoForViewSurveyManageDigitalPulseAC = (teamName: string, companyName: string, questions: Array<UserQuestionType>, teams: Array<TeamTypeFromManage>, chosenPulse: PulseTypeFromManage) =>
    ({
        type: 'DIGITAL-PULSE-MANAGE-SET-INFO-FOR-VIEW-SURVEY',
        teamName,
        teams,
        companyName,
        questions,
        chosenPulse
    } as const);

//MANAGE and Results DATE and FILTERS
export const setFilteredDateManageDigitalPulseAC = (date: string) => ({
    type: 'DIGITAL-PULSE-MANAGE-SET-FILTERED-DATE',
    date
} as const);
export const setFilteredStatusManageDigitalPulseAC = (status: StatusType | string) => ({
    type: 'DIGITAL-PULSE-MANAGE-SET-FILTERED-STATUS',
    status
} as const);
export const setFilteredCreatorDigitalPulseAC = (creator: StatusType | string) => ({
    type: 'DIGITAL-PULSE-MANAGE-SET-FILTERED-CREATOR',
    creator
} as const);
export const resetAllFiltersDigitalPulseAC = () => ({type: 'DIGITAL-PULSE-RESET-FILTERS'} as const);

//EDIT
export const setRecurringDigitalPulseEditAC = (recurring: boolean) => ({
    type: 'DIGITAL-PULSE-EDIT-SET-RECURRING',
    recurring
} as const);

export const chooseFrequencyDayDigitalPulseEditAC = (dayId: number) => ({
    type: 'DIGITAL-PULSE-EDIT-SET-FREQUENCY-DAY',
    dayId
} as const);
export const setFrequencyDigitalPulseEditAC = (frequency: null | { label: string, value: number }) => ({
    type: 'DIGITAL-PULSE-EDIT-SET-FREQUENCY',
    frequency
} as const);
export const setInfoForPulseOnEditDigitalPulseAC = (data: any) => ({
    type: 'DIGITAL-PULSE-EDIT-SET-INFO-FOR-PULSE',
    data
} as const);
export const setCheckinNameDigitalPulseEditAC = (name: string) => ({
    type: 'DIGITAL-PULSE-EDIT-SET-CHECKIN-NAME',
    name
} as const);
export const setTimeDigitalPulseEditAC = (time: string) => ({type: 'DIGITAL-PULSE-EDIT-SET-TIME', time} as const);
export const setStartDateDigitalPulseEditAC = (date: string) => ({
    type: 'DIGITAL-PULSE-EDIT-SET-START-DATE',
    date
} as const);
export const setEndDateDigitalPulseEditAC = (date: any) => ({type: 'DIGITAL-PULSE-EDIT-SET-END-DATE', date} as const);
export const choseTeamDigitalPulseEditAC = (teams: Array<TeamType>) => ({
    type: 'DIGITAL-PULSE-EDIT-CHOOSE-TEAM',
    teams
} as const);
export const choseDepartmentDigitalPulseEditAC = (departments: Array<DepartmentType>) => ({
    type: 'DIGITAL-PULSE-EDIT-CHOOSE-DEPARTMENT',
    departments
} as const);
export const setFilteredTeamsDigitalPulseEditAC = (teams: Array<TeamType>) => ({
    type: 'DIGITAL-PULSE-EDIT-SET-FILTERED-TEAMS',
    teams
} as const);
export const changeUserQuestionTypeDigitalPulseEditAC = (questionId: string, questionType: QuestionType, options: Array<OptionType>) => ({
    type: 'DIGITAL-PULSE-EDIT-CHANGE-USER-QUESTION-TYPE',
    questionId,
    questionType,
    options
} as const);
export const addNewQuestionDigitalPulseEditAC = (index: number) => ({
    type: 'DIGITAL-PULSE-EDIT-ADD-NEW-QUESTION',
    index
} as const);
export const removeUserQuestionDigitalPulseEditAC = (questionId: string) => ({
    type: 'DIGITAL-PULSE-EDIT-REMOVE-QUESTION',
    questionId
} as const);
export const changeQuestionTextDigitalPulseEditAC = (questionId: string, text: string) => ({
    type: 'DIGITAL-PULSE-EDIT-CHANGE-QUESTION-TEXT',
    questionId,
    text
} as const);
export const changeOptionInMultipleQuestionDigitalPulseEditAC = (questionId: string, option: OptionType) => ({
    type: 'DIGITAL-PULSE-EDIT-CHANGE-OPTION-IN-MULTIPLE-QUESTION',
    questionId,
    option
} as const);
export const deleteOptionInMultipleQuestionDigitalPulseEditAC = (questionId: string, optionId: string) => ({
    type: 'DIGITAL-PULSE-EDIT-DELETE-OPTION-IN-MULTIPLE-QUESTION',
    questionId,
    optionId
} as const);
export const addNewOptionInMultipleQuestionDigitalPulseEditAC = (questionId: string) => ({
    type: 'DIGITAL-PULSE-EDIT-ADD-NEW-OPTION-IN-MULTIPLE-QUESTION',
    questionId
} as const);

//RESULT
export const setPulseListAndCreatorsResultPageDigitalPulseAC = (pulseList: Array<PulseType>, creators: Array<{
    value: number,
    label: string
}>) => ({type: 'DIGITAL-PULSE-RESULT-SET-PULSE-AND-CREATORS-LIST', pulseList, creators} as const);
export const setInfoForCertainPulseDigitalPulseResultAC = (data: {
    chosenPulse: PulseType,
    teams: Array<{ id: number, name: string }>,
    chosenTeam: { id: number, name: string }
}) => ({type: 'DIGITAL-PULSE-RESULT-SET-INFO-FOR-CERTAIN-PULSE', data} as const);
export const setChosenTeamDigitalPulseResultAC = (chosenTeam: {
    id: number,
    name: string
}) => ({type: 'DIGITAL-PULSE-RESULT-SET-CHOSEN-TEAM', chosenTeam} as const);
export const setAnswersOfCertainPulseCertainTeamAC = (data: {
    questions: any,
    responsesCount: number,
    membersCount: number
}) => ({type: 'DIGITAL-PULSE-RESULT-SET-ANSWERS-OF-CERTAIN-PULSE-CERTAIN-TEAM', data} as const);
export const clearStateDigitalPulseCertainResultAC = (data: any) => ({
    type: 'DIGITAL-PULSE-RESULT-CLEAR-STATE',
    data
} as const);

//READY
export const getPulsesOnReadyToUseSurveysDigitalPulseAC = (pulses: Array<PulseTypeFromReady>) => ({
    type: 'DIGITAL-PULSE-READY-GET-PULSES',
    pulses
} as const);
export const setChosePulseOnReadyToUseSurveysDigitalPulseAC = (pulse: PulseTypeFromReady) => ({
    type: 'DIGITAL-PULSE-READY-SET-CHOSEN-PULSE',
    pulse
} as const);
export const setQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC = (questions: Array<QuestionFromPulseOnReady>) => ({
    type: 'DIGITAL-PULSE-READY-SET-QUESTIONS-OF-CERTAIN-PULSE',
    questions
} as const);
export const setChosenQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC = (questions: Array<QuestionFromPulseOnReady>) => ({
    type: 'DIGITAL-PULSE-READY-SET-CHOSEN-QUESTIONS-OF-CERTAIN-PULSE',
    questions
} as const);

export const checkCertainQuestionOfCertainPulseOnReadyToUseSurveysDigitalPulseAC = (questionId: number, isChecked: boolean) => ({
    type: 'DIGITAL-PULSE-READY-CHECK-CERTAIN-QUESTION',
    questionId,
    isChecked
} as const);
export const checkAllQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC = () => ({type: 'DIGITAL-PULSE-READY-CHECK-ALL-QUESTIONS'} as const);
export const uncheckAllQuestionsOfCertainPulseOnReadyToUseSurveysDigitalPulseAC = () => ({type: 'DIGITAL-PULSE-READY-UNCHECK-ALL-QUESTIONS'} as const);

export const setAllDepartmentsReadyToUseSurveysDigitalPulseAC = (departments: Array<{
    value: number,
    label: string
}>) => ({type: 'DIGITAL-PULSE-READY-GET-ALL-DEPARTMENTS', departments} as const);
export const setChosenDepartmentsReadyToUseSurveysDigitalPulseAC = (departments: Array<{
    value: number,
    label: string
}> | []) => ({type: 'DIGITAL-PULSE-READY-GET-CHOSEN-DEPARTMENTS', departments} as const);
export const setStartDateReadyToUseSurveysDigitalPulseAC = (data: string) => ({
    type: 'DIGITAL-PULSE-READY-SET-START-DATE',
    data
} as const);
export const setEndDateReadyToUseSurveysDigitalPulseAC = (data: string) => ({
    type: 'DIGITAL-PULSE-READY-SET-END-DATE',
    data
} as const);
export const setTimeFromServerDigitalPulseReadyAC = (time: string | null) => ({
    type: 'DIGITAL-PULSE-READY-SET-TIME-FROM-SERVER',
    time
} as const);
export const setTimeReadyToUseSurveysDigitalPulseAC = (time: string) => ({
    type: 'DIGITAL-PULSE-READY-SET-TIME',
    time
} as const);
export const setCheckinNameReadyToUseSurveysDigitalPulseAC = (name: string) => ({
    type: 'DIGITAL-PULSE-READY-SET-CHECKIN-NAME',
    name
} as const);

//QUESTIONNARIE
export const setInfoForQuestionnaireDigitalPulseAC = (info: {
    teamName: string,
    companyName: string,
    questions: Array<QuestionOnQuestionnaireType>
}) => ({type: 'DIGITAL-PULSE-QUESTIONNARIE-SET-INFO-FOR-QUESTIONNAIRE', info} as const);
export const chooseAnswerOnQuestionnaireDigitalPulseAC = (questionId: number, answer: string) => ({
    type: 'DIGITAL-PULSE-QUESTIONNAIRE-CHOOSE-ANSWER',
    questionId,
    answer
} as const);

export const digitalPulseGetTimeTC = (): AppThunk => async (dispatch) => {
    digitalPulseAPI.getTime().then(res => {
        dispatch(setTimeFromServerDigitalPulseAC(res.data.time));
    }).catch(err => {
        alert('wrong getting time from server digital pulse');
    })
}

export const digitalPulseGetTeamsTC = (): AppThunk => async (dispatch) => {
    digitalPulseAPI.getAllTeams().then(res => {
        let teams = res.data.teams.map((team: TeamFromServerType) => ({
            ...team,
            value: team.id,
            label: `${team.name} (${team.leader})`
        }))
        dispatch(setAllTeamsDigitalPulseAC(teams));
    }).catch(err => {
        alert('wrong getting teams digital pulse');
    })
}

export const digitalPulseGetDepartmentsTC = (): AppThunk => async (dispatch) => {
    digitalPulseAPI.getDepartments().then(res => {
        let departments = res.data.map((dep: { id: number, name: string }) => ({
            ...dep,
            value: dep.id,
            label: dep.name
        }))
        dispatch(setAllDepartmentsDigitalPulseAC(departments));
    }).catch(err => {
        alert('wrong getting departments digital pulse');
    })
}

export const digitalPulseGetQuestionsTypesTC = (): AppThunk => async (dispatch) => {
    digitalPulseAPI.getQuestionTypes().then(res => {
        let types = res.data.types.map((t: any) => ({...t, label: t.name, value: t.id}));
        dispatch(setQuestionsTypesDigitalPulseAC(types));
    }).catch(err => {
        alert('wrong getting questions types digital pulse');
    })
}

export const activatePulseDigitalPulseTC = (pulseId: number,
                                            navigate: (url: string) => void): AppThunk => async (dispatch) => {
    digitalPulseAPI.activatePulse(pulseId).then(res => {
        window.location.reload();
        dispatch(setActiveTabDigitalPulseAC('Manage'));
    }).catch(err => {
        dispatch(setLoadingDigitalPulseAC(false));
        let status = err.response && err.response.data && err.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else if (status === 422) {
            navigate('/pageOops');
        } else {
            dispatch(setErrorNotificationDigitalPulseAC(err.response.data.message))
        }
    })
}

export const createDigitalPulseTC = (data: SendPulseDataType,
                                     navigate: (url: string) => void): AppThunk => async (dispatch) => {
    digitalPulseAPI.createDigitalPulse(data).then(res => {
        let pulseId = res.data.id;
        dispatch(clearStateDigitalPulseBuildPageAC());
        dispatch(setLoadingDigitalPulseAC(false));
        dispatch(activatePulseDigitalPulseTC(pulseId, navigate));
    }).catch(err => {
        dispatch(setLoadingDigitalPulseAC(false));
        dispatch(setErrorNotificationDigitalPulseAC(err.response.data.message))
    })
}

export const createDigitalPulseAsDraftTC = (data: SendPulseDataType): AppThunk => async (dispatch) => {
    digitalPulseAPI.createDigitalPulse(data).then(res => {
        dispatch(clearStateDigitalPulseBuildPageAC());
        dispatch(setLoadingDigitalPulseAC(false));
        dispatch(setActiveTabDigitalPulseAC('Manage'));
        //убрала reload? так как он сразу переключается на модератора после перезагрузки
        // window.location.reload();
    }).catch(err => {
        dispatch(setLoadingDigitalPulseAC(false));
        dispatch(setErrorNotificationDigitalPulseAC(err.response.data.message))
    })
}

//MANAGE
export const editActivePulseOnManagePageTC = (pulseId: number, body: {
                                                  endDate: string,
                                                  recurringOn: boolean,
                                                  frequency: string | null, days: Array<boolean>
                                              },
                                              navigate: (url: string) => void,
                                              setError: (notification: string) => void,
                                              setSuccess: (notification: string) => void,
                                              setLoading:  Dispatch<SetStateAction<boolean>>): AppThunk => async (dispatch) => {
    setLoading(true);
    digitalPulseAPI.editActivePulse(pulseId, body).then(res => {
        dispatch(editActivePulseManagePageDigitalPulseAC(pulseId, res.data));
        setLoading(false);
        setSuccess('the pulse was edited');
    }).catch(error => {
        setLoading(false);
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404')
        } else if (status === 403) {
            setError(error.response.data.message)
        } else {
            navigate('/pageOops');
        }
    })
}

export const activatePulseOnManagePageTC = (pulseId: number,
                                            navigate: (url: string) => void,
                                            setError: (notification: string) => void,
                                            setSuccess: (notification: string) => void,
                                            setLoading:  Dispatch<SetStateAction<boolean>>): AppThunk => async (dispatch) => {
    setLoading(true)
    digitalPulseAPI.activatePulse(pulseId).then(res => {
        dispatch(makePulseAsActiveManagePageDigitalPulseAC(pulseId));
        setSuccess('the pulse is active');
        setLoading(false);
    }).catch(error => {
        setLoading(false);
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404')
        } else if (status === 403) {
            setError(error.response.data.message)
        } else {
            navigate('/pageOops');
        }
    })
}

export const deletePulseOnManagePageTC = (pulseId: number,
                                          navigate: (url: string) => void,
                                          setLoading:  Dispatch<SetStateAction<boolean>>): AppThunk => async (dispatch) => {
   setLoading(true);
    digitalPulseAPI.deletePulse(pulseId).then(res => {
        dispatch(deletePulseManageDigitalPulseAC(pulseId));
        setLoading(false);
    }).catch(error => {
        setLoading(false);
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404')
        } else {
            navigate('/pageOops');
        }
    })
}

export const deactivePulseOnManagePageTC = (pulseId: number,
                                            navigate: (url: string) => void,
                                            setSuccess: (notification: string) => void,
                                            setLoading:  Dispatch<SetStateAction<boolean>>): AppThunk => async (dispatch) => {
   setLoading(true);
    digitalPulseAPI.deactivatePulse(pulseId).then(res => {
        dispatch(deactivePulseManageDigitalPulseAC(pulseId));
        setLoading(false);
        setSuccess('the pulse is deactivated')
    }).catch(error => {
        setLoading(false);
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404')
        } else {
            navigate('/pageOops');
        }
    })
}

export const nudgePulseOnManagePageTC = (pulseId: number,
                                         navigate: (url: string) => void,
                                         setSuccess: (notification: string) => void,
                                         setLoading:  Dispatch<SetStateAction<boolean>>): AppThunk => async (dispatch) => {
    setLoading(true);
    digitalPulseAPI.nudgePulse(pulseId).then(res => {
        setLoading(false);
        setSuccess('Successfully!');
    }).catch(error => {
        setLoading(false);
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404')
        } else {
            navigate('/pageOops');
        }
    })
}

export const getFilteredPulseListOnManagePageTC = (date: string, status: string, navigate: (url: string) => void, creatorId: number | string): AppThunk => async (dispatch) => {
    dispatch(setLoadingDigitalPulseAC(true));
    digitalPulseAPI.getFilteredPulseList(date, status, creatorId).then(res => {
        let pulses = res.data.pulses;
        pulses.forEach((itm: any) => {
            itm.startDate = formatDate(new Date(itm.startDate));
            itm.endDate = itm.endDate ? formatDate(new Date(itm.endDate)) : '';
            itm.createdAt = formatDate(new Date(itm.createdAt));
            itm.days = itm.days.length > 0 ? initialDays.map((i, index) => ({
                ...i,
                active: itm.days[index]
            })) : initialDays;
            itm.frequency = initialFrequencies.filter(f => f.label === itm.frequency)[0] || null;
        })
        let creators = res.data.creators;
        dispatch(setLoadingDigitalPulseAC(false));
        dispatch(setPulseListAndCreatorsManagePageDigitalPulseAC(pulses, creators));
    }).catch(error => {
        dispatch(setLoadingDigitalPulseAC(false));
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else {
            navigate('/pageOops');
        }
    })
}

export const getPulseOnManagePageTC = (pulseId: number, navigate: (url: string) => void): AppThunk => async (dispatch) => {
    digitalPulseAPI.getPulse(pulseId).then(res => {
        let companyName = res.data.companyName;
        let pulse = res.data.pulse;
        let teamName = res.data.teams.length ? res.data.teams[0].name : '';
        let teams = res.data.teams;
        let questions = res.data.questions.map((question: QuestionTypeFromManage) => {
            return {
                id: question.id,
                value: question.text,
                type: {
                    createdAt: '',
                    id: question.typeId,
                    name: question.typeName,
                    updatedAt: '',
                    value: question.typeId,
                    label: question.typeName
                },
                options: question.pool?.map((pool) => {
                    return {id: v1(), value: pool, editMode: false}
                }) || [],
                questionEditMode: false
            }
        })
        dispatch(setInfoForViewSurveyManageDigitalPulseAC(teamName, companyName, questions, teams, pulse))
        dispatch(setPreviewModeDigitalPulseAC(true));
    }).catch(error => {
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else {
            navigate('/pageOops');
        }
    })
}

export const updatePulseDigitalPulseTC = (pulseId: number, data: DataForUpdateType, navigate: (url: string) => void): AppThunk => async (dispatch) => {
    digitalPulseAPI.updateDigitalPulse(pulseId, data).then(res => {
        navigate('/digitalPulse')
    }).catch(error => {
        dispatch(setErrorNotificationDigitalPulseAC(error.response.data.message))
    })
}


export const getPulseOnEditPageTC = (pulseId: number, navigate: (url: string) => void): AppThunk => async (dispatch) => {
    dispatch(setLoadingDigitalPulseAC(true));
    digitalPulseAPI.getPulse(pulseId).then(res => {
        let pulse = res.data.pulse;
        let teams = res.data.teams;
        let questions = res.data.questions.map((question: QuestionTypeFromManage) => {
            return {
                id: question.id,
                value: question.text,
                type: {
                    createdAt: '',
                    id: question.typeId,
                    name: question.typeName,
                    updatedAt: '',
                    value: question.typeId,
                    label: question.typeName,
                },
                options: question.pool?.map((pool) => {
                    return {id: v1(), value: pool, editMode: false}
                }) || [],
                questionEditMode: false
            }
        })

        const userDays = pulse.days.length > 0 ? initialDays.map((i, index) => ({
            ...i,
            active: pulse.days[index]
        })) : initialDays;

        const data = {
            chosenPulse: {...pulse},
            checkinName: pulse.name,
            startDate: formatDate(new Date(pulse.startDate)),
            endDate: pulse.endDate ? formatDate(new Date(pulse.endDate)) : '',
            chosenTeams: teams.map((team: TeamFromServerType) => ({
                ...team,
                value: team.id,
                label: `${team.name} (${team.leader})`
            })),
            time: pulse.time,
            userQuestions: [...questions],
            days: userDays,
            recurringOn: pulse.recurringOn,
            frequency: initialFrequencies.filter(f => f.label === pulse.frequency)[0] || null,
        }
        dispatch(setInfoForPulseOnEditDigitalPulseAC(data));
        dispatch(setLoadingDigitalPulseAC(false));
    }).catch(error => {
        dispatch(setLoadingDigitalPulseAC(false));
        let status = error.response && error.response.data && error.response.data.status;

        if (status === 500 || status === 404) {
            navigate('/404')
        } else {
            navigate('/pageOops');
        }
    })
}

//RESULTS
export const getPulseListOnResultPageTC = (navigate: (url: string) => void): AppThunk => async (dispatch) => {
    dispatch(setLoadingDigitalPulseAC(true));
    digitalPulseAPI.getPulseList().then(res => {
        let pulses = res.data.pulses;
        pulses.forEach((itm: any) => {
            itm.startDate = formatDate(new Date(itm.startDate));
            itm.endDate = itm.endDate ? formatDate(new Date(itm.endDate)) : '';
            itm.createdAt = formatDate(new Date(itm.createdAt));
        })
        let newPulses = pulses.filter((pulse: PulseType) => pulse.status !== 'In Draft');
        let creators = res.data.creators;
        dispatch(setLoadingDigitalPulseAC(false));
        dispatch(setPulseListAndCreatorsResultPageDigitalPulseAC(newPulses, creators));
    }).catch(error => {
        dispatch(setLoadingDigitalPulseAC(false));
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else {
            navigate('/pageOops');
        }
    })
}

export const getFilteredPulseListOnResultPageTC = (date: string, status: string, navigate: (url: string) => void, creatorId: string | number): AppThunk => async (dispatch) => {
    digitalPulseAPI.getFilteredPulseList(date, status, creatorId).then(res => {
        let pulses = res.data.pulses;
        pulses.forEach((itm: any) => {
            itm.startDate = formatDate(new Date(itm.startDate));
            itm.endDate = itm.endDate ? formatDate(new Date(itm.endDate)) : '';
            itm.createdAt = formatDate(new Date(itm.createdAt));
            itm.days = itm.days.length > 0 ? initialDays.map((i, index) => ({
                ...i,
                active: itm.days[index]
            })) : initialDays;
            itm.frequency = initialFrequencies.filter(f => f.label === itm.frequency)[0] || null;
        })
        let newPulses = pulses.filter((pulse: PulseType) => pulse.status !== 'In Draft');
        let creators = res.data.creators;
        dispatch(setPulseListAndCreatorsResultPageDigitalPulseAC(newPulses, creators));
    }).catch(error => {
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else {
            navigate('/pageOops');
        }
    })
}

export const getInfoForCertainPulseDigitalPulseResultTC = (pulse: PulseType, openResultMode: () => void): AppThunk => async (dispatch) => {
    dispatch(setLoadingDigitalPulseAC(true));
    digitalPulseAPI.getTeamsForCertainPuls(pulse.id).then((res) => {
        let data = {
            teams: res.data.teamsArr,
            chosenPulse: {...pulse},
            chosenTeam: res.data.teamsArr[0]
        }
        dispatch(setInfoForCertainPulseDigitalPulseResultAC(data));
        openResultMode();
        dispatch(setLoadingDigitalPulseAC(false));
    }).catch(error => {
        dispatch(setLoadingDigitalPulseAC(false));
        alert('something wrong getting teams for certain result')
    })
}

export const getCertainPulseForCertainTeamPulseDigitalPulseResultTC = (pulseId: number,
                                                                       teamId: number, navigate: (url: string) => void): AppThunk => async (dispatch) => {
    digitalPulseAPI.getCertainPulseForCertainTeam(pulseId, teamId).then((res) => {
        let questions = transformTeamAnswers(res.data.questions);
        let data = {
            questions: questions,
            responsesCount: res.data.responsesCount,
            membersCount: res.data.membersCount
        }
        dispatch(setAnswersOfCertainPulseCertainTeamAC(data));
    }).catch(error => {
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else {
            navigate('/pageOops');
        }
    })
}

export const getPulsesForReadyToUseSurveysDigitalPulseTC = (): AppThunk => async (dispatch) => {
    digitalPulseAPI.getPulsesForReadyToUseSurveys().then(res => {
        dispatch(getPulsesOnReadyToUseSurveysDigitalPulseAC(res.data.pulses));
    }).catch(error => {
        alert('something wrong getting pulses on ready page');
    })
}

export const getDepartmentsForReadyToUseSurveyTC = (): AppThunk => async (dispatch) => {
    digitalPulseAPI.getDepartmentsForReadyToUseSurvey().then(res => {
        let departments = res.data;
        dispatch(setAllDepartmentsReadyToUseSurveysDigitalPulseAC(departments));
    }).catch(error => {
        alert('something wrong getting departments ready page');
    })
}

export const digitalPulseGetTimeReadyTC = (): AppThunk => async (dispatch) => {
    digitalPulseAPI.getTime().then(res => {
        dispatch(setTimeFromServerDigitalPulseReadyAC(res.data.time));
    }).catch(err => {
        alert('wrong getting time from server digital pulse');
    })
}

export const createPulseOnReadyToUseSurveysTC = (answer: any): AppThunk => async (dispatch) => {
    digitalPulseAPI.createPulseOnReadyToUseSurveys(answer).then(res => {
        dispatch(setActiveTabDigitalPulseAC('Manage'));
        dispatch(setPreviewModeDigitalPulseAC(false));
    }).catch(err => {
        alert('wrong creating pulse on ready');
    })
}

export const getInfoForQuestionnaireTC = (token: string,
                                          showError: (errorMode: boolean, notification: string) => void,
                                          navigate: (url: string) => void): AppThunk => async (dispatch) => {
    dispatch(setLoadingDigitalPulseAC(true));
    digitalPulseAPI.getInfoForQuestionnaire(token).then(res => {
        const companyName = res.data.companyName;
        const teamName = res.data.team.name;
        let questionsData = res.data.questions ? res.data.questions.map((itm: any) => {
            return {id: itm.id, text: itm.text, type: itm.typeId, options: itm.pool, answer: ''}
        }) : [];
        questionsData = questionsData.map((q: any) => q.type === 2 ? {
            ...q,
            options: q.options.map((o: string) => o.replace(/  +/g, ' '))
        } : q)

        dispatch(setInfoForQuestionnaireDigitalPulseAC({teamName, companyName, questions: questionsData}))
        dispatch(setLoadingDigitalPulseAC(false));
    }).catch(error => {
        dispatch(setLoadingDigitalPulseAC(false));
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else if (status === 422) {
            showError(true, error.response.data.message);
        } else {
            navigate('/pageOops');
        }
    })
}

export const sendAnswerQuestionnaireTC = (token: string,
                                          answer: QuestionnaireAnswerType,
                                          showError: (errorMode: boolean, notification: string) => void,
                                          navigate: (url: string) => void): AppThunk => async (dispatch) => {
    digitalPulseAPI.sendPulseAnswers(token, answer).then(res => {
        navigate('/dashboard');
    }).catch(error => {
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else if (status === 422) {
            showError(true, error.response.data.message);
        } else {
            navigate('/pageOops');
        }
    })
}





