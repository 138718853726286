import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {RootStateType} from "./store/store";
import {Simulate} from "react-dom/test-utils";

import {useNavigate} from "react-router-dom";
import {btnLinkType} from "../components/pages/companyManagement/companyInformation/buttonLinkInvite/buttonLinkInvite";

export const useHookNavigate = (url: string)=> {
    const navigate = useNavigate();

    return navigate(url);
}

//export default useHookNavigate;

export const useHookSelectorCancelLoading = () => {
    let cancelLoading = useSelector((state: RootStateType) => state.coachingCorner.cancelLoading);
    return cancelLoading
}

export const useHookSelectorLoading = () => {
    let loading = useSelector((state: RootStateType) => state.coachingCorner.loading);
    return loading;
}

//common custom hook for error or success message show
export const useHookErrors = () => {
    const [notification, setNotification] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const hideNotification = () => {
        setNotification('');
        setIsError(false);
        setIsSuccess(false)
    }

    const setError = (notification: string) => {
        setNotification(notification);
        setIsError(true);
    }

    const setSuccess = (notification: string) => {
        setNotification(notification);
        setIsSuccess(true);
    }

    return {notification, isError, isSuccess, hideNotification, setError, setSuccess}
}

//custom hook for centring diagrams from company management / organization chart

export const useHookShowInfoText = () => {
    //для показа инфо-сообщения над кнопками
    const [infoMode, setInfoMode] = useState<boolean>(false);
    const [whoInfoMessage, setWhoInfoMessage] = useState<btnLinkType>(null);

    const showInfoMessage = (e: React.MouseEvent<HTMLDivElement>, who: btnLinkType) => {
        e.stopPropagation();
        if(whoInfoMessage && whoInfoMessage === who) {
            setInfoMode(false);
            setWhoInfoMessage(null);
        } else if(!whoInfoMessage && !infoMode) {
            setInfoMode(true);
            setWhoInfoMessage(who);
        } else if(whoInfoMessage && whoInfoMessage !== who) {
            setWhoInfoMessage(who)
        }
    }

    return {infoMode, whoInfoMessage, showInfoMessage}
}

export const useCenteredTree = (defaultTranslate = {x: 0, y: 0}) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const [dimensions, setDimensions] = useState<any>();
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const {width, height} = containerElem.getBoundingClientRect();
             setDimensions({width, height});
            setTranslate({x: width / 2, y: 10});
        }
    }, []);
    return [dimensions, translate, containerRef];
};
