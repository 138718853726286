import {AllActionType} from "../../types";
import {AppThunk} from "../store/store";
import {profileAPI} from "../../api/api";

export type ProfileActiveTabType =
    'edit'
    | 'membership'
    | 'security'
    | 'assessment'
    | 'values'
    | 'motivators'
    | 'feedback';

export type ValuePageProfileNormType = {
    description: string,
    id: number,
    name: string
}

export type MotivatorsPageType = {
    motivators: Array<{ id: number, name: string }>,
    userPriorities: Array<{ id: number, name: string }>,
    userRatings: Array<{ id: number, name: string }>,
}

export type ValuesPageType = {
    norms: Array<ValuePageProfileNormType>,
    userNorms: Array<number>
}

export type UpdateInfoType = {
    firstName: string,
    lastName: string,
    number: string,
    seniorityId: number,
    timeZone: string
}

export type AssessmentPageType = {
    description: null | string,
    isCompleted: boolean,
    name: null | string
}

export type InfoProfileEditPageType = {
    email: string,
    firstName: string,
    lastName: string,
    number: string,
    seniorities: Array<{ id: number, name: string }>,
    seniorityId: number,
    timeZone: string,
    image: string | null
}

export type ProfileSateType = {
    activeTab: ProfileActiveTabType,
    loading: boolean,
    editPage: InfoProfileEditPageType,
    membershipPage: {
        text: Array<string>
    },
    securityPage: {
        email: string
    },
    assessmentPage: AssessmentPageType,
    valuesPage: ValuesPageType,
    motivatorsPage: MotivatorsPageType,
}

const initialState: ProfileSateType = {
    activeTab: 'edit',
    loading: true,
    editPage: {
        email: '',
        firstName: '',
        lastName: '',
        number: '',
        seniorities: [],
        seniorityId: 0,
        timeZone: '',
        image: null
    },
    membershipPage: {
        text: []
    },
    securityPage: {
        email: ''
    },
    assessmentPage: {
        description: null,
        isCompleted: false,
        name: null
    },
    valuesPage: {
        norms: [],
        userNorms: []
    },
    motivatorsPage: {
        motivators: [],
        userPriorities: [],
        userRatings: [],
    }
}

export const profileReducer = (state = initialState, action: AllActionType) => {
    switch (action.type) {
        case 'PROFILE-SET-ACTIVE-TAB': {
            return {...state, activeTab: action.tab}
        }
        case 'PROFILE-SET-LOADING': {
            return {...state, loading: action.loading}
        }
        case 'PROFILE-EDIT-SET-INFO': {
            return {...state, editPage: {...state.editPage, ...action.info}}
        }
        case 'PROFILE-EDIT-SET-PHOTO': {
            return {...state, editPage: {...state.editPage, image: action.url}}
        }
        case 'PROFILE-EDIT-SET-CHOSEN-SENIORITY': {
            return {...state, editPage: {...state.editPage, seniorityId: action.id}}
        }
        case 'PROFILE-EDIT-SET-TIME-ZONE': {
            return {...state, editPage: {...state.editPage, timeZone: action.zone}}
        }
        case 'PROFILE-EDIT-UPDATE-DATA': {
            return {...state, editPage: {...state.editPage, ...action.answer}}
        }
        case 'PROFILE-SECURITY-GET-EMAIL': {
            return {...state, securityPage: {...state.securityPage, email: action.email}}
        }
        case 'PROFILE-MEMBERSHIP-GET-INFO': {
            return {...state, membershipPage: {...state.membershipPage, text: [...action.text]}}
        }
        case 'PROFILE-ASSESSMENT-GET-INFO': {
            return {...state, assessmentPage: {...state.assessmentPage, ...action.info}}
        }
        case 'PROFILE-VALUES-GET-INFO': {
            return {...state, valuesPage: {...state.valuesPage, ...action.info}}
        }
        case 'PROFILE-VALUES-CHOOSE-VALUE': {
            return {
                ...state, valuesPage: {
                    ...state.valuesPage, userNorms: [...action.values]
                }
            }
        }
        case 'PROFILE-MOTIVATORS-GET-INFO': {
            return {...state, motivatorsPage: {...state.motivatorsPage, ...action.info}}
        }
        case 'PROFILE-MOTIVATORS-CHANGE-USER-RATINGS': {
            return {...state, motivatorsPage: {...state.motivatorsPage, userRatings: [...action.ratings]}}
        }
        case 'PROFILE-MOTIVATORS-CHANGE-USER-PRIORITIES': {
            return {...state, motivatorsPage: {...state.motivatorsPage, userPriorities: [...action.priorities]}}
        }
        default:
            return state;
    }
}

export type setActiveTabProfileACType = ReturnType<typeof setActiveTabProfileAC>;
export type setLoadingProfileACType = ReturnType<typeof setLoadingProfileAC>;
export type setInfoEditPageProfileACType = ReturnType<typeof setInfoEditPageProfileAC>;
export type setPhotoEditPageProfileACType = ReturnType<typeof setPhotoEditPageProfileAC>;
export type setChoseSeniorityEditPageProfileACType = ReturnType<typeof setChoseSeniorityEditPageProfileAC>;
export type setTimeZoneEditPageProfileACType = ReturnType<typeof setTimeZoneEditPageProfileAC>;
export type updateDataEditPageProfileACType = ReturnType<typeof updateDataEditPageProfileAC>;

export type getInfoMembershipPageProfileACType = ReturnType<typeof getInfoMembershipPageProfileAC>;

export type getInfoAssessmentPageProfileACType = ReturnType<typeof getInfoAssessmentPageProfileAC>;
export type getInfoValuesPageProfileACType = ReturnType<typeof getInfoValuesPageProfileAC>;
export type choseValuePageValuesProfileACType = ReturnType<typeof choseValuePageValuesProfileAC>;

export type getInfoMotivatorsPageProfileACType = ReturnType<typeof getInfoMotivatorsPageProfileAC>;
export type changeUserPrioritiesMotivatorsPageProfileACType = ReturnType<typeof changeUserPrioritiesMotivatorsPageProfileAC>;
export type changeUserRatingsMotivatorsPageProfileACType = ReturnType<typeof changeUserRatingsMotivatorsPageProfileAC>;

export type getEmailSecurityPageProfileACType = ReturnType<typeof getEmailSecurityPageProfileAC>;

export const setActiveTabProfileAC = (tab: ProfileActiveTabType) => ({type: 'PROFILE-SET-ACTIVE-TAB', tab} as const);
export const setLoadingProfileAC = (loading: boolean) => ({type: 'PROFILE-SET-LOADING', loading} as const);
export const setInfoEditPageProfileAC = (info: InfoProfileEditPageType) => ({
    type: 'PROFILE-EDIT-SET-INFO',
    info
} as const);
export const setPhotoEditPageProfileAC = (url: any) => ({type: 'PROFILE-EDIT-SET-PHOTO', url} as const);
export const setChoseSeniorityEditPageProfileAC = (id: number) => ({
    type: 'PROFILE-EDIT-SET-CHOSEN-SENIORITY',
    id
} as const);
export const setTimeZoneEditPageProfileAC = (zone: string) => ({type: 'PROFILE-EDIT-SET-TIME-ZONE', zone} as const);
export const updateDataEditPageProfileAC = (answer: UpdateInfoType) => ({
    type: 'PROFILE-EDIT-UPDATE-DATA',
    answer
} as const);

//SECURITY
export const getEmailSecurityPageProfileAC = (email: string) => ({type: 'PROFILE-SECURITY-GET-EMAIL', email} as const)

//MEMBERSHIP
export const getInfoMembershipPageProfileAC = (text: Array<string>) => ({
    type: 'PROFILE-MEMBERSHIP-GET-INFO',
    text
} as const);

//ASSESSMENT
export const getInfoAssessmentPageProfileAC = (info: AssessmentPageType) => ({
    type: 'PROFILE-ASSESSMENT-GET-INFO',
    info
} as const)

//VALUES
export const getInfoValuesPageProfileAC = (info: ValuesPageType) => ({type: 'PROFILE-VALUES-GET-INFO', info} as const);
export const choseValuePageValuesProfileAC = (values: Array<number>) => ({
    type: 'PROFILE-VALUES-CHOOSE-VALUE',
    values
} as const);

//MOTIVATORS
export const getInfoMotivatorsPageProfileAC = (info: MotivatorsPageType) => ({
    type: 'PROFILE-MOTIVATORS-GET-INFO',
    info
} as const);
export const changeUserPrioritiesMotivatorsPageProfileAC = (priorities: Array<{
    id: number,
    name: string
}>) => ({type: 'PROFILE-MOTIVATORS-CHANGE-USER-PRIORITIES', priorities} as const);
export const changeUserRatingsMotivatorsPageProfileAC = (ratings: Array<{
    id: number,
    name: string
}>) => ({type: 'PROFILE-MOTIVATORS-CHANGE-USER-RATINGS', ratings} as const);

export const getProfileInfoEditPageTC = (navigate: (url: string) => void): AppThunk => async (dispatch) => {
    // dispatch(setLoadingProfileAC(true));
    profileAPI.getProfileInfoEditPage().then(res => {

        let info: InfoProfileEditPageType = {
            ...res.data.profile,
            seniorities: [...res.data.seniorities]
        }
        dispatch(setLoadingProfileAC(false));
        dispatch(setInfoEditPageProfileAC(info));
    }).catch(error => {
        dispatch(setLoadingProfileAC(false));
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        }
    })
}

export const removePhotoEditPageProfileTC = (file: File, handler: (mode: boolean, notification: string) => void): AppThunk => async (dispatch) => {
    profileAPI.removePhoto().then(res => {
        dispatch(savePhotoEditPageProfileTC(file, handler))
    }).catch(error => {
        alert('wrong removing photo')
    })
}

export const savePhotoEditPageProfileTC = (photoFile: File, handler: (mode: boolean, notification: string) => void): AppThunk => async (dispatch) => {
    profileAPI.savePhoto(photoFile).then(res => {
        dispatch(setPhotoEditPageProfileAC(res.data.url));
    }).catch(error => {
        console.error(error)
    })
}

export const updateInfoEditPageProfileTC = (answer: UpdateInfoType,
                                            handler: (mode: boolean, notification: string) => void,
                                            navigate: (url: string) => void): AppThunk => {
    return async (dispatch) => {
        profileAPI.updateInfo(answer).then(res => {
            handler(false, '');
            dispatch(updateDataEditPageProfileAC(answer));
            navigate('/dashboard');
        }).catch(error => {
            let status = error.response.data.status;
            if (status === 500 || status === 404) {
                navigate('/404');
            }
            if (status === 422) {
                handler(true, error.response.data.message[0]);
            }
        })
    };
}


export const getInfoMembershipPageProfileTC = (): AppThunk => async (dispatch) => {
    profileAPI.getInfoMembership().then(res => {
        dispatch(getInfoMembershipPageProfileAC(res.data.text));
        dispatch(setLoadingProfileAC(false));
    }).catch(error => {
        dispatch(setLoadingProfileAC(false));
        alert('wrong getting info membership')
    })
}

export const changePasswordSecurityPageProfileTC = (setSuccess: (notification: string) => void,
                                                    setError: (notification: string) => void,
                                                    currentPassword: string,
                                                    newPassword: string,
                                                    navigate: (url: string) => void): AppThunk => async (dispatch) => {
    profileAPI.changePassword(currentPassword, newPassword).then(res => {
        setSuccess("Password changed successfully");
    }).catch(error => {
        let status = error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        }
        if (status === 422 || (status > 400 && status !== 404)) {
            setError(error.response.data.message)
        }
    })
}

export const getInfoAssessmentPageProfileTC = (): AppThunk => async (dispatch) => {
    profileAPI.getInfoAssessmentPage().then(res => {
        dispatch(getInfoAssessmentPageProfileAC(res.data));
        dispatch(setLoadingProfileAC(false));
    }).catch(error => {
        dispatch(setLoadingProfileAC(false));
        alert('wrong getting assessment')
    })
}

export const getInfoValuesPageProfileTC = (): AppThunk => async (dispatch) => {
    profileAPI.getInfoValuesPage().then(res => {
        dispatch(setLoadingProfileAC(false));
        dispatch(getInfoValuesPageProfileAC(res.data));
    }).catch(error => {
        dispatch(setLoadingProfileAC(false));
        alert('wrong getting values')
    })
}

export const saveValuesPageProfileTC = (values: Array<number>): AppThunk => async (dispatch) => {
    profileAPI.saveValuesPage(values).then(res => {

    }).catch(error => {
        alert('wrong saving values on values page')
    })
}

export const getInfoMotivatorsPageProfileTC = (): AppThunk => async (dispatch) => {
    // dispatch(setLoadingProfileAC(true));
    profileAPI.getMotivatorsPage().then(res => {
        const userRatings = res.data.userRatings.map((r: { id: number }) => {
            return res.data.motivators.find((m: { id: number, name: string }) => m.id === r.id);
        });

        const userPriorities = res.data.userPriorities.map((p: { id: number }) => {
            return res.data.motivators.find((m: { id: number }) => m.id === p.id);
        });

        let info = {
            motivators: res.data.motivators,
            userRatings,
            userPriorities
        }
        dispatch(getInfoMotivatorsPageProfileAC(info));
        dispatch(setLoadingProfileAC(false));
    }).catch(error => {
        dispatch(setLoadingProfileAC(false));
        alert('wrong getting motivators on motivators page')
    })
}


export const saveMotivatorsPageProfileTC = (setSuccess: (notification: string) => void,
                                            setError: (notification: string) => void,
                                            ratings: Array<{ id: number }>,
                                            priorities: Array<{ id: number }>,
                                            navigate: (url: string) => void): AppThunk => async (dispatch) => {
    profileAPI.saveMotivatorsPage(ratings, priorities).then(res => {
        setSuccess("Motivators were saved!");
    }).catch(error => {
        let status = error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        }
        if (status === 422 || (status > 400 && status !== 404)) {
            setError(error.response.data.message)
        }
    })
}

export const getEmailSecurityPageProfileTC = (): AppThunk => async (dispatch) => {
    dispatch(setLoadingProfileAC(true));
    profileAPI.getEmailSecurityPage().then(res => {

        dispatch(getEmailSecurityPageProfileAC(res.data.email));
        dispatch(setLoadingProfileAC(false));
    }).catch(error => {
        dispatch(setLoadingProfileAC(false));
        alert('wrong getting email')
    })
}


export const deleteAccountEditPageProfileTC = (navigate: (url: string) => void): AppThunk => async (dispatch) => {
    profileAPI.deleteAccountEditPage().then(res => {
       // localStorage.clear();
        navigate('/signIn');
    }).catch(error => {
        alert('problem with removing account')
    })
}



