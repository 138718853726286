import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {teamSelectionForCheckinsAPI} from "../../api/api";

export type TeamSelectionForCheckinsStateType = {
    loading: boolean,
    teams: Array<any>,
    surveys: Array<any>,
    showModal: boolean,
    message: string,
    errorStatus: null | number,
}

const initialState: TeamSelectionForCheckinsStateType = {
    loading: false,
    teams: [],
    surveys: [],
    showModal: false,
    message: "",
    errorStatus: null,
}

type TeamType = {
    team: { admin: string, id: number, name: string },
    token: string
}

export const teamSelectionForCheckinsReducer = (state = initialState, action: AllActionType): TeamSelectionForCheckinsStateType => {
    switch (action.type) {
        case 'SET-MODAL-WINDOW-MODE-TEAM-SELECTION-FOR-CHECKINS': {
            return {...state, message: action.message, errorStatus: action.errorStatus, showModal: action.showModal}
        }
        case 'SET-LOADING-TEAM-SELECTION-FOR-CHECKINS': {
            return {...state, loading: action.loading}
        }
        case 'SET-INFO-TEAM-SELECTION-FOR-CHECKINS': {
            return {...state, teams: [...action.teams], surveys: [...action.surveys], loading: false}
        }
        default:
            return state
    }
}

export type setModalWindowModeTeamSelectionForCheckinsACType = ReturnType<typeof setModalWindowModeTeamSelectionForCheckinsAC>;
export type setLoadingTeamSelectionForCheckinsACType = ReturnType<typeof setLoadingTeamSelectionForCheckinsAC>;
export type setInfoOnTeamSelectionForCheckinsACType = ReturnType<typeof setInfoOnTeamSelectionForCheckinsAC>;

export const setModalWindowModeTeamSelectionForCheckinsAC = (message: string, errorStatus: number, showModal: boolean) => ({
    type: 'SET-MODAL-WINDOW-MODE-TEAM-SELECTION-FOR-CHECKINS',
    message,
    errorStatus,
    showModal
} as const);
export const setLoadingTeamSelectionForCheckinsAC = (loading: boolean) => ({
    type: 'SET-LOADING-TEAM-SELECTION-FOR-CHECKINS',
    loading
} as const);
export const setInfoOnTeamSelectionForCheckinsAC = (teams: Array<TeamType>, surveys: Array<string>) => ({
    type: 'SET-INFO-TEAM-SELECTION-FOR-CHECKINS',
    teams,
    surveys
} as const);

export const getTeamsTeamSelectionForCheckinsTC = (navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingTeamSelectionForCheckinsAC(true));
    teamSelectionForCheckinsAPI.getTeams().then(res => {
        let surveys = res.data.map((item: TeamType) => item.token);
        dispatch(setInfoOnTeamSelectionForCheckinsAC(res.data, surveys));
    }).catch(error => {
        dispatch(setLoadingTeamSelectionForCheckinsAC(false));
        let status = error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404');
        } else if (status === 422) {
            dispatch(setModalWindowModeTeamSelectionForCheckinsAC(error.response.data.message, 422, true))
        } else {
            navigate('/oops');
        }
    })
}
