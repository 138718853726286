import React, {useEffect, useRef} from "react";
import style from './buttonWithImage.module.scss';
import {LoaderDots} from "@thumbtack/thumbprint-react";

type ButtonWithImagePropsType = {
    name: string,
    img: string,
    width?: number,
    height?: number,
    disabled?: boolean,
    onClickHandler?: () => void,
    cursor?: string,
    margin?: string,
    type?: "button" | "submit" | "reset",
    loading?: boolean,
    form?: string
}

const ButtonWithImage = (props: ButtonWithImagePropsType) => {

    let buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
       props.form && buttonRef.current?.setAttribute('form', props.form);
    }, [])

    return (
        <button ref={buttonRef} className={`${style.button_with_image} ${props.disabled ? style.disabled_button : ''}`}
               // form={props.form ?  `${props.form}` : ''}
                type={props.type ? `${props.type}` : 'button'}
                disabled={props.disabled}
                style={{
                    width: props.width ? `${props.width}px` : '100%',
                    height: props.height ? `${props.height}px` : '45px',
                }}
                onClick={props.onClickHandler}>
            {!props.loading && <div className={style.button_with_image_img}>
                <img src={`${props.img}`} alt={props.name}/>
            </div>}
            <div className={style.button_with_image_value}>
                {props.loading ? <LoaderDots theme="inverse"/> : props.name}
            </div>
        </button>
    )
}

export default ButtonWithImage;