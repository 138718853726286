import React from "react";
import style from './footerWithCopyRight.module.scss';

const FooterWithCopyRight = () => {

    return(
        <div className={style.footer}>
            <p>© {new Date().getFullYear()} unboXt</p>
        </div>
    )
}

export default FooterWithCopyRight;