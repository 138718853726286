import React from "react";
import style from './../../companyChart.module.scss';

type ArrowLabelPropsType = {
    childrenCount: number
}
const ArrowLabel = ({childrenCount}: ArrowLabelPropsType) => {

    return(
        <svg width="100%" height="100" xmlns="http://www.w3.org/2000/svg">
            <line x1="155" y1="0" x2="155" y2="30" stroke="grey" strokeWidth="1"/>
            <g transform="translate(130, 20)"
               fill={'grey'}
               strokeWidth={'1'}
               color={'#F6F8FA'}>
                <polygon
                    points="0,4 50,4 25,40"
                    fill={'#b3b3b6'}
                    stroke="#b3b3b6"
                />
                <text x={childrenCount < 10 ? '17' : '13'} y={'20'} strokeWidth={.5}>+{childrenCount}</text>
            </g>
        </svg>
    )
}

export default ArrowLabel;