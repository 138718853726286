import React, {useCallback, useEffect, useState} from "react";
import {
    activeTabCoachingCornerAC, clearTrendsAndOffsetCoachingCornerAC,
    coachingCornerDislikeTrendTC,
    coachingCornerGetTrendsTC,
    coachingCornerLikeTrendTC, makeTrendSeenCoachingCornerAC,
    setChosenItemInModalWindowCoachingCornerAC,
    setLoadingCoachingCornerAC, setPostFromMotivationChatBoxCoachingCornerAC
} from "../../../../redux/reducers/coachingCornerReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import TrendItem from "../trendItem/trendItem";
import ModalWindowCoachingCorner from "../modalWindow/modalWindowCoachingCorner";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import {ActiveTabCoachingCorner} from "../coachingCorner";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import Tour, {ReactourStep} from "reactour";
import {setTourCompletedTC} from "../../../../redux/reducers/commonReducer";
import {useNavigate} from "react-router-dom";
import {setActiveTabTeamAC} from "../../../../redux/reducers/dashboardReducer";
import {closeTour, getScrollingBehaviourForTour} from "../../../../common";

export type TrendItemType = {
    allowComment: boolean,
    color: string,
    comments: Array<any>,
    daysAgo: string,
    disliked: boolean,
    dislikesCount: number,
    iconClass: string,
    id: number,
    insightBody: string,
    liked: boolean,
    likesCount: number,
    modalHeader: string,
    notificationType: string,
    seen: boolean
}

export type TrendsPropsType = {
    changeActiveTab: (tab: ActiveTabCoachingCorner) => void
}

const Trends = (props: TrendsPropsType) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector((state: RootStateType) => state.coachingCorner);
    const chosenTeamId = state.chosenTeamId;
    const {currentStatus, tourCompleted} = useSelector((state: RootStateType) => state.common);

    const trendsForTour = [{
        allowComment: false,
        color: "good",
        comments: [],
        daysAgo: "a day ago",
        disliked: false,
        dislikesCount: 0,
        iconClass: "004-bar-chart",
        id: 695311,
        insightBody: "Team performance has increased by 18% this month.",
        liked: true,
        likesCount: 1,
        modalHeader: "Performance Trend",
        notificationType: "perfomance_trend",
        seen: false
    }, {
        allowComment: true,
        color: "good",
        comments: [],
        daysAgo: "2 days ago",
        disliked: false,
        dislikesCount: 0,
        iconClass: "127-like",
        id: 585422,
        insightBody: "Your Northwest Region Sales team’s motivation rate for this week is 45% higher than last week. Take a look to see how your team members need support.",
        liked: true,
        likesCount: 1,
        modalHeader: "Motivation Trend",
        notificationType: "motivation_trend",
        seen: false
    },];
    const trends = tourCompleted ? state.trends : trendsForTour;
    const like = (trendId: number) => {
        dispatch(coachingCornerLikeTrendTC(trendId));
    }
    const disLike = (trendId: number) => {
        dispatch(coachingCornerDislikeTrendTC(trendId));
    }

    const [modalView, setModalView] = useState<boolean>(false);
    const closeModalWindow = () => {
        setModalView(false);
    }

    const clickTrend = (trend: any) => {
        if (trend.modalHeader === "Motivation Trend") {
            dispatch(setPostFromMotivationChatBoxCoachingCornerAC(true));
        }
        dispatch(makeTrendSeenCoachingCornerAC(trend.id));
        dispatch(setChosenItemInModalWindowCoachingCornerAC(trend));
        setModalView(true);
    }

    const updateData = () => {
        let scroll = window.scrollY;
        dispatch(setLoadingCoachingCornerAC(true));
        dispatch(coachingCornerGetTrendsTC(scroll));
    }

    const scrollWatch = useCallback(() => {
        let scroll = window.scrollY;

        if (scroll === document.body.scrollHeight - document.documentElement.clientHeight) {

            if (!state.loading && !state.cancelLoading) {
                updateData();
            }
        }
    }, [state.loading, state.cancelLoading])

    const stepsModerator: Array<ReactourStep> = [];
    const stepsLeader: Array<ReactourStep> = [
        {
            selector: '',
            content: () => (
                <div className="hint-body"></div>
            ),
            action: () => {
                dispatch(activeTabCoachingCornerAC('insights'))
            }
        },
        {
            selector: ".trends_tab_leader",
            position: 'right',
            content: () => (
                <div className="hint_body">
                    As a team leader, you will see trends that show which direction your team is trending from a
                    performance and motivation standpoint.
                </div>
            ),
        },
        {
            content: "",
            action: () => {
                dispatch(activeTabCoachingCornerAC('motivation'))
            }
        },
    ];

    const stepsMember: Array<ReactourStep> = [
        {
            selector: '',
            content: () => (
                <div className="hint-body"></div>
            ),
            action: () => {
                dispatch(activeTabCoachingCornerAC('insights'))
            }
        },
        {
            selector: ".trends_tab_member",
            position: 'right',
            content: () => (
                <div className="hint_body">
                    Trends are sent directly to you to show your positive, neutral, or negative changes in performance
                    and motivation.
                </div>
            ),
        },
        {
            content: "",
            action: () => {
                dispatch(activeTabCoachingCornerAC('motivation'))
            }
        },
    ];

   // const disableBody = (target: any) => disableBodyScroll(target);
  //  const enableBody = (target: any) => enableBodyScroll(target);

    const steps = currentStatus === 'isModerator' ? stepsModerator
        : currentStatus === 'isAdmin' ? stepsLeader : currentStatus === 'isMember' ? stepsMember : [];

    useEffect(() => {
        dispatch(clearTrendsAndOffsetCoachingCornerAC());
        chosenTeamId && updateData();
    }, [chosenTeamId])

    useEffect(() => {
        window.addEventListener('scroll', scrollWatch);

        return (() => {
            window.removeEventListener('scroll', scrollWatch);
        })

    }, [scrollWatch])

    useEffect(() => {
        getScrollingBehaviourForTour(tourCompleted)
    }, [tourCompleted])

    return (
        <div>
            {steps.length ? <Tour steps={steps}
                                  isOpen={!tourCompleted}
                                  onRequestClose={() => closeTour(currentStatus, navigate, dispatch)}
                                  closeWithMask={false}
                                  showNumber={false}
                                  nextButton={<span>NEXT</span>}
                                  prevButton={<span>PREV</span>}
                                  showNavigationNumber={false}
                                  disableDotsNavigation={true}
                                  disableInteraction={true}
                                  //onAfterOpen={disableBody}
                                 // onBeforeClose={enableBody}
                                  startAt={1}/> : null}
            {modalView &&
                <ModalWindowCoachingCorner closeModalWindow={closeModalWindow}
                                           changeActiveTab={props.changeActiveTab}/>}
            {
                trends.map((trend: TrendItemType) => <TrendItem key={trend.id}
                                                                trend={trend}
                                                                like={() => like(trend.id)}
                                                                dislike={() => disLike(trend.id)}
                                                                onClickItem={() => clickTrend(trend)}/>)
            }
            {state.loading && <LoaderDots/>}
        </div>
    )
}

export default Trends;