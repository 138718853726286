import React, {useState} from "react";
import style from './nodeItem.module.scss';
import add_role from "../../../../../../assets/images/companyManagement/addLeader_black.png";
import {useSelector} from "react-redux";
import {RootStateType} from "../../../../../../redux/store/store";
import link from './../../../../../../assets/images/companyManagement/link.png';

type NodeItemPropsType = {
    nodeData: any,
    isSecondary?: boolean
}

const NodeItem = ({nodeData, isSecondary}: NodeItemPropsType) => {
    const currentStatus = useSelector((state: RootStateType) => state.common.currentStatus);

    const [isBlock, setIsBlock] = useState(false);
    const [hoveredElement, setHoveredElement] = useState<'role' | 'link' | null>(null);

    const showHint = (hoveredElement: 'role' | 'link' | null, isBlock: boolean) => {
        setHoveredElement(hoveredElement);
        setIsBlock(isBlock);
    }

    const bottomTitle = nodeData.attributes?.level === 1 ? nodeData.attributes?.role
        : nodeData.attributes?.level === 2 ? nodeData.attributes?.departmentName
            : nodeData.attributes?.level === 3 ? nodeData.attributes?.teamName
                : nodeData.attributes?.level === 4 ? nodeData.attributes?.name : '';

    const userName = !isSecondary ? nodeData.attributes?.name : nodeData.attributes?.secondaryLeader.name;
    const userEmail = !isSecondary ? nodeData.attributes?.email : nodeData.attributes?.secondaryLeader.email;

    const addRole = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        alert('add new role')
    }

    const copyLink = (e: React.MouseEvent<HTMLDivElement>, link: string | null) => {
        e.stopPropagation();
        if (link) {
            let btn = e.currentTarget;
           // let span = btn.childNodes[1];
            let span = btn.nextSibling;


            navigator.clipboard.writeText(link || '').then(res => {
            })

            let data = btn?.getAttribute("data-tooltipe");
            //@ts-ignore
             span.style.display = "block";
            //@ts-ignore
            span.innerHTML = data;

            setTimeout(() => {
                //@ts-ignore
                span.style.display = "none";
            }, 2000);
        }
    };

    return(
        <div className={style.node_item_wrapper}>
            <div className={style.info_block} style={{backgroundColor: nodeData.attributes?.mainColor, paddingTop: nodeData.attributes?.additionalRolesCount ? '5px' : '35px'}}>

                <div className={style.info_block_icons_wrapper}>
                    {!isSecondary && nodeData.attributes?.additionalRolesCount || isSecondary && nodeData.attributes?.secondaryLeader.additionalRolesCount  ? <div className={style.roles_number}
                         onMouseEnter={() => showHint('role', true)}
                         onMouseLeave={() => showHint(null, false)}>
                        <span>+{!isSecondary ? nodeData.attributes?.additionalRolesCount : nodeData.attributes?.secondaryLeader.additionalRolesCount}</span>
                    </div> : null}
                    {/*{currentStatus === 'isModerator' ? <div className={style.add_icon} onClick={addRole}>*/}
                    {/*    <img src={add_role} alt="add_role"/>*/}
                    {/*</div> : null}*/}
                </div>

                <div style={{display: 'flex'}}>
                    <div className={style.info_block_foto}>
                        {!isSecondary && (nodeData.attributes?.foto ? <img src={nodeData.attributes?.foto} alt="avatar"/> : <span>{nodeData.attributes?.initials}</span>)}

                        {isSecondary && (nodeData.attributes?.secondaryLeader.foto ? <img src={nodeData.attributes?.secondaryLeader.foto} alt="avatar"/> : <span>{nodeData.attributes?.secondaryLeader?.initials}</span>)}
                    </div>
                    <div className={style.info_block_data}>
                        <p className={style.name}>{userName?.length > 15 ? userName.slice(0, 14).concat('...') : userName}</p>
                        <p className={style.role}>{!isSecondary ? nodeData.attributes?.role : nodeData.attributes?.secondaryLeader.role}</p>
                        <p className={style.position}>{!isSecondary ? nodeData.attributes?.workPosition : nodeData.attributes?.secondaryLeader.workPosition}</p>
                        <p className={style.email}>{userEmail?.length > 25 ? userEmail.slice(0, 26).concat('...') : userEmail}</p>
                    </div>
                </div>

                <div className={style.info_block_info_text} style={{display: isBlock && hoveredElement === 'role' ? 'block' : 'none'}}>
                    {hoveredElement === 'role' && `This user has additional roles in the company hierarchy.`}
                </div>

            </div>
            <div className={style.title_block}
                 style={{backgroundColor: nodeData.attributes?.titleColor}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                    <div className={style.title_block_name} style={{fontSize: bottomTitle?.length > 23 ? '16px' : ''}}>{bottomTitle?.length > 32 ? bottomTitle?.slice(0, 28).concat('...') : bottomTitle}</div>
                    {['team', 'department'].includes(nodeData.name) &&
                        <>
                            <div className={`${style.title_block_link} ${style.btn}`} data-tooltipe={'Copied!'}
                                 onClick={(e: React.MouseEvent<HTMLDivElement>) => copyLink(e, nodeData.attributes?.link)}
                                 onMouseEnter={() => showHint('link', true)}
                                 onMouseLeave={() => showHint(null, false)}>
                                <img src={link} alt="link"/>
                                {/*<span></span>*/}
                            </div>
                            <span></span>
                        </>
                    }
                </div>
                <div className={style.title_block_hint} style={{display: isBlock && hoveredElement === 'link' ? 'block' : 'none'}}>
                    {hoveredElement === 'link' && nodeData.name === 'department' ? `Invite Team Leaders via shareable link for department ${nodeData.attributes.departmentName}`
                        : nodeData.name === 'team' ? `This is a shareable link for team ${nodeData.attributes.teamName}`
                            : ''}
                </div>
            </div>
        </div>
    )
}

export default NodeItem;