import React, {useCallback, useEffect, useRef, useState} from "react";
import style from './companyChart.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {
    clearStateOrganizationChartCompanyManagementAC,
    getCompanyDiagramAdminsTC, resetAllFiltersCompanyManagementOrganizationChartAC,
    setChosenDepartmentCompanyManagementOrganizationChartAC,
    setChosenTeamCompanyManagementOrganizationChartAC, setDiagramDataCompanyManagementAC, setLoadingCompanyManagementAC,
    setTimeFrameCompanyManagementAC
} from "../../../../redux/reducers/companyManagementReducer";
import {RootStateType} from "../../../../redux/store/store";
import Tree from "react-d3-tree";
import CustomNodeLabel from "./customNodeLabel/customNodeLabel";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import {useCenteredTree, useHookErrors} from "../../../../redux/hookNavigate";
import SelectWithImage from "../../../parts/selectWithImage/selectWithImage";
import calendar from "../../../../assets/images/common/calendar.png";
import selectDepartment from "../../../../assets/images/common/selectDepartment.png";
import selectTeam from "../../../../assets/images/common/selectTeam.png";
import {setTimeFrameAC} from "../../../../redux/reducers/dashboardReducer";
import {resetAllFiltersDigitalPulseAC} from "../../../../redux/reducers/digitalPulseReducer";
import ButtonWithImage from "../../../parts/buttonWithImage/buttonWithImage";
import share_icon from "../../../../assets/images/common/share.png";
import MessageBox from "../../../parts/messageBox/messageBox";
import {debug} from "util";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Box} from "../dragTest/dragTest";


//функция для формирования массива данных для диаграммы
export const getChildrenForDiagramData = (admins: Array<any>, departments: Array<any>, teams: Array<any>, members: Array<any>, type?: 'notfull'): Array<any> => {

   // debugger
    const memberChildren = members?.map((member: any) => {
        let obj: { name: string, attributes: any, children?: Array<any> } = {
            name: 'member',
            attributes: {...member},
        }
        return obj;
    })

    const teamChildren = teams?.map((team: any) => {
        const members = memberChildren.filter((m: any) => m.attributes.teamId === team.id);
        let obj: { name: string, attributes: any, children?: Array<any> } = {
            name: 'team',
            attributes: {...team},
        }
        if (members.length) {
            obj.children = members;
        }
        return obj;
    })

    const additionalTeamChildren = teamChildren?.filter(t => t.attributes.departamentId === null)

    const departmentChildren = departments?.map((d: any) => {
        let teams = teamChildren.filter((t: any) => t.attributes.departamentId === +d.id);
        let obj: { name: string, attributes: any, children?: Array<any> } = {
            name: 'department',
            attributes: {...d},
        }
        if (teams.length) {
            obj.children = teams
        }
        return obj;
    })

    additionalTeamChildren.length && !type && departmentChildren.push({
        name: 'department',
        attributes: {level: 2, text: 'Teams without department'},
        children: additionalTeamChildren})

    return admins?.map((a: any) => {
        let obj: { name: string, attributes: any, children?: Array<any> } = {
            name: 'admin',
            attributes: {...a},
        }
        if (a.isDirector) {
            // obj.children = departments.length ? [...departmentChildren, ...additionalTeamChildren] : teamChildren;
            obj.children = departments.length ? departmentChildren : teamChildren;
        }
        return obj;
    })
}

type CompanyChartPropsType = {
    chooseAnItemForModalWindow: (action: 'share' | null,
                                 item: null) => void,
}
const CompanyChart = ({chooseAnItemForModalWindow}: CompanyChartPropsType) => {
    const companyState = useSelector((state: RootStateType) => state.companyManagement);
    const loading = companyState.loading;
   // const companyName = companyState.companyInfo.name;
    const {
        admins,
        departments,
        teams,
        members,
        diagramData,
        departmentsFromSelect,
        teamsFromSelect,
        chosenDepartment,
        chosenTeam,
        chosenTimeFrame,
        shareButtonLoader
    } = useSelector((state: RootStateType) => state.companyManagement.companyChart);
    const dispatch = useDispatch();
    const [translate, setTranslate] = useState({x: 0, y: 0});
    const [dimensions, setDimensions] = useState<any>();

    // const [searchValue, setSearchValue] = useState<string>('');
    //
    // const onSearchSmth = useCallback( (e:  React.ChangeEvent<HTMLInputElement>) => {
    //     let value = e.currentTarget.value;
    //     setSearchValue(value);
    //     dispatch(setLoadingCompanyManagementAC(true));
    //     if(value.trim()) {
    //         let searchValue = value.trim().toLowerCase();
    //         //let filteredAdmins = admins.filter((a: any) => a.name.toLowerCase().includes(searchValue))
    //
    //     }
    //     dispatch(setLoadingCompanyManagementAC(false));
    // }, [admins, departments, teams, members])


    // console.log(diagramData)
    const nodeSize = {x: 400, y: 450};
    //  const [dimensions, translate, containerRef] = useCenteredTree();
    const containerRef = useRef<any>(null);


    const timeFrame = [
        {value: '1d', label: 'Yesterday'},
        {value: '1w', label: 'Last 7 days'},
        {value: '1m', label: 'Last 30 days'},
        {value: '6m', label: 'Last 6 months'},
        {value: '1y', label: 'Last year'},
    ];

    const choseTimeFrame = (selectedValues: { value: string, label: string }) => {
        dispatch(setTimeFrameCompanyManagementAC(selectedValues));
        dispatch(setChosenDepartmentCompanyManagementOrganizationChartAC(null));
        dispatch(setChosenTeamCompanyManagementOrganizationChartAC(null))
    }

    const choseDepartments = useCallback((department: { value: string, label: string }) => {

        let director = admins.filter((a: any) => a.isDirector);
        let newDepartments = departments.filter((d: any) => d.id === department.value);
        const adminChildren = getChildrenForDiagramData(director, newDepartments, teams, members, 'notfull') || [];
        let chosenTeamId = chosenTeam?.value;
        if (chosenTeamId) {
            let fullTeamInfo = teams.filter((t: any) => t.id === chosenTeamId)[0];
            +department.value !== fullTeamInfo.departamentId && dispatch(setChosenTeamCompanyManagementOrganizationChartAC(null))
        }

        dispatch(setDiagramDataCompanyManagementAC(adminChildren))
        dispatch(setChosenDepartmentCompanyManagementOrganizationChartAC(department));
    }, [admins, departments, teams, members, chosenTeam])

    const choseTeams = useCallback((team: { value: number, label: string }) => {

        let director = admins.filter((a: any) => a.isDirector);
        let fullTeamInfo = teams.filter((t: any) => t.id === team.value);
        let newDepartments = departments.filter((d: any) => +d.id === fullTeamInfo[0].departamentId);
        let newChosenDepartment = departmentsFromSelect.filter((d: any) => d.value === newDepartments[0]?.id)
        const adminChildren = getChildrenForDiagramData(director, newDepartments, fullTeamInfo, members, 'notfull') || [];
        dispatch(setChosenDepartmentCompanyManagementOrganizationChartAC(newChosenDepartment[0]));
        dispatch(setDiagramDataCompanyManagementAC(adminChildren))
        dispatch(setChosenTeamCompanyManagementOrganizationChartAC(team))
    }, [admins, departments, teams, members])

    const onResetFilters = () => {
        if (chosenTimeFrame || chosenDepartment || chosenTeam) {
            dispatch(resetAllFiltersCompanyManagementOrganizationChartAC());
            !chosenTimeFrame && dispatch(getCompanyDiagramAdminsTC());
        }
    }

    const centerDiagram = useCallback(() => {
        if (containerRef) {
            const {width, height} = containerRef.current.getBoundingClientRect();
            setDimensions({width, height});
            setTranslate({x: width / 2, y: 70});
        }
    }, [])

    //запрос на бэк будет идти только когда юзер меняет тайм фрейм
    useEffect(() => {
        chosenTimeFrame ? dispatch(getCompanyDiagramAdminsTC(chosenTimeFrame.value)) : dispatch(getCompanyDiagramAdminsTC());
    }, [chosenTimeFrame]);

    // useEffect(() => {
    //     return () => {
    //         // dispatch(resetAllFiltersCompanyManagementOrganizationChartAC())
    //         dispatch(clearStateOrganizationChartCompanyManagementAC())
    //     }
    // }, [])

    useEffect(() => {
        centerDiagram();

        return () => {
            // dispatch(resetAllFiltersCompanyManagementOrganizationChartAC())
            dispatch(clearStateOrganizationChartCompanyManagementAC())
        }
    }, [])


    return (
        //@ts-ignore
        <div className={style.organization_chart_wrapper}>
            <div className='filters_block cm_org_diagram' style={{marginBottom: '6rem'}}>
                {
                    <div
                        className={`selection_block_item_wrapper`}>
                        <SelectWithImage name={'Time Frame'}
                                         image={calendar}
                                         isMulti={false}
                                         value={chosenTimeFrame}
                                         options={timeFrame}
                                         handler={choseTimeFrame}
                                         imageWidth={3.4375}
                                         imageHeight={4.0625}
                                         loading={loading}
                        />
                    </div>
                }

                {
                    <div
                        className={`selection_block_item_wrapper`}>
                        <SelectWithImage name={'Department'}
                                         placeholder={'Select Department'}
                                         imageHeight={3.125}
                                         imageWidth={3.125}
                                         value={chosenDepartment}
                                         options={departmentsFromSelect}
                            // isMulti={true}
                                         handler={(selectedValues) => choseDepartments(selectedValues)}
                                         image={selectDepartment}
                                         loading={loading}
                        />
                    </div>
                }

                {
                    <div className={'selection_block_item_wrapper'}>
                        <SelectWithImage name={'Team'}
                                         placeholder={'Select Team'}
                                         image={selectTeam}
                                         imageWidth={4.0625}
                                         imageHeight={3.125}
                                         value={chosenTeam}
                                         options={teamsFromSelect}
                            // isMulti={true}
                                         handler={(selectedValues) => choseTeams(selectedValues)}
                                         loading={loading}
                        />
                    </div>
                }

                <div className={style.reset_filters_button} onClick={onResetFilters}>Reset filters</div>

            </div>

            {/*<div className={style.draggble_zone} id={'drag_area'} >*/}
            {/*    <div id={'draggableElement'} className={style.draggble_zone_element} draggable={true}></div>*/}
            {/*</div>*/}


            {/*<DndProvider backend={HTML5Backend}>*/}
            {/*    <div style={{border: '1px solid red'}}>*/}
            {/*        <Box name={'nata'}/>*/}
            {/*    </div>*/}
            {/*</DndProvider>*/}

            {/*<div className={style.search_wrapper}>*/}
            {/*    <input type="text"*/}
            {/*           value={searchValue}*/}
            {/*           placeholder={'search'} onChange={onSearchSmth}/>*/}
            {/*</div>*/}

            {/*<div className={'reset_filters_button'} onClick={onResetFilters}>Reset filters</div>*/}

            {loading ? <LoaderDots/> :

                <div className={`${style.chart_wrapper} border_1`} id={'canvas_test'} ref={containerRef}>


                    <div className={style.reports_buttons_wrapper}>
                        <ButtonWithImage name={'Share Chart'}
                                         img={share_icon}
                                         width={183}
                                         onClickHandler={() => chooseAnItemForModalWindow('share', null)}
                                         disabled={shareButtonLoader}
                                         loading={shareButtonLoader}
                        />
                    </div>
                    <Tree data={{
                        name: admins[0]?.companyName || '',
                        children: [...diagramData]
                    }}
                          dimensions={dimensions}
                          pathFunc={'step'}
                        // draggable={false}
                        //@ts-ignore
                          translate={translate}
                          orientation="vertical"
                        //  collapsible={true}
                          initialDepth={2}
                          depthFactor={350}
                          separation={{siblings: 3, nonSiblings: 3}}
                          zoom={0.5}
                        // nodeSize={{x: 300, y: 3}}


                          renderCustomNodeElement={(rd3tProps) => {
                              // debugger
                              return <CustomNodeLabel nodeData={rd3tProps.nodeDatum}
                                                      toggleNode={rd3tProps.toggleNode}
                                                      foreignProps={{
                                                          width: nodeSize.x,
                                                          height: nodeSize.y,
                                                          x: -163,
                                                          y: rd3tProps.nodeDatum.__rd3t.depth === 3 && rd3tProps.nodeDatum?.attributes?.secondaryLeader ? -240 : rd3tProps.nodeDatum.__rd3t.depth === 4 ? -50 : -100
                                                      }}/>
                          }
                              // renderForeignObjectNode(rd3tProps.nodeDatum, { width: nodeSize.x, height: nodeSize.y, x: -125 })
                          }
                    />
                </div>
            }
        </div>

    )
}

export default CompanyChart;