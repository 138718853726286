import React, {useCallback, useEffect, useState} from "react";
import style from './certainTeamView.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import Sidebar from "../../../parts/sidebar/sidebar";
import MiniSidebar from "../../../parts/miniSidebar/miniSidebar";
import ChangeLeaderDepartmentManagementModalWindow
    from "../../departmentManagement/modalWindows/changeLeaderDepartmentManagementModalWindow/changeLeaderDepartmentManagementModalWindow";
import InviteMemberLeaderModalWindow
    from "../../departmentManagement/modalWindows/inviteMemberLeaderModalWindow/inviteMemberLeaderModalWindow";
import Header from "../../../parts/header/header";
import Tab from "../../../parts/tab/tab";
import CertainTeamPageInformation
    from "../../departmentManagement/certainTeamPage/certainTeamPageInformation/certainTeamPageInformation";
import CertainTeamPageCheckinReport
    from "../../departmentManagement/certainTeamPage/certainTeamPageCheckinReport/certainTeamPageCheckinReport";
import FooterWithCopyRight from "../../../parts/footerWithCopyright/footerWithCopyright";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import {
    chooseNewDepartmentLeaderTC,
    setChosenItemForModalWindowCompanyManagementAC
} from "../../../../redux/reducers/companyManagementReducer";
import ModalWindowWrapper from "../../../parts/modalWindowWrapper/modalWindowWrapper";
import ChangeModalWindowBody from "../companyInformation/changeModalWindowBody/changeModalWindowBody";
import InviteModalWindowBody from "../companyInformation/inviteModalWindowBody/inviteModalWindowBody";
import ChooseModalWindowBody from "../companyInformation/chooseModalWindowBody/chooseModalWindowBody";
import {
    addNewSecondaryTeamLeaderTC,
    changeSecondaryTeamLeaderTC,
    chooseNewSecondaryTeamLeaderTC,
} from "../../../../redux/reducers/departmentManagementReducer";
import DeleteModalWindowBody from "../companyInformation/deleteModalWindowBody/deleteModalWindowBody";

const CertainTeamView = () => {
    const dispatch = useDispatch();

    const {loading, chosenTeam, certainTeamPage} = useSelector((state: RootStateType) => state.departmentManagement);
    let secondaryLeaderName = certainTeamPage.certainTeamPageInfo.secondaryLeaderName;
    const {chosenItemForModalWindow, possibleLeaders} = useSelector((state: RootStateType) => state.companyManagement);
    const state = useSelector((state: RootStateType) => state.departmentManagement);
    const chosenTeamId = state.certainTeamPage.certainTeamPageInfo.id;

    //для всех модалок
    const [modalView, setModalView] = useState<boolean>(false);
    const [actionType, setActionType] = useState<'add' | 'change' | 'choose' | 'delete' | null>(null);
    const [itemType, setItemType] = useState<'admin' | 'department' | 'team' | 'member' | null>(null);

    const closeModalWindow = () => {
        setModalView(false);
        setActionType(null);
        setItemType(null);
        dispatch(setChosenItemForModalWindowCompanyManagementAC(null));
    }

    //при нажатии на change или add new должна будет везде вызываться эта функция
    const chooseAnItemForModalWindow = (action: 'add' | 'change' | 'choose' | 'delete' | null,
                                        item: 'admin' | 'department' | 'team' | 'member' | null) => {
        setModalView(true);
        action && setActionType(action);
        item && setItemType(item);
    }

    //открыть модалку по изменению лидера департамента или команды
    let [leaderModalWindowMode, setLeaderModalWindowMode] = useState<boolean>(false);
    const onChangeLeaderModalWindowMode = (mode: boolean) => {
        setLeaderModalWindowMode(mode)
    }

    //открыть модалку по добавлению мембера
    let [memberModalWindowMode, setMemberModalWindowMode] = useState<boolean>(false);
    const onChangeMemberModalWindowMode = (mode: boolean) => {
        setMemberModalWindowMode(mode)
    }

    let [activeTab, changeActiveTab] = React.useState('information');

    const modalWindowTitle = itemType === 'team' && actionType === 'choose' && !secondaryLeaderName ? 'Add Secondary Team Leader'
        : 'team' && actionType === 'choose' && secondaryLeaderName ? 'Change Secondary Team Leader'
            : 'team' && actionType === 'delete' ? 'Delete Secondary Team Leader' : '';

    //это в модалке по choose выбираем нового департмент лидера или тим лидера в
    //зависимости от того, где модалка открывается (от chosenItemForModalWindow)
    //одну из двух сдклай!!!
    // const chooseNewDepartmentLeader = (chosenItemForModalWindowId: number | string,
    //                                    chosenLeaderId: number | string,
    //                                    setError: (notification: string) => void,
    //                                    setSuccess: (notification: string) => void) => {
    //     dispatch(chooseNewDepartmentLeaderTC(chosenItemForModalWindowId, chosenLeaderId, setError, setSuccess, closeModalWindow))
    // }
    //
    // const chooseNewTeamLeader = (chosenItemForModalWindowId: number | string,
    //                              chosenLeaderId: number | string,
    //                              setError: (notification: string) => void,
    //                              setSuccess: (notification: string) => void) => {
    //     dispatch(chooseNewSecondaryTeamLeaderTC(chosenItemForModalWindowId, chosenLeaderId, setError, setSuccess, closeModalWindow))
    // }
    //
    // let actionForChoosingLeader =
    //     itemType === 'team' ? chooseNewTeamLeader : itemType === 'department' ? chooseNewDepartmentLeader
    //         : () => {alert('something wrong choosing leader!')};

    let actionForChoosingLeader = useCallback((chosenItemForModalWindowId: number | string,
                                               setError: (notification: string) => void,
                                               setSuccess: (notification: string) => void,
                                               chosenLeaderId: number | string | null,
                                               newInvitedUserInfo: {
                                                   email: string,
                                                   firstName: string,
                                                   lastName: string
                                               }, infoKeep: {keep: boolean} | null) => {
        //сюда еще санку на change secondary team leader
        //fixies 31102023
        itemType === 'team' && !chosenLeaderId && secondaryLeaderName && infoKeep ? dispatch(changeSecondaryTeamLeaderTC(chosenItemForModalWindowId,
                chosenLeaderId, newInvitedUserInfo, infoKeep, setError, setSuccess, closeModalWindow))
        : itemType === 'team' && !chosenLeaderId && !secondaryLeaderName ? dispatch(addNewSecondaryTeamLeaderTC(chosenItemForModalWindowId,
                chosenLeaderId, newInvitedUserInfo, setError, setSuccess, closeModalWindow, 'departmentManagement'))
       // : itemType === 'team' && chosenLeaderId && secondaryLeaderName && infoKeep ? dispatch(changeSecondaryTeamLeaderTC(chosenItemForModalWindowId,
       //          chosenLeaderId, infoKeep, setError, setSuccess, closeModalWindow, 'departmentManagement'))
            : itemType === 'team' && chosenLeaderId && !secondaryLeaderName ? dispatch(chooseNewSecondaryTeamLeaderTC(chosenItemForModalWindowId,
                    chosenLeaderId, setError, setSuccess, closeModalWindow, 'departmentManagement'))
                : itemType === 'department' && chosenLeaderId ? dispatch(chooseNewDepartmentLeaderTC(chosenItemForModalWindowId,
                        chosenLeaderId, setError, setSuccess, closeModalWindow))
                    : () => {
                        alert('something wrong choosing leader!')
                    };
    }, [itemType, secondaryLeaderName])

    let chosenItemId = itemType === 'department' && chosenItemForModalWindow && ('id' in chosenItemForModalWindow) ? chosenItemForModalWindow.id
        : itemType === 'team' && chosenItemForModalWindow && ('id' in chosenItemForModalWindow) ? chosenItemForModalWindow.id : '';

    return (
        <div className='common_wrapper'>
            <div className="menu_block">
                <Sidebar/>
                <MiniSidebar/>
            </div>

            <div className={`body_block add_position`}>
                {leaderModalWindowMode && <ChangeLeaderDepartmentManagementModalWindow
                    onChangeLeaderModalWindowMode={onChangeLeaderModalWindowMode}/>}
                {memberModalWindowMode &&
                    <InviteMemberLeaderModalWindow onChangeMemberModalWindowMode={onChangeMemberModalWindowMode}/>}

                {modalView && actionType ?
                    <ModalWindowWrapper closeModalWindow={closeModalWindow} headerTitle={modalWindowTitle}>
                        {
                            actionType === 'choose' ? (injectedProps) => (
                                    <ChooseModalWindowBody itemType={itemType}
                                                           actionType={actionType}
                                                           possibleLeaders={possibleLeaders}
                                                           chosenItemForModalWindowId={chosenItemId}
                                                           chooseNewLeader={actionForChoosingLeader}
                                                           closeModalWindow={closeModalWindow}
                                                           secondaryLeaderName={secondaryLeaderName}
                                                           {...injectedProps}/>)
                                : actionType === 'delete' ? (injectedProps) => (
                                        <DeleteModalWindowBody itemType={itemType}
                                                               actionType={actionType}
                                                               chosenItemForModalWindowId={chosenItemId}
                                                               closeModalWindow={closeModalWindow}
                                                               whereFixSecondaryName={'departmentManagement'}
                                                               {...injectedProps}/>)
                                    : (injectedProps) => <></>
                        }
                    </ModalWindowWrapper> : null
                }

                <Header pageName={chosenTeam?.name}/>
                {
                    !loading ?
                        <>
                            <div className={'tabs_block'}>
                                <Tab tabName={'Team Information'} activeTab={activeTab === 'information'}
                                     handler={() => changeActiveTab('information')}/>
                                {/*<Tab tabName={'Check-In Report'} activeTab={activeTab === 'report'}*/}
                                {/*     handler={() => changeActiveTab('report')}/>*/}
                            </div>
                            <div className={'common_pages_block add_padding'}>
                                {activeTab === 'information' &&
                                    <CertainTeamPageInformation
                                        chooseAnItemForModalWindow={chooseAnItemForModalWindow}
                                        onChangeLeaderModalWindowMode={onChangeLeaderModalWindowMode}
                                        onChangeMemberModalWindowMode={onChangeMemberModalWindowMode}/>
                                }
                                {/*{activeTab === 'report' && <CertainTeamPageCheckinReport/>}*/}
                                <FooterWithCopyRight/>
                            </div>
                        </>
                        : <LoaderDots/>}
            </div>
        </div>
    )
}

export default CertainTeamView;