import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {createDepartmentApi} from "../../api/api";
import {addRoleAC, setCurrentStatusAC} from "./commonReducer";

export type ModalWindowCreateDepartmentInfoType = {
    company: {
        id: number,
        name: string
    } | null,
    departments: Array<{ id: number, name: string }> | null
}

type DepartmentType = {
    createdAt: string,
    id: number | null,
    name: string | null,
    perfomingScoreId: number | null,
    updatedAt: string,
    userId: number | null,
}
type PerfomingScoreType = { id: number, name: string };
type CreateDepartmentPageType = {
    perfomingScore: Array<PerfomingScoreType>,
    department: DepartmentType
}

export type CreateDepartmentStateType = {
    loading: boolean,
    modalWindow: ModalWindowCreateDepartmentInfoType,
    createDepartmentPage: CreateDepartmentPageType,
}

const initialState: CreateDepartmentStateType = {
    loading: true,
    modalWindow: {
        company: null,
        departments: null,
    },
    createDepartmentPage: {
        perfomingScore: [],
        department: {
            createdAt: '',
            id: null,
            name: null,
            perfomingScoreId: null,
            updatedAt: '',
            userId: null,
        }
    }
}

export const createDepartmentReducer = (state = initialState, action: AllActionType): CreateDepartmentStateType => {
    switch (action.type) {
        case 'CREATE-DEPARTMENT-SET-LOADING': {
            return {...state, loading: action.loading}
        }
        case 'CREATE-DEPARTMENT-SET-MODAL-WINDOW-INFO': {
            return {...state, modalWindow: {...state.modalWindow, ...action.info}}
        }
        case 'CREATE-DEPARTMENT-SET-DEPARTMENT-NAME': {
            return {
                ...state,
                createDepartmentPage: {
                    ...state.createDepartmentPage,
                    department: {...state.createDepartmentPage.department, name: action.name}
                }
            }
        }
        case 'CREATE-DEPARTMENT-SET-PERFORMING-ID': {
            return {
                ...state,
                createDepartmentPage: {
                    ...state.createDepartmentPage,
                    department: {...state.createDepartmentPage.department, perfomingScoreId: action.id}
                }
            }
        }
        case 'CREATE-DEPARTMENT-SET-INFO-CONTINUE': {
            return {
                ...state, createDepartmentPage: {
                    ...state.createDepartmentPage,
                    perfomingScore: [...action.info.perfomingScore],
                    department: {...action.info.department}
                }
            }
        }
        default:
            return state;
    }
}

export type setLoadingCreateDepartmentACType = ReturnType<typeof setLoadingCreateDepartmentAC>;
export type setInfoModalWindowCreateDepartmentACType = ReturnType<typeof setInfoModalWindowCreateDepartmentAC>;
export type setDepartmentNameDepartmentCreateACType = ReturnType<typeof setDepartmentNameDepartmentCreateAC>;
export type setPerfomingScoreIdDepartmentCreateACType = ReturnType<typeof setPerfomingScoreIdDepartmentCreateAC>;
export type setInfoCreateDepartmentContinueACType = ReturnType<typeof setInfoCreateDepartmentContinueAC>;


export const setLoadingCreateDepartmentAC = (loading: boolean) => ({
    type: 'CREATE-DEPARTMENT-SET-LOADING',
    loading
} as const);
export const setInfoModalWindowCreateDepartmentAC = (info: ModalWindowCreateDepartmentInfoType) => ({
    type: 'CREATE-DEPARTMENT-SET-MODAL-WINDOW-INFO',
    info
} as const);
export const setPerfomingScoreIdDepartmentCreateAC = (id: number) => ({
    type: 'CREATE-DEPARTMENT-SET-PERFORMING-ID',
    id
} as const);
export const setInfoCreateDepartmentContinueAC = (info: CreateDepartmentPageType) => ({
    type: 'CREATE-DEPARTMENT-SET-INFO-CONTINUE',
    info
} as const);
export const setDepartmentNameDepartmentCreateAC = (name: string) => ({
    type: 'CREATE-DEPARTMENT-SET-DEPARTMENT-NAME',
    name
} as const);

export const getInfoModalWindowCreateDepartmentTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCreateDepartmentAC(true));
    createDepartmentApi.getInfoForModalWindow().then(res => {
        dispatch(setLoadingCreateDepartmentAC(false));
        dispatch(setInfoModalWindowCreateDepartmentAC(res.data));
    }).catch(err => {
        dispatch(setLoadingCreateDepartmentAC(false));
        alert('wrong getting  info on modal window create department');
    })
}

export const getInfoCreateDepartmentTC = (id: number | ''): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCreateDepartmentAC(true));
    createDepartmentApi.getInfoCreateDepartment(id).then(res => {
        dispatch(setLoadingCreateDepartmentAC(false));
        dispatch(setInfoCreateDepartmentContinueAC(res.data))
    }).catch(err => {
        dispatch(setLoadingCreateDepartmentAC(false));
        alert('wrong getting  info  create department');
    })
}

export const updateInfoCreateDepartmentTC = (id: number, info: {
    field: string,
    value: any
}, setError: (notification: string) => void,): AppThunk => async (dispatch, getState: () => RootStateType) => {
    createDepartmentApi.updateInfo(id, info).then(res => {
    }).catch(error => {
        setError(error.response.data.message);
    })
}

export const createDepartmentTC = (id: number,
                                   setError: (notification: string) => void,
                                   navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let allRoles = getState().common.roles;
    createDepartmentApi.createDepartment(id).then(res => {
        if(!allRoles.filter(i => i.name === 'isDepartmentAdmin')[0]) {
            localStorage.setItem('role', 'isDepartmentAdmin');
            dispatch(setCurrentStatusAC('isDepartmentAdmin'));
            dispatch(addRoleAC({name: 'isDepartmentAdmin', value: true}));
        }
        navigate('/dashboard')
    }).catch(error => {
        let status = error.response.data.status;
        if (status === 500 || status === 404) {
            navigate('/404')
        }
        if (status === 422 || status === 403 || (status > 400 && status !== 404)) {
            setError(error.response.data.message)
        }
    })
}