import React, {useState} from "react";
import style from './switchNotifications.module.scss';
import NotificationItem from "./notificationItem/notificationItem";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import {readNotificationMenuNotificationsReducerTC} from "../../../redux/reducers/notificationsMenuReducer";

export type NotificationsModeType = 'all' | 'unread';

const SwitchNotifications = () => {
    const dispatch = useDispatch();
    const allNotifications = useSelector((state: RootStateType) => state.notificationsMenu.allNotifications);
    const [notificationsMode, setNotificationsMode] = useState<NotificationsModeType>('unread');

    const changeNotificationsMode = (mode: NotificationsModeType) => {
        setNotificationsMode(mode);
    }

    //делаем все нотификашки прочитанными
    const readAllNotifications = () => {
        let allReadNotifications = allNotifications.map(n => n.id);
        let body = {notification: allReadNotifications}
        dispatch(readNotificationMenuNotificationsReducerTC(body))
    }

    //читаем конкретную нотификашку
    const readCertainNotification = (id: number) => {
        let flag = allNotifications.find(n => n.id === id)?.seen;
        let notificationSend = {notification: {id: id, seen: !flag}}
        dispatch(readNotificationMenuNotificationsReducerTC(notificationSend))
    }

    return (
        <div className={`${style.switch_notifications_window} notifications_menu`}>
            <div className={style.switch_notifications_window_header}>Notifications</div>
            <div className={style.switch_notifications_separator}></div>
            <div className={style.switch_notifications_window_filters}>
                {notificationsMode === 'all' ? <p onClick={() => changeNotificationsMode('unread')}>Unread</p>
                    : <p onClick={() => changeNotificationsMode('all')}>View All</p>}
                <p onClick={readAllNotifications}>Mark all as read</p>
            </div>
            <div className={style.switch_notifications_window_body}>
                {allNotifications.map((n, index) => <NotificationItem key={index}
                                                                      index={index}
                                                                      notificationsMode={notificationsMode}
                                                                      notification={n}
                                                                      changeNotificationReadFlag={readCertainNotification}/>)}
            </div>
        </div>
    )
}

export default SwitchNotifications;