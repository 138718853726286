import React, {useEffect} from "react";
import style from './editableSpan.module.scss';
import InsertedImage from "../insertedImage/insertedImage";

import pencil from './../../../assets/images/common/pencil.png';

export type EditableSpanPropsType = {
    title?: string,
    //на close edit mode и enterPress нужно сохранять в стэйте изменения, поэтому и хэндлер нужен
    onChangeHandler: (title: string, id?: string) => void,
    onChangeEditTitle?: (mode: boolean) => void
}

//!!!!!!Здесь в спан надо прокидывать наименование чекина из глобального стэйта
const EditableSpan = (props: EditableSpanPropsType) => {
    const [editMode, changeEditMode] = React.useState<boolean>(false);
    const [title, changeTitle] = React.useState<string>(props.title || '')

    const openEditMode = () => {
        changeEditMode(true);
       props.onChangeEditTitle && props.onChangeEditTitle(true);
    }

    const closeEditMode = () => {
        if(title.trim().length) {
            changeEditMode(false);
            props.onChangeHandler(title);
           props.onChangeEditTitle && props.onChangeEditTitle(false);
        }
    }

    const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeTitle(e.currentTarget.value.trim());
    }

    const enterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        //e.preventDefault();
        if(e.charCode === 13 && title.trim().length) {
            closeEditMode();
        }
    }

    useEffect(() => {
        props.title && changeTitle(props.title)
    }, [props.title])

    return(
        <div className={style.editable_span}>
            {
                editMode ?
                        <input placeholder={title} type="text" autoFocus onBlur={closeEditMode} onKeyPress={enterPress} required={true}
                              onChange={onChangeTitle}/>
                    :
                    <div className={style.editable_span_main}>
                        <span>{title}</span>
                        <InsertedImage width={1.5625} height={1.5625} image={pencil} handler={openEditMode} cursor={'pointer'}/>
                    </div>
            }

        </div>
    )
}

export default EditableSpan;