import React from "react";
import style from './notificationItem.module.scss';
import {NotificationsModeType} from "../switchNotifications";
import {
    clickActionButtonMenuNotificationsTC,
    NotificationType,
    sendMessageFromTextareaMenuNotificationsTC
} from "../../../../redux/reducers/notificationsMenuReducer";
import ItemWithTextarea from "./itemWithTextarea/itemWithTextarea";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setNotificationsModeAC} from "../../../../redux/reducers/commonReducer";

export type NotificationItemPropsType = {
    notification: NotificationType,
    index: number,
    notificationsMode: NotificationsModeType,
    changeNotificationReadFlag: (id: number) => void,
}

const NotificationItem = React.memo((props: NotificationItemPropsType) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sendMessage = (teamId: number, post: { text: string, anonymously: boolean, isMotivation: boolean }) => {
        dispatch(sendMessageFromTextareaMenuNotificationsTC(teamId, post, props.notification.id, props.notification.seen, showToolTipe));
        // !props.notification.seen && dispatch(readNotificationMenuNotificationsReducerTC({notification: {id: props.notification.id, seen: true}}))
    }

    const buttonHandler = () => {
        let id = props.notification.id;
        let url = props.notification.buttonLink;
        let body = JSON.parse(props.notification.props);
        url && body && dispatch(clickActionButtonMenuNotificationsTC(id, props.notification.seen, url, body, showToolTipe));
        // !props.notification.seen && dispatch(readNotificationMenuNotificationsReducerTC({notification: {id: props.notification.id, seen: true}}))
    }

    const onMessageClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        for (let i = 0; i < e.currentTarget.childNodes?.length; i++) {
            //@ts-ignore
            let url = e.currentTarget.childNodes[i].nodeName === 'A' && e.currentTarget.childNodes[i].pathname;
            url && navigate(url);
            url && dispatch(setNotificationsModeAC(false));
        }

        // @ts-ignore
        //  if (e.target.attributes[0].name === 'href') {
        //      //@ts-ignore
        //      let url = e.target.attributes[0].value;
        //      url && navigate(url);
        //      dispatch(setNotificationsModeAC(false));
        //  }
    }

    const showToolTipe = () => {
        let btn = document.getElementById(`${props.notification.id}`);
        let span = btn?.children.item(0);
        let data = btn?.getAttribute("data-tooltipe");
        //@ts-ignore
        span.style.display = "block";
        //@ts-ignore
        span.innerHTML = data;
        setTimeout(() => {
            //@ts-ignore
            span.style.display = "none";
        }, 2000);
    }

    return (
        <div className={style.notification_item_wrapper}
             style={{display: props.notificationsMode === 'unread' && props.notification.seen ? 'none' : 'flex'}}>
            <div className={style.notification_item_wrapper_point}>
                <div className={`${props.notification.seen ? style.read_point : style.unread_point}`}
                     onClick={() => props.changeNotificationReadFlag(props.notification.id)}></div>
            </div>
            <div className={style.notification_item_wrapper_context}>
                <div className={style.header}>
                    {props.notification.icon &&
                        <div className={`${style.header_img}`} style={{backgroundColor: `${props.notification.color}`}}>
                            <img src={`${props.notification.icon}`} alt=""/>
                        </div>}
                    {props.notification.title && <div className={style.header_title}
                                                      dangerouslySetInnerHTML={{__html: props.notification.title || ''}}></div>}
                </div>
                {props.notification.message &&
                    <div className={style.text} dangerouslySetInnerHTML={{__html: props.notification.message}}
                         onClick={onMessageClick}></div>}
                {props.notification.textArea && <ItemWithTextarea notification={props.notification}
                    //placeholder={props.notification.textAreaPlaceholder}
                                                                  sendMessage={sendMessage}/>}
                {props.notification.buttonTitle && <div className={`${style.action_button}`}
                                                        style={{color: props.notification.buttonClicked ? 'grey' : '#2697FE'}}
                                                        onClick={buttonHandler} data-tooltipe={`Action completed!`}
                                                        id={`${props.notification.id}`}>
                    <span className={style.btn_span}></span>
                    <span>{props.notification.buttonTitle}</span>
                </div>}
            </div>
        </div>
    )
})

export default NotificationItem;