import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {companyManagementAPI, dashboardAPI} from "../../api/api";
import {v1} from "uuid";
import {CheckinReportMemberType} from "./teamManagementReducer";
import {setLoadingCommonReducerAC} from "./commonReducer";
import {getChildrenForDiagramData} from "../../components/pages/companyManagement/companyChart/companyChart";
import {Buffer} from "buffer";


export type CompanyManagementTeamType = {
    departamentId: number | null,
    id: number,
    isAdmin: boolean,
    isMember: boolean,
    leader: string,
    name: string,
    onboardingCompleted: boolean
}

export type CompanyInfoAdminType = {
    id?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    onboarding?: boolean,
    status?: boolean
}

export type CompanyInfoDepartmentType = {
    companyId: number | null,
    id: string,
    name?: string,
    leader?: string,
    email?: string,
    link: null | string
}

export type CompanyInfoTeamLeadersType = {
    id: string,
    departmentId: number,
    name: string,
    secondaryLeaderName: string | null,
    leader: string,
    email: string,
    link: null | string,
    teamId: number | null,
}

export type CompanyInfoType = {
    admins: Array<CompanyInfoAdminType>,
    id: number,
    companyLink: string,
    departmentLink: string,
    teamLeaderLink: string,
    name: string,
    leaders: Array<CompanyInfoTeamLeadersType>,
    departments: Array<CompanyInfoDepartmentType>
}

export type DepartmentType = {
    id: number,
    name: string
}

export type checkinsInfoType = {
    date: Date,
    members: any,
}

export type CompanyChartType = {
    // director: {},
    admins: [],
    departments: [],
    teams: [],
    members: [],
    diagramData: [],
    departmentsFromSelect: [],
    teamsFromSelect: [],
    chosenDepartment: null,
    chosenTeam: null,
    chosenTimeFrame: null,
    availableUsers: null,
    shareButtonLoader: false
}

export type CompanyManagementStateType = {
    loading: boolean,
    availableTeams: Array<any>,
    chosenTeam: any,
    chosenDepartmentIdForChangingLeader: string,
    departments: Array<DepartmentType>,
    chosenDepartment: DepartmentType | null,
    chosenItemForModalWindow: CompanyInfoDepartmentType | CompanyInfoTeamLeadersType | null,
    companyInfo: CompanyInfoType,
    checkinsInfo: checkinsInfoType,
    possibleLeaders: Array<PossibleLeaderUpdatedType>,
    companyChart: any,
}

const initialState: CompanyManagementStateType = {
    loading: false,
    availableTeams: [],
    chosenTeam: {},
    chosenDepartmentIdForChangingLeader: '',
    departments: [{id: 0, name: ''}],
    chosenDepartment: {id: 0, name: 'All Teams'},
    chosenItemForModalWindow: null,
    companyInfo: {
        admins: [{id: '', firstName: '', lastName: '', email: '', onboarding: false, status: false}],
        id: 0,
        companyLink: '',
        departmentLink: '',
        teamLeaderLink: '',
        name: '',
        leaders: [],
        departments: [{companyId: null, id: '', name: '', leader: '', email: '', link: null}]
    },
    checkinsInfo: {
        date: new Date(),
        members: []
    },
    possibleLeaders: [],
    companyChart: {
        // director: {},
        admins: [],
        departments: [],
        teams: [],
        members: [],
        diagramData: [],
        departmentsFromSelect: [],
        teamsFromSelect: [],
        chosenDepartment: null,
        chosenTeam: null,
        chosenTimeFrame: null,
        availableUsers: null,
        shareButtonLoader: false
    }
}

export const companyManagementReducer = (state = initialState, action: AllActionType): CompanyManagementStateType => {
    switch (action.type) {
        case 'COMPANY-MANAGEMENT-SET-LOADING': {
            return {...state, loading: action.isLoading}
        }
        case 'COMPANY-MANAGEMENT-SET-COMPANY-INFO': {
            return {...state, companyInfo: {...action.info}}
        }
        case 'COMPANY-MANAGEMENT-SET-AVAILABLE-TEAMS': {
            return {...state, availableTeams: [...action.teams]}
        }
        case 'COMPANY-MANAGEMENT-SET-CHOSEN-TEAM': {
            return {...state, chosenTeam: {...action.chosenTeam}}
        }
        case 'COMPANY-MANAGEMENT-CHECKIN-REPORTS-SET-MEMBERS': {
            return {...state, checkinsInfo: {...state.checkinsInfo, members: [...action.members]}}
        }
        case 'COMPANY-MANAGEMENT-CHECKIN-REPORTS-CHANGE-DATE': {
            return {...state, checkinsInfo: {...state.checkinsInfo, date: action.date}}
        }
        case 'COMPANY-MANAGEMENT-ADD-ADMIN': {
            return {...state, companyInfo: {...state.companyInfo, admins: [action.admin, ...state.companyInfo.admins]}}
        }
        case 'COMPANY-MANAGEMENT-DELETE-ADMIN': {
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    admins: state.companyInfo.admins.filter(admin => admin.id !== action.adminId)
                }
            }
        }
        case 'COMPANY-MANAGEMENT-ADD-DEPARTMENT-LEADER': {
            return {
                ...state,
                companyInfo: {...state.companyInfo, departments: [action.leader, ...state.companyInfo.departments]}
            }
        }
        case 'COMPANY-MANAGEMENT-DELETE-DEPARTMENT-LEADER': {
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    departments: state.companyInfo.departments.filter(department => department.id !== action.departmentId)
                }
            }
        }
        // case 'COMPANY-MANAGEMENT-SET-DEPARTMENT-ID-FOR-CHANGING-LEADER': {
        //     return {...state, chosenDepartmentIdForChangingLeader: action.departmentId}
        // }
        case 'COMPANY-MANAGEMENT-SET-CHOSEN-ITEM-FOR-MODAL-WINDOW': {
            return {...state, chosenItemForModalWindow: action.item}
        }
        case 'COMPANY-MANAGEMENT-DELETE-TEAM-LEADER': {
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    leaders: state.companyInfo.leaders.filter(leader => leader.id !== action.leaderId)
                }
            }
        }
        case 'COMPANY-MANAGEMENT-SET-POSSIBLE-LEADERS': {
            return {...state, possibleLeaders: action.leaders}
        }
        case 'COMPANY-MANAGEMENT-FIX-DEPARTMENT-LEADER': {
            return {
                ...state, companyInfo: {
                    ...state.companyInfo,
                    departments: state.companyInfo.departments.map(d => d.name === action.leader.name ? {...action.leader} : d)
                }
            }
        }
        case 'COMPANY-MANAGEMENT-UPDATE-TEAM-LEADER-SECONDARY-NAME': {
            return {
                ...state, companyInfo: {
                    ...state.companyInfo,
                    leaders: state.companyInfo.leaders.map(l => l.teamId === action.teamId ? {
                        ...l,
                        secondaryLeaderName: action.secondaryName
                    } : l)
                }
            }
        }
        case 'COMPANY-MANAGEMENT-GET-CHART-INFO': {
            return {...state, companyChart: {...state.companyChart, ...action.info}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-DIAGRAM-DATA': {
            return {...state, companyChart: {...state.companyChart, diagramData: [...action.data]}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-DEPARTMENTS-FOR-SELECT': {
            return {...state, companyChart: {...state.companyChart, departmentsFromSelect: [...action.departments]}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-TEAMS-FOR-SELECT': {
            return {...state, companyChart: {...state.companyChart, teamsFromSelect: [...action.teams]}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-CHOSEN-DEPARTMENT': {
            let teamsForSelect = action.department ? state.companyChart.teams.filter((t: any) => t.departamentId === action?.department?.value)
                .map((t: any) => ({value: t.id, label: t.teamName})) : [];
            return {...state, companyChart: {...state.companyChart, chosenDepartment: action.department || null, teamsFromSelect: [...teamsForSelect]}}
        }

        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-CHOSEN-TEAM': {

            return {...state, companyChart: {...state.companyChart, chosenTeam: action.team}}
        }
        case 'COMPANY-MANAGEMENT-SET-TIME-FRAME': {
            return {...state, companyChart: {...state.companyChart, chosenTimeFrame: action.time}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-RESET-FILTERS': {
            return {...state, companyChart: {...state.companyChart, chosenTimeFrame: null, chosenDepartment: null, chosenTeam: null}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-GET-AVAILABLE-USERS': {
            return {...state, companyChart: {...state.companyChart, availableUsers: [...action.users]}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SHARE-BUTTON-LOADER': {
            return {...state, companyChart: {...state.companyChart, shareButtonLoader: action.isLoading}}
        }
        case 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-CLEAR-STATE': {
            return {
                ...state,
                companyChart: {...state.companyChart,
                    admins: [],
                    departments: [],
                    teams: [],
                    members: [],
                    diagramData: [],
                    departmentsFromSelect: [],
                    teamsFromSelect: [],
                    chosenDepartment: null,
                    chosenTeam: null,
                    chosenTimeFrame: null,
                    availableUsers: null,
                    shareButtonLoader: false
                }
            }
        }

        default:
            return state;
    }
}

export type setLoadingCompanyManagementACType = ReturnType<typeof setLoadingCompanyManagementAC>;
export type setDepartmentsCompanyManagementACType = ReturnType<typeof setDepartmentsCompanyManagementAC>;
export type setChosenDepartmentCompanyManagementACType = ReturnType<typeof setChosenDepartmentCompanyManagementAC>;
export type setCompanyInfoCompanyManagementACType = ReturnType<typeof setCompanyInfoCompanyManagementAC>;
export type setAvailableTeamsCompanyManagementACType = ReturnType<typeof setAvailableTeamsCompanyManagementAC>;
export type setChosenTeamCompanyManagementACType = ReturnType<typeof setChosenTeamCompanyManagementAC>;
export type setMembersCheckinReportCompanyManagementACType = ReturnType<typeof setMembersCheckinReportCompanyManagementAC>;
export type changeDateCheckinReportCompanyManagementACType = ReturnType<typeof changeDateCheckinReportCompanyManagementAC>;
export type addAdminCompanyManagementACType = ReturnType<typeof addAdminCompanyManagementAC>;
export type deleteAdminCompanyManagementACType = ReturnType<typeof deleteAdminCompanyManagementAC>;

export type addDepartmentLeaderCompanyManagementACType = ReturnType<typeof addDepartmentLeaderCompanyManagementAC>;
export type deleteDepartmentLeaderCompanyManagementACType = ReturnType<typeof deleteDepartmentLeaderCompanyManagementAC>;
//export type setDepartmentIdForChangingLeaderCompanyManagementACType = ReturnType<typeof setDepartmentIdForChangingLeaderCompanyManagementAC>;

export type deleteTeamLeaderCompanyManagementACType = ReturnType<typeof deleteTeamLeaderCompanyManagementAC>;
export type setChosenItemForModalWindowCompanyManagementACType = ReturnType<typeof setChosenItemForModalWindowCompanyManagementAC>;
export type getPossibleLeadersCompanyManagementACType = ReturnType<typeof getPossibleLeadersCompanyManagementAC>;
export type fixDepartmentLeaderCompanyManagementACType = ReturnType<typeof fixDepartmentLeaderCompanyManagementAC>;

export type updateTeamLeaderSecondaryNameCompanyManagementACType = ReturnType<typeof updateTeamLeaderSecondaryNameCompanyManagementAC>;
export type getChartInfoCompanyManagementACType = ReturnType<typeof getChartInfoCompanyManagementAC>;
export type setDiagramDataCompanyManagementACType = ReturnType<typeof setDiagramDataCompanyManagementAC>;
export type setDepartmentsForSelectCompanyManagementOrganizationChartACType = ReturnType<typeof setDepartmentsForSelectCompanyManagementOrganizationChartAC>;
export type setTeamsForSelectCompanyManagementOrganizationChartACType = ReturnType<typeof setTeamsForSelectCompanyManagementOrganizationChartAC>;
export type setChosenDepartmentCompanyManagementOrganizationChartACType = ReturnType<typeof setChosenDepartmentCompanyManagementOrganizationChartAC>;
export type setChosenTeamCompanyManagementOrganizationChartACType = ReturnType<typeof setChosenTeamCompanyManagementOrganizationChartAC>;
export type setTimeFrameCompanyManagementACType = ReturnType<typeof setTimeFrameCompanyManagementAC>;
export type resetAllFiltersCompanyManagementOrganizationChartACType = ReturnType<typeof resetAllFiltersCompanyManagementOrganizationChartAC>;
export type getAvailableUsersShareModalWindowCompanyManagementACType = ReturnType<typeof getAvailableUsersShareModalWindowCompanyManagementAC>;
export type setLoaderForShareDiagramButtonACType = ReturnType<typeof setLoaderForShareDiagramButtonAC>;
export type clearStateOrganizationChartCompanyManagementACType = ReturnType<typeof clearStateOrganizationChartCompanyManagementAC>;

export const setLoadingCompanyManagementAC = (isLoading: boolean) => ({type: 'COMPANY-MANAGEMENT-SET-LOADING', isLoading}as const);
export const setDepartmentsCompanyManagementAC = (departments: Array<DepartmentType>) => ({
    type: 'COMPANY-MANAGEMENT-SET-DEPARTMENTS',
    departments
} as const);
export const setChosenDepartmentCompanyManagementAC = (department: DepartmentType) => ({
    type: 'COMPANY-MANAGEMENT-SET-CHOSEN-DEPARTMENT',
    department
} as const);
export const setCompanyInfoCompanyManagementAC = (info: CompanyInfoType) => ({
    type: 'COMPANY-MANAGEMENT-SET-COMPANY-INFO',
    info
} as const);
export const setAvailableTeamsCompanyManagementAC = (teams: Array<CompanyManagementTeamType>) => ({
    type: 'COMPANY-MANAGEMENT-SET-AVAILABLE-TEAMS',
    teams
} as const);
export const setChosenTeamCompanyManagementAC = (chosenTeam: CompanyManagementTeamType) => ({
    type: 'COMPANY-MANAGEMENT-SET-CHOSEN-TEAM',
    chosenTeam
} as const);
export const setMembersCheckinReportCompanyManagementAC = (members: Array<CheckinReportMemberType>) => ({
    type: 'COMPANY-MANAGEMENT-CHECKIN-REPORTS-SET-MEMBERS',
    members
} as const);
export const changeDateCheckinReportCompanyManagementAC = (date: Date) => ({
    type: 'COMPANY-MANAGEMENT-CHECKIN-REPORTS-CHANGE-DATE',
    date
} as const);
export const addAdminCompanyManagementAC = (admin: CompanyInfoAdminType) => ({
    type: 'COMPANY-MANAGEMENT-ADD-ADMIN',
    admin
} as const);
export const deleteAdminCompanyManagementAC = (adminId: string) => ({
    type: 'COMPANY-MANAGEMENT-DELETE-ADMIN',
    adminId
} as const);
export const addDepartmentLeaderCompanyManagementAC = (leader: CompanyInfoDepartmentType) => ({
    type: 'COMPANY-MANAGEMENT-ADD-DEPARTMENT-LEADER',
    leader
} as const);
export const deleteDepartmentLeaderCompanyManagementAC = (departmentId: string) => ({
    type: 'COMPANY-MANAGEMENT-DELETE-DEPARTMENT-LEADER',
    departmentId
} as const);
// export const setDepartmentIdForChangingLeaderCompanyManagementAC = (departmentId: string) => ({
//     type: 'COMPANY-MANAGEMENT-SET-DEPARTMENT-ID-FOR-CHANGING-LEADER',
//     departmentId
// } as const);

export const deleteTeamLeaderCompanyManagementAC = (leaderId: string) => ({
    type: 'COMPANY-MANAGEMENT-DELETE-TEAM-LEADER',
    leaderId
} as const);
//это для общей модалки в будущем
export const setChosenItemForModalWindowCompanyManagementAC = (item: any) => ({
    type: 'COMPANY-MANAGEMENT-SET-CHOSEN-ITEM-FOR-MODAL-WINDOW',
    item
} as const);

export const getPossibleLeadersCompanyManagementAC = (leaders: Array<PossibleLeaderUpdatedType>) => ({
    type: 'COMPANY-MANAGEMENT-SET-POSSIBLE-LEADERS', leaders
} as const);

export const fixDepartmentLeaderCompanyManagementAC = (leader: CompanyInfoDepartmentType) => ({
    type: 'COMPANY-MANAGEMENT-FIX-DEPARTMENT-LEADER', leader
} as const);

export const updateTeamLeaderSecondaryNameCompanyManagementAC = (teamId: string | number, secondaryName: string | null) => ({
    type: 'COMPANY-MANAGEMENT-UPDATE-TEAM-LEADER-SECONDARY-NAME',
    teamId,
    secondaryName
} as const);

//organization chart
export const getChartInfoCompanyManagementAC = (info: any) => ({
    type: 'COMPANY-MANAGEMENT-GET-CHART-INFO',
    info
} as const);

export const setDiagramDataCompanyManagementAC = (data: any) => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-DIAGRAM-DATA',
    data
}as const);

export const setDepartmentsForSelectCompanyManagementOrganizationChartAC = (departments: Array<{value: string, label: string}>) => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-DEPARTMENTS-FOR-SELECT',
    departments
}as const);

export const setTeamsForSelectCompanyManagementOrganizationChartAC = (teams: Array<{value: number, label: string}>) => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-TEAMS-FOR-SELECT',
    teams
}as const);

export const setChosenDepartmentCompanyManagementOrganizationChartAC = (department: {value: string, label: string} | null) => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-CHOSEN-DEPARTMENT',
    department
}as const);

export const setChosenTeamCompanyManagementOrganizationChartAC = (team: {value: number, label: string} | null) => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SET-CHOSEN-TEAM',
    team
}as const);

export const setTimeFrameCompanyManagementAC = (time: {value: string, label: string} | null) => ({type: 'COMPANY-MANAGEMENT-SET-TIME-FRAME', time}as const);

export const resetAllFiltersCompanyManagementOrganizationChartAC = () => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-RESET-FILTERS'
}as const);

export const getAvailableUsersShareModalWindowCompanyManagementAC = (users: Array<any>) => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-GET-AVAILABLE-USERS',
    users
}as const);

export const setLoaderForShareDiagramButtonAC = (isLoading: boolean) => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-SHARE-BUTTON-LOADER',
    isLoading
}as const);

export const clearStateOrganizationChartCompanyManagementAC = () => ({
    type: 'COMPANY-MANAGEMENT-ORGANIZATION-CHART-CLEAR-STATE'
}as const);

export const getAvailableTeamsTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    companyManagementAPI.getAllTeams().then(res => {
        dispatch(setAvailableTeamsCompanyManagementAC(res.data.teams));
        let firstChosenTeam = res.data.teams.find((team: CompanyManagementTeamType) => team.id > 0);
        dispatch(setChosenTeamCompanyManagementAC(firstChosenTeam));
    }).catch(err => {
        alert('wrong getting all available teams');
    })
}

export const getDepartmentsTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    companyManagementAPI.getDepartments().then(res => {
        dispatch(setDepartmentsCompanyManagementAC(res.data))
    }).catch(err => {
        alert('wrong getting departments on company management');
    })
}

export const getCertainDepartmentInfoTC = (chosenDepartmentId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    companyManagementAPI.getCertainDepartmentInfo(chosenDepartmentId).then(res => {
        let companyInfo: CompanyInfoType = {...res.data.company};
        companyInfo = {...companyInfo, admins: companyInfo.admins.map(a => ({...a, id: v1()}))}
        //console.log(companyInfo.name)
        dispatch(setLoadingCommonReducerAC(false));
        dispatch(setCompanyInfoCompanyManagementAC(companyInfo));
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        alert('wrong getting certain department info');
    })
}

export const getCheckinReportInfoTC = (teamId: number, date: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    companyManagementAPI.getCheckinReportInfo(teamId, date).then(res => {
        let members = res.data.answers.map((member: {
            firstName: string,
            lastName: string,
            email: string,
            completedAt: string
        }) => ({...member, id: v1()}));
        dispatch(setMembersCheckinReportCompanyManagementAC(members));
    }).catch(err => {
        alert('wrong getting checkinreport info on company management');
    })
}

export const createNewAdminTC = (companyId: number,
                                 admin: CompanyInfoAdminType,
                                 setError: (notification: string) => void,
                                 setSuccess: (notification: string) => void,
                                 closeModalWindow: () => void,): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let info = {
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email
    };
    dispatch(setLoadingCommonReducerAC(true));
    companyManagementAPI.inviteAdmin(companyId, info).then(res => {
        dispatch(setLoadingCommonReducerAC(false));
        //setSuccess('Invitation was sent successfully!');
        dispatch(addAdminCompanyManagementAC(admin));
        closeModalWindow();
        //changeModalWindow(false);
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        setError(err.response.data.message);
    })
}

export const removeAdminTC = (companyId: number, email: string, adminId: string, adminStatus: boolean, setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    if (!adminStatus) {
        companyManagementAPI.deleteAdminInvite(companyId, {email: email}).then(res => {
            dispatch(deleteAdminCompanyManagementAC(adminId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    } else {
        companyManagementAPI.deleteAdmin(companyId, {email: email}).then(res => {
            dispatch(deleteAdminCompanyManagementAC(adminId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    }
}

export const reinviteAdminTC = (companyId: number, email: string,
                                setError: (notification: string) => void,
                                setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    companyManagementAPI.reinviteAdmin(companyId, {email: email}).then(res => {
        setSuccess('Invitation will be resent');
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const nudgeAdminTC = (companyId: number, email: string,
                             setError: (notification: string) => void,
                             setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    companyManagementAPI.nudgeAdmin(companyId, {email: email}).then(res => {
        setSuccess('Admin will be nudged');
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const addDepartmentLeaderTC = (companyId: number,
                                      info: { firstName: string, lastName: string, email: string },
                                      leader: CompanyInfoDepartmentType,
                                      setError: (notification: string) => void,
                                      setSuccess: (notification: string) => void,
                                      closeModalWindow: () => void,
                                      // changeModalWindowMode: (mode: boolean) => void
): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    companyManagementAPI.addDepartmentLeader(companyId, info).then(res => {
        dispatch(setLoadingCommonReducerAC(false));
        // setSuccess('Invitation was sent successfully!')
        dispatch(addDepartmentLeaderCompanyManagementAC(leader));
        closeModalWindow();
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        setError(err.response.data.message);
    })
}

export const deleteDepartmentLeaderTC = (companyId: number, email: string, departmentId: string, setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    if (+departmentId < 0) {
        companyManagementAPI.deleteInviteDepartmentLeader(companyId, {email: email}).then(res => {
            dispatch(deleteDepartmentLeaderCompanyManagementAC(departmentId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    } else {
        companyManagementAPI.deleteDepartmentLeader(departmentId).then(res => {
            dispatch(deleteDepartmentLeaderCompanyManagementAC(departmentId));
        }).catch(err => {
            setError(err.response.data.message);
        })

    }
}

export const changeDepartmentLeaderCompanyManagementTC = (departmentId: string,
                                                          info: {
                                                              firstName: string,
                                                              lastName: string,
                                                              email: string,
                                                              keep: boolean
                                                          },
                                                          setError: (notification: string) => void,
                                                          setSuccess: (notification: string) => void,
                                                          closeModalWindow: () => void,): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    companyManagementAPI.changeDepartmentLeader(departmentId, info).then(res => {
        dispatch(setLoadingCommonReducerAC(false));
        //setSuccess('Completed successfully!');
        closeModalWindow();
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        setError(err.response.data.message);
    })
}


export const deleteTeamLeaderTC = (departmentId: number, email: string, teamId: number | null, leaderId: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    if (+leaderId < 0) {
        companyManagementAPI.deleteInviteTeamLeader(departmentId, {email: email}).then(res => {
        }).catch(err => {
            alert('wrong deleting team leader invite on company management');
        })
    } else {
        teamId && companyManagementAPI.deleteTeamLeader(teamId).then(res => {
        }).catch(err => {
            alert('wrong deleting team leader on company management');
        })

    }
    dispatch(deleteTeamLeaderCompanyManagementAC(leaderId));

}

export const changeTeamLeaderCompanyManagementTC = (teamId: string,
                                                    info: {
                                                        firstName: string,
                                                        lastName: string,
                                                        email: string,
                                                        keep: boolean
                                                    },
                                                    setError: (notification: string) => void,
                                                    setSuccess: (notification: string) => void,
                                                    closeModalWindow: () => void,): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    companyManagementAPI.changeTeamLeader(teamId, info).then(res => {
        dispatch(setLoadingCommonReducerAC(false));
        //setSuccess('Completed successfully!')
        closeModalWindow();
    }).catch(err => {
        setError(err.response.data.message);
        dispatch(setLoadingCommonReducerAC(false));
    })
}

type PossibleLeaderType = {
    email: string,
    firstName: string,
    id: number,
    lastName: string,
    role: string,
}

export type PossibleLeaderUpdatedType = PossibleLeaderType & {
    value: number,
    label: string,
}

export const getPossibleLeadersTC = (forRightRoute: 'department' | 'team',
                                     setError: (notification: string) => void): AppThunk => (dispatch, getState: () => RootStateType) => {
    let necessaryRoute = forRightRoute === 'department' ? companyManagementAPI.getPossibleDepartmentLeaders()
        : companyManagementAPI.getPossibleTeamLeaders();
    necessaryRoute.then(res => {
        let leaders = res.data.users.map((u: PossibleLeaderType) => ({
            ...u,
            value: u.id,
            label: `${u.firstName} ${u.lastName}`
        }))
        dispatch(getPossibleLeadersCompanyManagementAC(leaders))
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const chooseNewDepartmentLeaderTC = (departmentId: string | number,
                                            userId: number | string,
                                            setError: (notification: string) => void,
                                            setSuccess: (notification: string) => void,
                                            closeModalWindow: () => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));

    companyManagementAPI.chooseDepartmentLeader(departmentId, userId).then(res => {
        let newDepartment = res.data;
        dispatch(fixDepartmentLeaderCompanyManagementAC(newDepartment));
        dispatch(setLoadingCommonReducerAC(false));
        closeModalWindow();
        // setSuccess(`A new leader has been successfully added to the ${res.data.name} department!`)
    }).catch(err => {
        setError(err.response.data.message);
        dispatch(setLoadingCommonReducerAC(false));
    })
}

//companyChart
export const getCompanyDiagramAdminsTC = (period?: string): AppThunk => async (dispatch, getState: () => RootStateType) => {

    let commonState = getState().common;
    let role = commonState.currentStatus;
    let userName = commonState.userInfo.name;

    //isDepartmentAdmin
    dispatch(setLoadingCompanyManagementAC(true))

    companyManagementAPI.getCommonCompanyDiagram(period).then(res => {

        //цвета
        const adminColor = {titleColor: '#2697fe', mainColor: '#D9EDFF'};

        const colors = [
            {titleColor: '#FFC20E', mainColor: '#FEECB6'},
            {titleColor: '#0EE5A3', mainColor: '#A4F5E3'},
            {titleColor: '#EE2828', mainColor: '#FBA9A9'},
            {titleColor: `#4631e385`, mainColor: '#ACA2F3'}
        ]

        const admins = res.data?.admins?.map((a: any) => ({...a, ...adminColor}));
         const departments = res.data?.departments?.map((d: any, index: number) => ({...d, ...colors[index < 4 ? index : index % 4]}));
        const teams = res.data?.teams?.map((t: any, index: number) => ({...t, ...colors[index < 4 ? index : index % 4]})) || null;
      //   const members = res.data?.users?.map((m: any, index: number) => ({...m, ...colors[index < 4 ? index : index % 4]})) || null;

        // console.log(teams)
        // console.log(departments)
        const members = res.data?.users?.map((m: any, index: number) => {
            let team = teams.filter((t: any) => t.id === m.teamId)[0];
            return {...m, titleColor: team.titleColor || '', mainColor: team.mainColor || ''}
        })



        let filteredAdmins = (role === 'isDepartmentAdmin' ? admins.filter((a: any) => a.isDirector) : admins) || [];
        let filteredDepartments = (role === 'isDepartmentAdmin' ? departments.filter((d: any) => d.name === userName) : departments) || [];
        let filteredTeams = (role === 'isDepartmentAdmin' ? teams.filter((t: any) => filteredDepartments.some((d: any) => t.departamentId === d.id)) : teams) || [];
        let filteredMembers = (role === 'isDepartmentAdmin' ? members.filter((m: any) => filteredTeams.some((t: any) => m.teamId === t.id)) : members) || [];


        let info = {
            admins: filteredAdmins,
            departments: filteredDepartments,
            teams: filteredTeams,
            members: filteredMembers,
        };

        let departmentsForSelect = filteredDepartments.map((d: any) => ({value: d.id, label: d.departmentName}));
        dispatch(setDepartmentsForSelectCompanyManagementOrganizationChartAC(departmentsForSelect))
        // debugger
        let teamsForSelect = filteredTeams.map((d: any) => ({value: d.id, label: d.teamName}));
        dispatch(setTeamsForSelectCompanyManagementOrganizationChartAC(teamsForSelect))

        // const adminChildren = getChildrenForDiagramData(admins, departments, teams, members) || [];
        const adminChildren = getChildrenForDiagramData(filteredAdmins, filteredDepartments, filteredTeams, filteredMembers) || [];

     //   console.log(adminChildren)

        dispatch(getChartInfoCompanyManagementAC(info));
        dispatch(setDiagramDataCompanyManagementAC(adminChildren))

        dispatch(setLoadingCompanyManagementAC(false))
    }).catch(err => {
       // console.log(err)
        //setError(err.response.data.message);
        alert('something wrong getting diagram')
        dispatch(setLoadingCompanyManagementAC(false))
    })
}

export const getUsersForShareModalWindowCompanyManagementTC = (setError: (notification: string) => void,
                                                               setSuccess: (notification: string) => void,): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCompanyManagementAC(true));

    dashboardAPI.getInfoForModalWindowPDFReports().then(res => {
        let users = res.data.users;
        dispatch(getAvailableUsersShareModalWindowCompanyManagementAC(users));
        dispatch(setLoadingCompanyManagementAC(false));
    }).catch(err => {
        setError(err.response.data.message);
        dispatch(setLoadingCompanyManagementAC(false));
    })
}

export type ShareDownloadOrganizationDiagramInfoType = {
    users: any,
    period: any,
    teams: any,
    departments: any,
}
export const shareDownloadOrganizationDiagramTC = (info: any,
                                  type: 'send' | 'download',
                                  setError: (err: string) => void,
                                  setSuccess: (not: string) => void,
                                  closeReportModalWindow?: () => void,
): AppThunk => async (dispatch, getState: () => RootStateType) => {
   // debugger
    closeReportModalWindow && closeReportModalWindow();
    dispatch(setLoaderForShareDiagramButtonAC(true));
    companyManagementAPI.shareDownloadOrganizationDiagram({...info, type})
        .then(res => {
            //debugger
            dispatch(setLoaderForShareDiagramButtonAC(false));
            if (type === 'send') {
                setSuccess('Diagram was sent');
            } else if (type === 'download') {
                const base64str = res.data.buffer;
                let a = Buffer.from(base64str, 'base64')
                let file = new Blob([a]);
                let fileURL = window.URL.createObjectURL(file);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = res.data.file;
                alink.click();
                setSuccess('Successful file download');
            }
        }).catch(err => {
           // console.log(err)
        dispatch(setLoaderForShareDiagramButtonAC(false));
        setError(err.response?.data?.message);
    })
}