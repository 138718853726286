import React, {useEffect} from "react";
import style from './modalWindowCreateDepartment.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import {getInfoModalWindowCreateDepartmentTC} from "../../../../redux/reducers/createDepartmentReducer";
import {NavLink} from "react-router-dom";
import ItemForChoose from "../../createTeam/modalWindowCreateTeam/itemForChoose/itemForChoos";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import closeImg from "../../../../assets/images/createTeam/close.png";

export type ModalWindowCreateDepartmentPropsType = {
    closeModalWindow: (mode: boolean) => void
}

const ModalWindowCreateDepartment = React.memo((props: ModalWindowCreateDepartmentPropsType) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateType) => state.createDepartment.modalWindow);
    const loading = useSelector((state: RootStateType) => state.createDepartment.loading);

    useEffect(() => {
        document.body.classList.add('overflow-hidden')
        dispatch(getInfoModalWindowCreateDepartmentTC());

        return (() => {
            document.body.classList.remove('overflow-hidden');
        })
    }, [])

    return (
        state.company ?
            <div className={style.modal_wrapper}>
                <div className={style.modal_body}>
                    <h2>Your Company Selection</h2>
                    <h3>Select existing company for your department:</h3>
                    <NavLink to={`/departmentCreate`}><ItemForChoose name={state.company?.name}/></NavLink>
                    {
                        state.departments?.length ?
                            <>
                                <h3>The onboarding for departments is not yet complete.</h3>
                                {!loading ? state.departments.map(department => {
                                    return (
                                        <React.Fragment key={department.id}>
                                            <NavLink to={`/departmentCreate/${department.id}`}>
                                                <ItemForChoose name={department.name ? department.name : 'N/A'}
                                                               backGround={'#f8dd88'}/>
                                            </NavLink>
                                        </React.Fragment>
                                    )
                                }) : <LoaderDots/>}
                            </> : null
                    }
                    <div className={style.close} onClick={() => props.closeModalWindow(false)}>
                        <img src={closeImg} alt=""/>
                    </div>
                </div>
            </div> : <></>
    )
})

export default ModalWindowCreateDepartment;