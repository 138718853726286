import React from "react";
import style from "./insertedImage.module.scss";

export type InsertedImagePropsType = {
    width: number,
    height: number,
    image: string,
    handler?: (e: React.MouseEvent<HTMLDivElement>) => void,
    cursor?: string,
    margin?: string,
    rotate?: boolean,
    dataToggle?: boolean,
    ['data-name']?: string
}

const InsertedImage = (props: InsertedImagePropsType) => {

    return (
        <div className={style.inserted_image} data-toggle={props.dataToggle}
             style={{
                 width: `${props.width}rem`,
                 height: `${props.height}rem`,
                 cursor: `${props.cursor}`,
                 margin: props.margin
             }}
             onClick={props.handler}>
            <img src={`${props.image}`} style={{transform: props.rotate ? `rotate(${180}deg)` : ''}} alt=""/>
        </div>
    )
}

export default InsertedImage;
